import React from "react";
import { TabsContent } from "../../../misc/Tabs";
import { Card, CardContent } from "../../../misc/Card";
import { Button } from "../../../misc/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import DragAndDropMedia from "../../../misc/DragAndDrop";
import { VideoTabProps } from "./types";
import { MediaItem } from "../types";
import { calculateTotalPages } from "./utils";
import MediaThumbnails from "./MediaThumbnails";
import DeleteMediaButton from "./DeleteMediaButton";

const VideosTab: React.FC<VideoTabProps> = ({
  videos,
  listingId,
  address1,
  handleUploadComplete,
  toggleSelectMedia,
  selectedMedia,
  isEditing,
  carouselApi,
  combinedMedia,
  mlsApproved,
  setSelectedMedia,
  setIsLoading,
  currentPage,
  setCurrentPage,
  itemsPerPage = 10,
  carouselIndexMap,
}) => {
  const videoItems: MediaItem[] = videos.map((video) => ({
    ...video,
    type: "video",
  }));

  const totalPages = calculateTotalPages("videos", videos);

  return (
    <TabsContent value="videos">
      <Card>
        <CardContent className="pt-6">
          <MediaThumbnails
            mediaItems={videoItems}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            toggleSelectMedia={toggleSelectMedia}
            selectedMedia={selectedMedia}
            isEditing={isEditing}
            carouselApi={carouselApi}
            combinedMedia={combinedMedia}
            mlsApproved={mlsApproved || false}
            carouselIndexMap={carouselIndexMap}
          />
          <div className="flex justify-between items-center mt-4">
            <Button
              variant="outline"
              onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
              disabled={currentPage === 1}
            >
              <FaArrowLeft className="mr-2 h-4 w-4" /> Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="outline"
              onClick={() =>
                setCurrentPage(Math.min(currentPage + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next <FaArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
          {isEditing && selectedMedia.length > 0 ? (
            <div className="my-2 flex justify-end">
              <DeleteMediaButton
                selectedMedia={selectedMedia}
                setIsLoading={setIsLoading}
                mlsApproved={mlsApproved || false}
                listingId={listingId}
                handleDeleteComplete={handleUploadComplete}
                setSelectedMedia={setSelectedMedia}
              />
            </div>
          ) : null}
          <DragAndDropMedia
            type="video"
            listingId={listingId}
            propertyAddress1={address1}
            onUploadComplete={handleUploadComplete}
            mlsApproved={mlsApproved || false}
          />
        </CardContent>
      </Card>
    </TabsContent>
  );
};

export default VideosTab;
