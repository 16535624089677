import React from "react";
import logo from "../assets/images/logo.png";
import { toast } from "react-toastify";
import CustomToast from "../components/misc/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { isValidEmail } from "..//utils/validationUtil";
import forgotImage from "../assets/images/forgot-pasword.jpg";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const ForgotPasswordScreen: React.FC = () => {
  const [email, setEmail] = React.useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      toast.error(
        <CustomToast message="Please enter your email address." type="error" />,
        {
          autoClose: 3000,
        }
      );
      return;
    }
    const url = process.env.REACT_APP_BACKEND_URL || "";

    axios
      .post(
        `${url}/password-reset`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      )
      .then(() => {
        setEmail("");

        toast.success(
          <CustomToast
            message="Password reset email has been sent."
            type="success"
          />
        );

        setTimeout(() => {
          window.location.href = "/login";
        }, 2500);
      })
      .catch((error) => {
        console.error("Error:", error);
        setTimeout(() => {
          toast.error(
            <CustomToast
              message="An error occurred. Please try again later."
              type="error"
            />
          );
        }, 1000);
      });
  };

  return (
    <main className="flex flex-col items-center justify-center h-screen px-5 bg-background">
      <section className="flex flex-col items-center px-14 py-10 max-w-full bg-white rounded-2xl min-h-[833px] w-[605px] justify-between max-md:px-5 max-md:pb-24 space-y-10">
        <button
          className="flex items-center space-x-2 text-primary text-lg mb-4 self-start absolute"
          onClick={() => navigate(-1)}
        >
          <FiArrowLeft />
          <span>Back</span>
        </button>
        <header className="flex flex-col items-center space-y-4 text-2xl font-bold leading-none text-center text-primary">
          <img
            src={logo}
            alt="Marabot Logo"
            className="object-contain w-full aspect-[8.77]"
          />
          <h1>Forgot Password?</h1>
        </header>
        <img
          loading="lazy"
          src={forgotImage}
          alt="Forgot password illustration"
          className="object-contain mt-14 max-w-full aspect-[1.35] w-[404px] max-md:mt-10"
        />
        <form
          className="flex flex-col mt-14 max-w-full w-[397px] max-md:mt-10"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col w-full">
            <label
              htmlFor="emailInput"
              className="self-center text-sm font-medium tracking-normal leading-none text-center text-primary"
            >
              Enter your email address
            </label>
            <input
              type="email"
              id="emailInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              className="flex-1 shrink gap-1 self-stretch px-6 py-4 mt-1 w-full text-base tracking-normal bg-white rounded-xl border border-solid border-stone-300 text-neutral-400 max-md:px-5"
              aria-label="Enter your email address"
            />
          </div>
          <button
            type="submit"
            className="gap-2.5 self-stretch px-8 py-5 mt-16 w-full text-lg font-medium text-center bg-secondary min-h-[49px] rounded-[30px] text-zinc-800 max-md:px-5 max-md:mt-10"
          >
            Send Email
          </button>
        </form>
      </section>
    </main>
  );
};

export default ForgotPasswordScreen;
