import React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../Carousel";
import { MediaCarouelProps } from "./types";
import LeafletMap from "../LeafletMap";

const MediaCarousel: React.FC<MediaCarouelProps> = ({
  setCarouselApi,
  combinedMedia,
  latitude,
  longitude,
  address,
}) => {
  const Media = ({
    src,
    type,
    alt,
  }: {
    src: string;
    type: string;
    alt?: string;
  }) => {
    return type === "image" ? (
      <img src={src} alt={alt} className="w-full h-full object-contain mx-2" />
    ) : (
      <video src={src} className="w-full h-full mx-2 object-fit" controls />
    );
  };

  return (
    <>
      {combinedMedia.length > 0 ? (
        <div className="mb-8">
          <Carousel
            className="w-full h-96 relative" // Define carousel height
            setApi={setCarouselApi}
          >
            <CarouselContent>
              {combinedMedia.map((media, index) => (
                <CarouselItem key={index}>
                  <Media
                    src={media.url}
                    type={media.type}
                    alt={`Media ${index}`}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            {combinedMedia.length > 0 && <CarouselPrevious />}
            {combinedMedia.length > 0 && <CarouselNext />}
          </Carousel>
        </div>
      ) : (
        <>
          {/* Map if no images */}
          {latitude && longitude && address ? (
            <div className="h-80 my-4">
              <LeafletMap
                latitude={latitude}
                longitude={longitude}
                address={address}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default MediaCarousel;
