import React, { useEffect } from "react";
import { PostTimeProps, postTime } from "./types";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../misc/Card";
import { Label } from "../misc/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../misc/Select";
import { Button } from "../misc/Button";
import { FaClock } from "react-icons/fa6";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { timeStringToPostTime, postTimeToTimeString } from "./utils/timeUtils";
import { Popover, PopoverContent, PopoverTrigger } from "../misc/Popover";
import TimePicker from "../misc/TimePicker";

const MAX_POSTS_PER_DAY = 3;
const DEFAULT_TIMES = [
  { hour: 8, minute: 30 },
  { hour: 12, minute: 30 },
  { hour: 18, minute: 30 },
] as postTime[]; // Using postTime objects

const ChoosePostTime: React.FC<PostTimeProps> = ({
  setCurrentStep,
  postsPerDay,
  setPostsPerDay,
  times,
  setTimes,
}) => {
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

  // Handle navigation back to the previous step
  const handleBack = () => {
    setCurrentStep(2);
  };

  // Handle navigation to the next step
  const nextHandler = () => {
    if (!isDisabled) {
      setCurrentStep(4);
    } else {
      toast.error(
        <CustomToast
          message="Please select a time for each post to proceed."
          type="error"
        />,
        { autoClose: 3000 }
      );
    }
  };

  // Handle changes to the number of posts per day
  const handlePostsPerDayChange = (value: string) => {
    const newPostsPerDay = parseInt(value, 10);
    setPostsPerDay(newPostsPerDay);

    // Adjust the times array based on the new number of posts
    setTimes((prevTimes) => {
      const newTimes = [...prevTimes];
      if (newPostsPerDay > prevTimes.length) {
        // Add default times for new posts
        const timesToAdd = newPostsPerDay - prevTimes.length;
        for (let i = 0; i < timesToAdd; i++) {
          newTimes.push(
            DEFAULT_TIMES[newTimes.length] || { hour: 12, minute: 0 }
          );
        }
      } else if (newPostsPerDay < prevTimes.length) {
        // Truncate the times array if the number of posts decreases
        newTimes.length = newPostsPerDay;
      }
      return newTimes;
    });
  };

  // Update a specific time in the times array
  const updateTime = (index: number, newTimeStr: string) => {
    const newTimeObj = timeStringToPostTime(newTimeStr);
    setTimes((prevTimes) => {
      const newTimes = [...prevTimes];
      newTimes[index] = newTimeObj;
      return newTimes;
    });
  };

  // Initialize times on component mount
  useEffect(() => {
    if (times.length === 0 && postsPerDay > 0) {
      const initialTimes = DEFAULT_TIMES.slice(0, postsPerDay);
      setTimes(initialTimes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run only once on mount

  // Ensure that the times array is adjusted whenever postsPerDay changes
  useEffect(() => {
    setTimes((prevTimes) => {
      const newTimes = [...prevTimes];
      if (postsPerDay > prevTimes.length) {
        const timesToAdd = postsPerDay - prevTimes.length;
        for (let i = 0; i < timesToAdd; i++) {
          newTimes.push(
            DEFAULT_TIMES[newTimes.length] || { hour: 12, minute: 0 }
          );
        }
      } else if (postsPerDay < prevTimes.length) {
        newTimes.length = postsPerDay;
      }
      return newTimes;
    });
  }, [postsPerDay, setTimes]);

  // Handle isDisabled logic
  useEffect(() => {
    // Check if the number of times matches postsPerDay
    const isCountMatching = times.length === postsPerDay;

    // Check if all times are selected (valid hour and minute)
    const areAllTimesValid = times.every(
      (time) =>
        time.hour >= 0 &&
        time.hour <= 23 &&
        time.minute >= 0 &&
        time.minute <= 59
    );

    // Update isDisabled based on the above conditions
    setIsDisabled(!(isCountMatching && areAllTimesValid));
  }, [times, postsPerDay]);

  React.useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      .custom-time-input::-webkit-calendar-picker-indicator {
        background: none;
        display: none;
      }
      .custom-time-input::-webkit-datetime-edit-fields-wrapper {
        padding: 0;
      }
      .custom-time-input::-webkit-datetime-edit-hour-field,
      .custom-time-input::-webkit-datetime-edit-minute-field,
      .custom-time-input::-webkit-datetime-edit-ampm-field {
        padding: 0 4px;
      }
      .custom-time-input::-webkit-datetime-edit-hour-field:focus,
      .custom-time-input::-webkit-datetime-edit-minute-field:focus,
      .custom-time-input::-webkit-datetime-edit-ampm-field:focus {
        background-color: #E9C468;
        color: #193456;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Post Frequency and Time</CardTitle>
        <CardDescription>
          Set your posts per day and post times for each platform you have
          selected.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Number of Posts Per Day */}
        <div className="space-y-2">
          <Label htmlFor="posts-per-day">Number of Posts Per Day</Label>
          <Select
            onValueChange={handlePostsPerDayChange}
            value={postsPerDay.toString()}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select number of posts" />
            </SelectTrigger>
            <SelectContent>
              {Array.from(
                { length: MAX_POSTS_PER_DAY },
                (_, num) => num + 1
              ).map((num) => (
                <SelectItem key={num} value={num.toString()}>
                  {num}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* Post Times */}
        <div className="space-y-2">
          {times.map((time, index) => (
            <div key={index} className="flex items-center gap-2 relative">
              <div className="relative flex-1">
                <input
                  type="time"
                  className="custom-time-input w-full pl-10 pr-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#E9C468] bg-white"
                  value={postTimeToTimeString(time)}
                  onChange={(e) => updateTime(index, e.target.value)}
                />
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="absolute left-1 top-1/2 transform -translate-y-1/2 text-gray-400 bg-transparent hover:bg-transparent hover:text-secondary"
                    >
                      <FaClock className="h-4 w-4" />
                      <span className="sr-only">Open time picker</span>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <TimePicker
                      time={postTimeToTimeString(time)}
                      onChange={(newTimeStr) => updateTime(index, newTimeStr)}
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <span className="text-sm text-muted-foreground">
                #{index + 1}
              </span>
            </div>
          ))}
          <div className="bg-gray-100 p-3 rounded-md">
            <p className="text-sm text-gray-600">
              <strong className="text-primary">Tip:</strong> Click the clock
              icons to open a visual picker, or type directly to set the time
              manually.
            </p>
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-between">
            <Button variant="secondary" onClick={handleBack}>
              Back
            </Button>
            <Button onClick={nextHandler} disabled={isDisabled}>
              Next
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ChoosePostTime;
