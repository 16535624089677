import React from "react";
import { ImageTabProps } from "./types";
import { TabsContent } from "@radix-ui/react-tabs";
import { Card, CardContent } from "../../../misc/Card";
import { Button } from "../../../misc/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import DragAndDropMedia from "../../../misc/DragAndDrop";
import { MediaItem } from "../types";
import { calculateTotalPages } from "./utils";
import MediaThumbnails from "./MediaThumbnails";
import { Images } from "../../../../types/listingImages";
import DeleteMediaButton from "./DeleteMediaButton";

const ImagesTab: React.FC<ImageTabProps> = ({
  images,
  listingId,
  handleUploadComplete,
  toggleSelectMedia,
  selectedMedia,
  isEditing,
  carouselApi,
  combinedMedia,
  mlsApproved,
  setSelectedMedia,
  setIsLoading,
  itemsPerPage = 10,
  currentPage,
  setCurrentPage,
  carouselIndexMap,
}) => {
  const imageItems: MediaItem[] = images.map((image: Images) => ({
    ...image,
    type: "image",
  }));

  const totalPages = calculateTotalPages("images", images);

  return (
    <TabsContent value="images">
      <Card>
        <CardContent className="pt-6">
          <MediaThumbnails
            mediaItems={imageItems}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            toggleSelectMedia={toggleSelectMedia}
            selectedMedia={selectedMedia}
            isEditing={isEditing}
            carouselApi={carouselApi}
            combinedMedia={combinedMedia}
            mlsApproved={mlsApproved || false}
            carouselIndexMap={carouselIndexMap}
          />
          <div className="flex justify-between items-center mt-4">
            <Button
              variant="outline"
              onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
              disabled={currentPage === 1}
              className="text-primary hover:text-secondary"
            >
              <FaArrowLeft className="mr-2 h-4 w-4" /> Previous
            </Button>
            <span className="text-primary">
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="outline"
              onClick={() =>
                setCurrentPage(Math.min(currentPage + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="text-primary hover:text-secondary"
            >
              Next <FaArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
          {isEditing && selectedMedia.length > 0 ? (
            <div className="my-2 flex justify-end">
              <DeleteMediaButton
                selectedMedia={selectedMedia}
                setIsLoading={setIsLoading}
                mlsApproved={mlsApproved || false}
                listingId={listingId}
                handleDeleteComplete={handleUploadComplete}
                setSelectedMedia={setSelectedMedia}
              />
            </div>
          ) : null}
          <DragAndDropMedia
            type="image"
            listingId={listingId}
            onUploadComplete={handleUploadComplete}
            mlsApproved={mlsApproved || false}
          />
        </CardContent>
      </Card>
    </TabsContent>
  );
};

export default ImagesTab;
