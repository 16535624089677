import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar";
import { User } from "../types/user";

interface Props {
  role: string | null;
  user: User | null;
}

const UserLayout: React.FC<Props> = ({ role, user }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex flex-col h-screen">
      {/* TopBar */}
      <header className="h-16 flex items-center px-4 flex-shrink-0">
        <TopBar role={role} user={user} />
      </header>

      {/* Main Content Area */}
      <div className="flex flex-1 overflow-hidden">
        {/* SideBar on Desktop */}
        {!isMobile && (
          <aside className="bg-background text-white flex-shrink-0">
            <SideBar />
          </aside>
        )}

        {/* Page Content */}
        <main
          className={`flex-1 bg-background overflow-auto ${
            isMobile ? "pb-16" : ""
          }`}
        >
          <Outlet />
        </main>
      </div>

      {/* SideBar on Mobile */}
      {isMobile && <SideBar />}
    </div>
  );
};

export default UserLayout;
