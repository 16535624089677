import React, { useEffect, useState } from "react";
import StepProgressBar from "../progress-bar";
import { SelectedAddress } from "../../types/selectedAddress";
import Button from "../Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "../misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { Features, ComfirmListing } from "../../types/userListing";
import LoadingScreen from "../../screens/LoadingScreen";
import LeafletMap from "./LeafletMap";
import { FaBath, FaCalendarDays, FaLocationDot, FaStar } from "react-icons/fa6";
import { BsHousesFill } from "react-icons/bs";
import { FaBed, FaScroll } from "react-icons/fa";
import { MdSquareFoot } from "react-icons/md";
import { PiMapPinSimpleAreaFill } from "react-icons/pi";
import { compileAddress } from "../../utils/addressCompiler";
import { useNavigate } from "react-router-dom";
import InputField from "../misc/InputField";
import { MLSFeatures } from "./types";
import DataLoadingError from "../misc/DataLoadingError";

interface CreateUserListingProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  selectedAddress: SelectedAddress | null;
  mlsApproved: boolean | null;
  mlsId: string;
}

const defaultFeatures: Features = {
  architectureType: "",
  cooling: false,
  coolingType: "",
  exteriorType: "",
  fireplace: false,
  fireplaceType: "",
  floorCount: 0,
  foundationType: "",
  garage: false,
  garageSpaces: undefined,
  garageType: "",
  heating: false,
  heatingType: "",
  pool: false,
  poolType: "",
  roofType: "",
  roomCount: 0,
  unitCount: 0,
  viewType: "",
};

const defaultMLSFeatures: MLSFeatures = {
  accessibility: undefined,
  accessibilityType: "",
  appliances: "",
  architecturalStyle: "",
  associationAmenities: "",
  associationFeeIncludes: "",
  basement: undefined,
  basementType: "",
  communityFeatures: "",
  cooling: undefined,
  coolingType: "",
  fireplace: undefined,
  fireplaceFeatures: "",
  flooring: "",
  garage: undefined,
  garageSpaces: undefined,
  heating: undefined,
  heatingType: "",
  interiorFeatures: "",
  laundryFeatures: "",
  patioAndPorchFeatures: "",
  roof: "",
  sewer: "",
  spa: undefined,
  spaFeatures: "",
  view: undefined,
  viewType: "",
  waterfront: undefined,
  waterfrontFeatures: "",
  windowFeatures: "",
};

const ConfirmUserListing: React.FC<CreateUserListingProps> = ({
  setCurrentStep,
  selectedAddress,
  mlsApproved,
  mlsId,
}) => {
  const navigate = useNavigate();

  const defaultListing: ComfirmListing = {
    _id: "",
    longitude: 0,
    latitude: 0,
    formattedAddress: "",
    propertyType: "",
    bedrooms: "",
    bathrooms: "",
    squareFootage: "",
    lotSize: "",
    yearBuilt: "",
    legalDescription: "",
    features: mlsApproved ? { ...defaultMLSFeatures } : { ...defaultFeatures },
    footer: {
      agentName: "",
      agentAgency: "",
      agencyAddress: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
      },
      agencyPhone: "",
    },
  };

  const [listing, setListing] = useState<ComfirmListing>(defaultListing);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchListingData = async () => {
      try {
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        let payload;

        if (mlsApproved) {
          if (mlsId) {
            payload = { mlsId };
          } else if (selectedAddress) {
            payload = { address: selectedAddress };
          }
        } else {
          payload = { address: compileAddress(selectedAddress!) };
        }

        const response = await axios.post(
          `${backendUrl}/${mlsApproved ? "realtor-listing" : "listing"}`,
          payload,
          {
            headers,
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          setLoading(false);
          console.log("Listing data fetched successfully:", response);
          setListing(response.data);

          if (
            (!response.data.latitude || !response.data.longitude) &&
            response.data.formattedAddress
          ) {
            try {
              const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
              if (!apiKey) {
                throw new Error("Google Geocoding API key is not set.");
              }
              const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                response.data.formattedAddress
              )}&key=${apiKey}`;
              const geocodingResponse = await axios.get(geocodingUrl);
              if (geocodingResponse.data.status === "OK") {
                const location =
                  geocodingResponse.data.results[0].geometry.location;
                // Update the listing with new coordinates
                const updatedListing: ComfirmListing = {
                  ...response.data,
                  latitude: location.lat,
                  longitude: location.lng,
                };
                setListing(updatedListing);

                toast.success(
                  <CustomToast
                    message="Coordinates updated successfully."
                    type="success"
                  />,
                  {
                    autoClose: 3000,
                  }
                );
              }
            } catch (error) {
              console.error("Error fetching coordinates from Google:", error);
              toast.error(
                <CustomToast
                  message="Failed to fetch coordinates. Please try again."
                  type="error"
                />,
                {
                  autoClose: 3000,
                }
              );
            }
          }
          toast.success(
            <CustomToast
              message="Property details fetched successfully."
              type="success"
            />,
            {
              autoClose: 3000,
            }
          );
        } else {
          setLoading(false);
          console.error("Failed to fetch listing data:", response);

          toast.error(
            <CustomToast
              message="Unable to fetch property details. Please check the address and try again."
              type="error"
            />,
            {
              autoClose: 3000,
            }
          );

          setTimeout(() => {
            setCurrentStep(1);
          }, 3000);
        }
      } catch (error: unknown) {
        setLoading(false);
        console.error("Error fetching listing data:", error);
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            toast.error(
              <CustomToast
                message="No property details found for the selected address. Please try again."
                type="error"
              />,
              {
                autoClose: 3000,
              }
            );
          } else {
            toast.error(
              <CustomToast
                message="Failed to fetch property details. Please try again."
                type="error"
              />,
              {
                autoClose: 3000,
              }
            );
          }
        }

        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchListingData();
  }, [selectedAddress, setCurrentStep, mlsId, mlsApproved]);

  const [progressSteps] = useState([
    { label: "Property Address", isActive: true, isCompleted: true },
    { label: "Confirm Property Details", isActive: true, isCompleted: false },
  ]);

  const handleBack = () => {
    setCurrentStep(1);
  };

  const handleSaveAndNext = async () => {
    if (mlsApproved) {
      // MLS Approved: Asynchronous Creation
      try {
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const payload = {
          mlsId: mlsId || listing._id,
          footer: listing.footer,
          latitude: listing.latitude,
          longitude: listing.longitude,
          formattedAddress: listing.formattedAddress,
        };
        const response = await axios.post(
          `${backendUrl}/realtor-listing/create`,
          payload,
          {
            headers,
            withCredentials: true,
          }
        );

        if (response.status === 200 || response.status === 201) {
          setLoading(false);
          toast.success(
            <CustomToast
              message="Listing creation initiated."
              type="success"
            />,
            {
              autoClose: 3000,
            }
          );

          navigate("/listings");
        } else {
          setLoading(false);
          toast.error(
            <CustomToast
              message="Failed to initiate listing creation. Please try again."
              type="error"
            />,
            {
              autoClose: 3000,
            }
          );
        }
      } catch (error) {
        setLoading(false);
        console.error("Error initiating listing creation:", error);
        toast.error(
          <CustomToast
            message="Failed to initiate listing creation. Please try again."
            type="error"
          />,
          {
            autoClose: 3000,
          }
        );
      }
    } else {
      // MLS Not Approved: Synchronous Creation
      setLoading(true);

      try {
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const payload = { ...listing };
        const response = await axios.post(
          `${backendUrl}/listing/create`,
          payload,
          {
            headers,
            withCredentials: true,
          }
        );

        if (response.status === 201 || response.status === 200) {
          setLoading(false);
          toast.success(
            <CustomToast
              message="Listing created successfully."
              type="success"
            />,
            {
              autoClose: 3000,
            }
          );

          navigate("/listing", { state: { listingId: response.data.id } });
        } else {
          setLoading(false);
          toast.error(
            <CustomToast
              message="Failed to create listing. Please try again."
              type="error"
            />,
            {
              autoClose: 3000,
            }
          );
        }
      } catch (error) {
        setLoading(false);
        console.error("Error creating listing:", error);
        toast.error(
          <CustomToast
            message="Failed to create listing. Please try again."
            type="error"
          />,
          {
            autoClose: 3000,
          }
        );
      }
    }
  };

  // Handler functions for agent details
  const handleAgentDetailChange = (
    field: keyof ComfirmListing["footer"],
    value: string
  ) => {
    setListing((prev) => ({
      ...prev,
      footer: {
        ...prev.footer,
        [field]: value,
      },
    }));
  };

  const handleAgencyAddressChange = (
    field: keyof ComfirmListing["footer"]["agencyAddress"],
    value: string
  ) => {
    setListing((prev) => ({
      ...prev,
      footer: {
        ...prev.footer,
        agencyAddress: {
          ...prev.footer.agencyAddress,
          [field]: value,
        },
      },
    }));
  };

  const booleanFields: Set<string> = new Set([
    "cooling",
    "fireplace",
    "garage",
    "heating",
    "pool",
    "accessibility",
    "basement",
    "spa",
    "view",
    "waterfront",
  ]);

  const typeOrAssociatedFields: Set<string> = new Set([
    "coolingType",
    "fireplaceType",
    "garageType",
    "heatingType",
    "poolType",
    "viewType",
    "accessibilityType",
    "basementType",
    "spaFeatures",
    "waterfrontFeatures",
  ]);

  // Map boolean fields to their related fields
  const booleanToFieldsMap: Record<string, string[]> = {
    cooling: ["coolingType"],
    fireplace: ["fireplaceType", "fireplaceFeatures"],
    garage: ["garageType", "garageSpaces"],
    heating: ["heatingType"],
    pool: ["poolType"],
    accessibility: ["accessibilityType"],
    basement: ["basementType"],
    spa: ["spaFeatures"],
    view: ["viewType"],
    waterfront: ["waterfrontFeatures"],
  };

  // Map type or associated fields back to their corresponding boolean fields
  const fieldToBooleanMap: Record<string, string> = {
    coolingType: "cooling",
    fireplaceType: "fireplace",
    fireplaceFeatures: "fireplace",
    garageType: "garage",
    garageSpaces: "garage",
    heatingType: "heating",
    poolType: "pool",
    accessibilityType: "accessibility",
    basementType: "basement",
    spaFeatures: "spa",
    viewType: "view",
    waterfrontFeatures: "waterfront",
  };

  const formatKey = (key: string): string => {
    // Split camelCase or PascalCase into words
    return key
      .replace(/([A-Z])/g, " $1") // Insert space before capital letters
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
      .trim();
  };

  // Helper function to check if a field is a boolean field
  const isBooleanField = (key: string): boolean => booleanFields.has(key);

  // Helper function to check if a field is a type or associated field
  const isTypeOrAssociatedField = (key: string): boolean =>
    typeOrAssociatedFields.has(key);

  if (error) {
    return (
      <DataLoadingError
        heading="Failed to fetch property details."
        text="Property details were not found for the selected address. Please check the address and try again or use MLS ID to fetch the property details."
        setCurrentStep={setCurrentStep}
        step={1}
      />
    );
  }

  return (
    <main className="flex overflow-auto flex-col justify-center items-center min-h-full bg-background shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
      <section className="flex flex-col items-center px-14 py-20 max-w-full bg-white rounded-2xl h-full w-[905px] max-md:px-5 max-md:pb-24 relative">
        {loading || !listing ? (
          <LoadingScreen />
        ) : (
          <>
            <header className="flex flex-col mx-auto w-full max-md:max-w-full">
              <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
                Confirm Property Details
              </h1>
              <StepProgressBar steps={progressSteps.filter((step) => step)} />
            </header>
            <div className="w-full max-w-3xl mx-auto rounded-lg border bg-card text-card-foreground shadow-sm text-primary">
              <div className="flex flex-col space-y-1.5 p-6">
                {listing.latitude !== 0 && listing.longitude !== 0 && (
                  <div className="w-full h-80">
                    <LeafletMap
                      latitude={listing.latitude}
                      longitude={listing.longitude}
                      address={listing.formattedAddress}
                    />
                  </div>
                )}
                <div className="text-2xl font-bold flex items-center gap-2 leading-none tracking-tight ">
                  <FaLocationDot className="h-6 w-6 text-secondary" />
                  {listing.formattedAddress}
                </div>
              </div>
              <div className="space-y-6 p-6 pt-0">
                {listing.propertyType ? (
                  <div className="space-y-4 flex items-center gap-2">
                    <BsHousesFill className="h-6 w-6 text-secondary" />
                    <span>{listing.propertyType}</span>
                  </div>
                ) : null}
                <div className="grid grid-cols-2 gap-4">
                  {listing.bedrooms ? (
                    <div className="flex items-center gap-2">
                      <FaBed className="h-6 w-6 text-secondary" />
                      <span>{listing.bedrooms} Beds</span>
                    </div>
                  ) : null}
                  {listing.bathrooms ? (
                    <div className="flex items-center gap-2">
                      <FaBath className="h-6 w-6 text-secondary" />
                      <span>{listing.bathrooms} Baths</span>
                    </div>
                  ) : null}
                  {listing.squareFootage ? (
                    <div className="flex items-center gap-2">
                      <MdSquareFoot className="h-6 w-6 text-secondary" />
                      <span>{listing.squareFootage} sq ft</span>
                    </div>
                  ) : null}
                  {listing.lotSize ? (
                    <div className="flex items-center gap-2">
                      <PiMapPinSimpleAreaFill className="h-6 w-6 text-secondary" />
                      <span>
                        {mlsApproved
                          ? listing.lotSize
                          : (parseInt(listing.lotSize) / 43560).toFixed(2)}{" "}
                        acres
                      </span>
                    </div>
                  ) : null}
                  {listing.yearBuilt ? (
                    <div className="flex items-center gap-2">
                      <FaCalendarDays className="h-6 w-6 text-secondary" />
                      <span>Built {listing.yearBuilt}</span>
                    </div>
                  ) : null}
                </div>
                {listing.legalDescription ? (
                  <div>
                    <div className="flex">
                      <FaScroll className="h-6 w-6 text-secondary" />
                      <span className="ml-2">Description</span>
                    </div>
                    <p className="text-sm">{listing.legalDescription}</p>
                  </div>
                ) : null}
                {listing.features &&
                  typeof listing.features === "object" &&
                  Object.keys(listing.features).length > 0 && (
                    <div>
                      <h3 className="text-lg font-semibold mb-2 flex items-center gap-2 text-primary">
                        <FaStar className="h-6 w-6 text-secondary" />
                        Features
                      </h3>
                      <div className="flex flex-wrap gap-2">
                        {Object.entries(listing.features).map(
                          ([key, value]) => {
                            // Handle Boolean Fields
                            if (isBooleanField(key)) {
                              if (value === true) {
                                const relatedFields =
                                  booleanToFieldsMap[key] || [];
                                return relatedFields.map((relatedKey) => {
                                  const relatedValue =
                                    listing.features[
                                      relatedKey as keyof (
                                        | Features
                                        | MLSFeatures
                                      )
                                    ];
                                  // Check if relatedValue is not null or undefined
                                  if (
                                    relatedValue !== undefined &&
                                    relatedValue !== null &&
                                    relatedValue !== ""
                                  ) {
                                    return (
                                      <div
                                        className="border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 px-2 py-1 rounded"
                                        key={relatedKey}
                                      >
                                        {`${formatKey(
                                          relatedKey
                                        )}: ${relatedValue}`}
                                      </div>
                                    );
                                  }
                                  return null;
                                });
                              }
                              // Do not render the boolean field itself to prevent duplication
                              return null;
                            }

                            // Handle Type and Associated Fields
                            if (isTypeOrAssociatedField(key)) {
                              const correspondingBoolean =
                                fieldToBooleanMap[key];
                              if (
                                correspondingBoolean &&
                                listing.features[
                                  correspondingBoolean as keyof (
                                    | Features
                                    | MLSFeatures
                                  )
                                ] === true
                              ) {
                                // Skip rendering because it's already handled by the boolean field
                                return null;
                              }
                              // Render the type or associated field only if the corresponding boolean is false or not present
                              if (
                                value !== undefined &&
                                value !== null &&
                                value !== ""
                              ) {
                                // Special handling for garageSpaces which is a number
                                if (key === "garageSpaces") {
                                  return (
                                    <div
                                      className="border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 px-2 py-1 rounded"
                                      key={key}
                                    >
                                      {`Garage Spaces: ${value}`}
                                    </div>
                                  );
                                }
                                return (
                                  <div
                                    className="border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 px-2 py-1 rounded"
                                    key={key}
                                  >
                                    {`${formatKey(key)}: ${value}`}
                                  </div>
                                );
                              }
                              return null;
                            }

                            // Handle Independent Fields (Non-Boolean and Non-Type Fields)
                            if (
                              value !== undefined &&
                              value !== null &&
                              value !== ""
                            ) {
                              return (
                                <div
                                  className="border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 px-2 py-1 rounded"
                                  key={key}
                                >
                                  {`${formatKey(key)}: ${String(value).replace(
                                    /,(\S)/g,
                                    ", $1"
                                  )}`}
                                </div>
                              );
                            }

                            // For any other cases, do not render anything
                            return null;
                          }
                        )}
                      </div>
                    </div>
                  )}
                {/* Editable Footer Section */}
                {listing.footer && (
                  <div className="mt-6 p-6 border-t">
                    <h3 className="text-lg font-semibold mb-4">
                      Agent Information
                    </h3>
                    <div className="space-y-4">
                      <InputField
                        label="Agent Name"
                        placeholder="Ex: John Doe"
                        type="text"
                        value={listing.footer.agentName}
                        onChange={(e) =>
                          handleAgentDetailChange("agentName", e.target.value)
                        }
                      />
                      <InputField
                        label="Agency"
                        placeholder="Ex: Best Realty"
                        type="text"
                        value={listing.footer.agentAgency}
                        onChange={(e) =>
                          handleAgentDetailChange("agentAgency", e.target.value)
                        }
                      />
                      {/* Agency Address Fields */}
                      <h4 className="text-md font-medium mb-2">
                        Agency Address
                      </h4>
                      <InputField
                        label="Street 1"
                        placeholder="Ex: 456 Market St"
                        type="text"
                        value={listing.footer.agencyAddress.address1}
                        onChange={(e) =>
                          handleAgencyAddressChange("address1", e.target.value)
                        }
                      />
                      <InputField
                        label="Street 2"
                        placeholder="Ex: Suite 789"
                        type="text"
                        value={listing.footer.agencyAddress.address2 || ""}
                        onChange={(e) =>
                          handleAgencyAddressChange("address2", e.target.value)
                        }
                      />
                      <InputField
                        label="City"
                        placeholder="Ex: San Francisco"
                        type="text"
                        value={listing.footer.agencyAddress.city}
                        onChange={(e) =>
                          handleAgencyAddressChange("city", e.target.value)
                        }
                      />
                      <InputField
                        label="State"
                        placeholder="Ex: CA"
                        type="text"
                        value={listing.footer.agencyAddress.state}
                        onChange={(e) =>
                          handleAgencyAddressChange("state", e.target.value)
                        }
                      />
                      <InputField
                        label="Zip Code"
                        placeholder="Ex: 94107"
                        type="text"
                        value={listing.footer.agencyAddress.zipCode}
                        onChange={(e) =>
                          handleAgencyAddressChange("zipCode", e.target.value)
                        }
                      />
                      <InputField
                        label="Agency Phone"
                        placeholder="Ex: 555-123-4567"
                        type="text"
                        value={listing.footer.agencyPhone}
                        onChange={(e) =>
                          handleAgentDetailChange("agencyPhone", e.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
                {/* End of Editable Footer Section */}
              </div>
            </div>
          </>
        )}
        <div className="w-full flex justify-between mt-8 gap-20">
          <Button text="Back" className="w-1/3" onClick={handleBack} />
          <Button text="Next" className="w-1/3" onClick={handleSaveAndNext} />
        </div>
      </section>
    </main>
  );
};

export default ConfirmUserListing;
