import React from "react";
import { Post } from "../../types/posts";
import { format, isSameDay } from "date-fns";
import { FaTimes } from "react-icons/fa";
import PostCard from "./PostCard"; // Import the PostCard component

interface DayViewProps {
  day: Date;
  posts: Post[];
  onClose: () => void;
}

const DayView: React.FC<DayViewProps> = ({ day, posts, onClose }) => {
  const dayPosts = posts
    .filter((post) => isSameDay(new Date(post.scheduledFor), day))
    .sort(
      (a, b) =>
        new Date(a.scheduledFor).getTime() - new Date(b.scheduledFor).getTime()
    ); // Sort by scheduledFor time

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Background Overlay */}
      <div
        className="fixed inset-0 bg-gray-800 bg-opacity-50"
        onClick={onClose}
      ></div>
      {/* Content */}
      <div className="relative bg-white max-w-[605px] w-full max-h-[90vh] overflow-y-auto rounded-md shadow-lg">
        {/* Sticky Header */}
        <div className="flex justify-between items-center px-6 py-2 sticky top-0 bg-white z-10 w-full">
          <h2 className="text-xl font-bold">
            {format(day, "EEEE, MMMM d, yyyy")}
          </h2>
          <button onClick={onClose} aria-label="Close Day View">
            <FaTimes size={24} />
          </button>
        </div>
        {/* Posts Container */}
        <div className="space-y-4 p-6">
          {dayPosts.length > 0 ? (
            dayPosts.map((post) => <PostCard key={post._id} post={post} />)
          ) : (
            <p className="text-center text-gray-500">
              No posts scheduled for this day.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DayView;
