import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import StepProgressBar from "../progress-bar";
import Button from "../Button";
import PricingPlans from "./PricingPlans";
import { auth } from "../../firebase/firebase-config";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import LoadingScreen from "../../screens/LoadingScreen";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import "react-toastify/dist/ReactToastify.css";

interface PlanDetailsProps {
  setCurrentStep: (step: number) => void;
  role: string | null;
}

interface PlanData {
  planId: string;
  title: string;
  subtitle: string;
  price: string;
  listingsLimit: string;
  postsLimit: string;
  imageLimit: string;
  videoLimit: string;
}

const PlanDetails: React.FC<PlanDetailsProps> = ({ setCurrentStep, role }) => {
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [userPlanId, setUserPlanId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [plans, setPlans] = useState<PlanData[]>([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setLoading(true);
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error("User not authenticated");
        const token = await getIdToken(currentUser, true);
        const headers = { Authorization: `Bearer ${token}` };
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const response = await axios.get(`${backendUrl}/plan`, {
          headers,
          withCredentials: true,
        });
        setPlans(response.data);

        const userResponse = await axios.get(
          `${backendUrl}/users/plan-details`,
          {
            headers,
            withCredentials: true,
          }
        );

        setUserPlanId(userResponse.data);
        setSelectedPlanId(userResponse.data);
      } catch (error) {
        console.error("Error fetching plans:", error);
        toast.error(
          <CustomToast
            message="Failed to fetch plans. Please try again."
            type="error"
          />,
          {
            autoClose: 3000,
          }
        );
      } finally {
        setLoading(false);
      }
    };

    if (role === "TEAM_USER") {
      setCurrentStep(3);
      return;
    }

    fetchPlans();
  }, [role, setCurrentStep]);

  const handleNext = async () => {
    if (selectedPlanId === null) {
      toast.error(
        <CustomToast
          message="Please select a plan before proceeding."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
      return;
    }

    setLoading(true);

    if (selectedPlanId === userPlanId) {
      setCurrentStep(5);
      return;
    }

    setLoading(true);

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error("User not authenticated");
      const token = await getIdToken(currentUser, true);
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.patch(
        `${backendUrl}/users/plan-details`,
        { planId: selectedPlanId },
        {
          headers: headers,
          withCredentials: true,
        }
      );

      setLoading(false);

      if (response.status === 200) {
        toast.success(
          <CustomToast message="Plan selected successfully." type="success" />,
          {
            autoClose: 3000,
          }
        );

        setCurrentStep(5);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error selecting plan:", error);
      toast.error(
        <CustomToast
          message="Failed to select plan. Please try again."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    }
  };

  const handleBack = () => {
    setCurrentStep(3);
  };

  return (
    <main className="flex overflow-hidden flex-col justify-center items-center min-h-screen bg-background shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
      <section className="flex flex-col items-center px-14 py-20 max-w-full bg-white rounded-2xl min-h-[833px] w-[905px] max-md:px-5 max-md:pb-24">
        <div className="w-full flex flex-col items-center mb-8">
          <img
            loading="lazy"
            src={logo}
            alt="Agent Registration Logo"
            className="object-contain self-center max-w-full aspect-[8.77] w-[395px]"
          />
          <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
            User Onboarding
          </h1>
          <StepProgressBar
            steps={[
              { label: "Agent Details", isActive: true, isCompleted: true },
              { label: "Broker Details", isActive: true, isCompleted: true },
              { label: "Choose MLS", isActive: true, isCompleted: true },
              { label: "Select Plan", isActive: true, isCompleted: false },
              { label: "Payment", isActive: false, isCompleted: false },
            ]}
          />
        </div>
        {loading && <LoadingScreen />}
        <div className="flex-grow flex items-center w-full">
          <PricingPlans
            plans={plans}
            selectedPlanId={selectedPlanId}
            setSelectedPlanId={setSelectedPlanId}
          />
        </div>

        <div className="w-full flex justify-between mt-8 gap-20">
          <Button text="Back" className="w-1/3" onClick={handleBack} />
          <Button text="Next" className="w-1/3" onClick={handleNext} />
        </div>
      </section>
    </main>
  );
};

export default PlanDetails;
