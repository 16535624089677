import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  ReactNode,
} from "react";
import { io, Socket } from "socket.io-client";
import { getAuthHeader } from "../utils/authHeader";

// Define the shape of the SocketContext
interface SocketContextProps {
  socket: Socket | null;
}

// Create and export the SocketContext with a default value
export const SocketContext = createContext<SocketContextProps>({
  socket: null,
});

// Custom hook to use the SocketContext
export const useSocket = (): SocketContextProps => useContext(SocketContext);

// Define the props for the SocketProvider
interface SocketProviderProps {
  children: ReactNode;
}

// Create and export the SocketProvider component
export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    const initializeSocket = async () => {
      try {
        const headers = await getAuthHeader();

        const token = headers.Authorization?.split(" ")[1]; // Extract Bearer token

        if (!token) {
          throw new Error("Authentication token not found.");
        }

        // Initialize the Socket.io client
        const newSocket = io(`${process.env.REACT_APP_BACKEND_URL}/progress`, {
          auth: {
            token, // Send token during connection
          },
          withCredentials: true,
          transports: ["websocket"], // Use WebSocket only
        });

        // Socket event listeners
        newSocket.on("connect", () => {
          console.log("Connected to Socket.io server");
        });

        newSocket.on("disconnect", (reason: string) => {
          console.log(`Disconnected from Socket.io server: ${reason}`);
        });

        newSocket.on("connect_error", (error: Error) => {
          console.error("Socket connection error:", error.message);
        });

        newSocket.on("error", (error: any) => {
          console.error("Socket error:", error);
        });

        setSocket(newSocket);
        socketRef.current = newSocket;
      } catch (error: any) {
        console.error("Socket connection error:", error.message);
      }
    };
    initializeSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        console.log("Socket disconnected");
        socketRef.current = null;
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
