import React, { useEffect, useState } from "react";
import { ListingsTabProps } from "./types";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../misc/Tabs";
import NoItems from "../misc/NoItems";
import { useNavigate } from "react-router-dom";
import {
  CombinedAsset,
  CombinedImage,
  CombinedMedia,
  CombinedVideo,
} from "../listings/listing/types";
import ImagesTab from "../listings/listing/tabs/ImagesTab";
import VideosTab from "../listings/listing/tabs/VideosTab";
import AssetsTab from "../listings/listing/tabs/AssetsTab";
import CustomSelect from "../misc/CustomSelect";
import { SingleValue } from "react-select";

interface OptionType {
  label: string;
  value: string;
}

const ListingsTab: React.FC<ListingsTabProps> = ({
  listingMedia,
  activeListingTab,
  listingTabs,
  setActiveListingTab,
  activeTab,
  loading,
  mlsApproved,
  carouselApi,
  setLoading,
  fetchListingMedia,
  carouselIndexMap,
  isEditing,
}) => {
  const navigate = useNavigate();

  const [selectedMedia, setSelectedMedia] = useState<CombinedMedia[]>([]);
  const [activeMediaTab, setActiveMediaTab] = useState<
    "images" | "videos" | "assets"
  >("images");
  const [currentPage, setCurrentPage] = React.useState(1);

  const showNoItems =
    activeTab === "listing" && listingTabs.length <= 0 && !loading;

  const createListingHandler = () => {
    navigate("/create-listing");
  };

  const listing = listingMedia.find(
    (listing) => listing.address === activeListingTab
  );

  useEffect(() => {
    setCurrentPage(1);
    setSelectedMedia([]);
  }, [listing]);

  const combinedMedia: CombinedMedia[] = [
    ...(listing?.images || []).map(
      (image) => ({ ...image, type: "image" } as CombinedImage)
    ),
    ...(listing?.videos || []).map(
      (video) => ({ ...video, type: "video" } as CombinedVideo)
    ),
    ...(listing?.assets || []).map((asset) => {
      let mediaType: "image" | "video" = "image";
      if (asset.assetType === "Video") {
        mediaType = "video";
      } else if (asset.assetType === "Image") {
        mediaType = "image";
      }
      return { ...asset, type: mediaType } as CombinedAsset;
    }),
  ];

  const toggleSelectMedia = (media: CombinedMedia) => {
    setSelectedMedia(
      selectedMedia.some((m) => m.id === media.id)
        ? selectedMedia.filter((m) => m.id !== media.id)
        : [...selectedMedia, media]
    );
  };

  // Create options for the select box
  const listingOptions: OptionType[] = listingTabs.map((tab) => {
    return {
      value: tab,
      label: `${tab}`,
    };
  });

  return (
    <div className="">
      {activeTab === "listing" && listingTabs.length > 0 ? (
        <TabsContent value="listing" className="bg-neutral-100 p-6">
          <Tabs
            value={activeMediaTab}
            onValueChange={(value: string) =>
              setActiveMediaTab(value as "images" | "videos" | "assets")
            }
          >
            {/* Combine CustomSelect and TabsList in the same flex container */}
            <div className=" relative flex flex-row items-center justify-center mb-4">
              <div className="flex flex-row items-center justify-center">
                <div className="w-[250px]">
                  <CustomSelect
                    options={listingOptions}
                    value={
                      activeListingTab
                        ? listingOptions.find(
                            (option) => option.value === activeListingTab
                          )
                        : null
                    }
                    onChange={(selectedOption: SingleValue<OptionType>) => {
                      setActiveListingTab(
                        selectedOption ? selectedOption.value : ""
                      );
                    }}
                    placeholder="Select Listing"
                    isClearable={false}
                    className="w-full"
                    bg="#F5F5F5"
                  />
                </div>
                <TabsList className="ml-4">
                  <TabsTrigger value="images">
                    Images ({listing?.images.length || 0})
                  </TabsTrigger>
                  <TabsTrigger value="videos">
                    Videos ({listing?.videos.length || 0})
                  </TabsTrigger>
                  <TabsTrigger value="assets">
                    Assets ({listing?.assets.length || 0})
                  </TabsTrigger>
                </TabsList>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="w-full">
                {activeMediaTab === "images" && (
                  <ImagesTab
                    images={listing?.images || []}
                    listingId={listing?.listingId || ""}
                    toggleSelectMedia={toggleSelectMedia}
                    selectedMedia={selectedMedia}
                    isEditing={isEditing}
                    carouselApi={carouselApi}
                    combinedMedia={combinedMedia}
                    mlsApproved={mlsApproved}
                    setSelectedMedia={setSelectedMedia}
                    setIsLoading={setLoading}
                    handleUploadComplete={fetchListingMedia}
                    itemsPerPage={10}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    carouselIndexMap={carouselIndexMap}
                  />
                )}

                {activeMediaTab === "videos" && (
                  <VideosTab
                    videos={listing?.videos || []}
                    listingId={listing?.listingId || ""}
                    address1={listing?.address || ""}
                    toggleSelectMedia={toggleSelectMedia}
                    selectedMedia={selectedMedia}
                    isEditing={isEditing}
                    carouselApi={carouselApi}
                    combinedMedia={combinedMedia}
                    mlsApproved={mlsApproved}
                    setSelectedMedia={setSelectedMedia}
                    setIsLoading={setLoading}
                    handleUploadComplete={fetchListingMedia}
                    itemsPerPage={10}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    carouselIndexMap={carouselIndexMap}
                  />
                )}

                {activeMediaTab === "assets" && (
                  <AssetsTab
                    assets={listing?.assets || []}
                    listingId={listing?.listingId || ""}
                    address1={listing?.address || ""}
                    toggleSelectMedia={toggleSelectMedia}
                    selectedMedia={selectedMedia}
                    isEditing={isEditing}
                    carouselApi={carouselApi}
                    combinedMedia={combinedMedia}
                    mlsApproved={mlsApproved}
                    setSelectedMedia={setSelectedMedia}
                    setIsLoading={setLoading}
                    handleUploadComplete={fetchListingMedia}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={10}
                    carouselIndexMap={carouselIndexMap}
                  />
                )}
              </div>
            </div>
          </Tabs>
        </TabsContent>
      ) : showNoItems ? (
        <div
          className={`relative ${
            showNoItems
              ? "min-h-[300px] flex items-start justify-center mt-4"
              : ""
          }`}
        >
          <NoItems
            title="No Listings Created"
            description="Create a new listing to view listings media here."
            descriptionTwo="Click the button below to create a new listing."
            buttonText="Create Listing"
            clickHandler={createListingHandler}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ListingsTab;
