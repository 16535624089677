import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebase-config";
import { setIsAdminView } from "../store/authSlice";
import AgentDetails from "../components/onboarding/AgentDetails";
import BrokerDetails from "../components/onboarding/BrokerDetails";
import MLSDetails from "../components/onboarding/MLSDetails";
import PlanDetails from "../components/onboarding/PlanDetails";
import Payment from "../components/onboarding/Payment";
import AddTeamUsers from "../components/onboarding/AddTeamUsers";
import Button from "../components/Button";

interface Props {
  role: string | null;
}

const OnboardingScreen: React.FC<Props> = ({ role }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);

  useEffect(() => {
    if (role === "TEAM_ADMIN") {
      setCurrentStep(6);
    }
  }, [role]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToAdminPanel = () => {
    dispatch(setIsAdminView(true));
    sessionStorage.setItem("isAdminView", "true");
    navigate("/admin");
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <AgentDetails setCurrentStep={setCurrentStep} role={role} />;
      case 2:
        return <BrokerDetails setCurrentStep={setCurrentStep} role={role} />;
      case 3:
        return <MLSDetails setCurrentStep={setCurrentStep} role={role} />;
      case 4:
        return <PlanDetails setCurrentStep={setCurrentStep} role={role} />;
      case 5:
        return <Payment setCurrentStep={setCurrentStep} role={role} />;
      case 6:
        return <AddTeamUsers setCurrentStep={setCurrentStep} role={role} />;
      default:
        return <AgentDetails setCurrentStep={setCurrentStep} role={role} />;
    }
  };

  return (
    <div>
      <div className="absolute top-0 right-0 p-4 flex flex-col">
        {role === "ADMIN" && (
          <Button className="bg-primary text-white" onClick={goToAdminPanel}>
            Admin panel
          </Button>
        )}
        <Button onClick={handleLogout}>Logout</Button>
      </div>
      {renderStep()}
    </div>
  );
};

export default OnboardingScreen;
