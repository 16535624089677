import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../types/user";
// auth characteristics of current user relevant to client
interface AuthState {
  user: User | null;
  role: string | null;
  // whether the user has completed onboarding
  isOnboarded: boolean;
  // whether the user is in admin view / has admin rights
  isAdminView: boolean;
  // whether the user is authenticated by login service
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  //whether the user has verified their email
  emailVerified: boolean;
  // Whether the user has realtor access
  mlsApproved: boolean;
}

const initialState: AuthState = {
  user: null,
  role: null,
  isOnboarded: false,
  isAdminView: false,
  isAuthenticated: false,
  loading: true,
  error: null,
  emailVerified: false,
  mlsApproved: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload;
      state.isAuthenticated = !!action.payload;
      state.loading = false;
      state.mlsApproved = action.payload?.mlsApproved || false;
    },
    setRole(state, action: PayloadAction<string | null>) {
      state.role = action.payload;
    },
    setIsOnboarded(state, action: PayloadAction<boolean>) {
      state.isOnboarded = action.payload;
    },
    setIsAdminView(state, action: PayloadAction<boolean>) {
      state.isAdminView = action.payload;
      sessionStorage.setItem("isAdminView", JSON.stringify(state.isAdminView));
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
      state.loading = false;
    },
    setEmailVerified(state, action: PayloadAction<boolean>) {
      state.emailVerified = action.payload;
    },
    logout(state) {
      state.user = null;
      state.role = null;
      state.isAuthenticated = false;
      state.isAdminView = false;
      state.isOnboarded = false;
      state.loading = false;
      sessionStorage.removeItem("isAdminView");
    },
  },
});

export const {
  setUser,
  setRole,
  setIsOnboarded,
  setIsAdminView,
  setLoading,
  setError,
  setEmailVerified,
  logout,
} = authSlice.actions;

export default authSlice.reducer;
