export interface ChoosePlatformsProps {
  setCurrentStep: (step: number) => void;
  selectedPlatforms: SocialsConnected;
  setSelectedPlatforms: (platforms: SocialsConnected) => void;
  from: string | null;
}

export interface LocationState {
  listingId: string;
  listingType: string;
  from: string;
}

export interface SocialsConnected {
  facebook: boolean;
  instagram: boolean;
}

export interface ConnectedSocialsData {
  displayName: string;
  id: string;
  pageName: string;
  platform: string;
  profileUrl: string;
  userImage: string;
  username?: string;
}

export interface ChooseFrequencyProps {
  setCurrentStep: (step: number) => void;
  frequency: string;
  setFrequency: (frequency: string) => void;
  selectedDays: string[];
  setSelectedDays: (days: string[]) => void;
  date: Date | undefined;
  setDate: (date: Date) => void;
}

export const weekDays = [
  { id: "Monday", label: "Monday" },
  { id: "Tuesday", label: "Tuesday" },
  { id: "Wednesday", label: "Wednesday" },
  { id: "Thursday", label: "Thursday" },
  { id: "Friday", label: "Friday" },
  { id: "Saturday", label: "Saturday" },
  { id: "Sunday", label: "Sunday" },
];

export interface ChooseCampaignOptionsProps {
  setCurrentStep: (step: number) => void;
  autoApprove: boolean;
  setAutoApprove: React.Dispatch<React.SetStateAction<boolean>>;
  soldPost: boolean;
  setSoldPost: React.Dispatch<React.SetStateAction<boolean>>;
  useEmojis: boolean;
  setUseEmojis: React.Dispatch<React.SetStateAction<boolean>>;
  createCampaign: () => void;
  listingType: string | null;
}

export interface PostTimeProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  postsPerDay: number;
  setPostsPerDay: React.Dispatch<React.SetStateAction<number>>;
  times: postTime[];
  setTimes: React.Dispatch<React.SetStateAction<postTime[]>>;
}

export interface postTime {
  hour: number;
  minute: number;
}

export interface CampaignProgress {
  currentStep: string;
  totalPosts?: number;
  postsCreated?: number;
}
