import React, { useEffect, useMemo } from "react";
import { Card, CardContent } from "../misc/Card";
import { itemsPerPage, PromotionAssetsTabProps, PromotionType } from "./types";
import { Button } from "../misc/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import DragAndDropMedia from "../misc/DragAndDrop";
import { calculateTotalPages, isPromotionType } from "./util";
import MediaThumbnails from "./MediaThumbnail";
import { Assets } from "../../types/asset-type";
import { DeleteMediaButton } from "./DeleteMediaButton";
import CustomSelect from "../misc/CustomSelect";
import { TabsContent } from "../misc/Tabs";
import { Link } from "react-router-dom";

interface OptionType {
  label: string;
  value: PromotionType;
}

const PromotionAssetsTab: React.FC<PromotionAssetsTabProps> = ({
  isEditing,
  assets,
  promotionTypes,
  promotionId,
  selectedMedia,
  setSelectedMedia,
  carouselApi,
  fetchAssets,
  activePromotionType,
  setActivePromotionType,
  carouselAssetIndexMap,
  value,
  setLoading,
}) => {
  // Initialize currentPages state with each PromotionType set to page 1
  const [currentPages, setCurrentPages] = React.useState<{
    [key in PromotionType]?: number;
  }>(() => {
    const initialPages: { [key in PromotionType]?: number } = {};
    promotionTypes.forEach((type) => {
      initialPages[type] = 1;
    });
    return initialPages;
  });

  // Update currentPages and activePromotionType when promotionTypes change
  useEffect(() => {
    setCurrentPages((prevPages) => {
      const updatedPages = { ...prevPages };
      promotionTypes.forEach((type) => {
        if (!(type in updatedPages)) {
          updatedPages[type] = 1;
        }
      });
      return updatedPages;
    });

    // Reset activePromotionType if it's no longer valid
    if (activePromotionType && !promotionTypes.includes(activePromotionType)) {
      if (promotionTypes.length > 0) {
        setActivePromotionType(promotionTypes[0]);
      }
    }
  }, [promotionTypes, activePromotionType, setActivePromotionType]);

  // Get the current page for the active promotion type
  const currentPage = activePromotionType
    ? currentPages[activePromotionType] || 1
    : 1;

  // Filter assets based on the active promotion type
  const filteredAssets = useMemo(() => {
    if (!activePromotionType) return [];
    return assets?.filter(
      (asset) =>
        isPromotionType(asset.assetType) &&
        asset.assetType === activePromotionType
    );
  }, [assets, activePromotionType]);

  // Calculate total pages based on the number of filtered assets
  const totalPages = useMemo(() => {
    if (!activePromotionType) return 1;
    return calculateTotalPages(filteredAssets || [], itemsPerPage);
  }, [activePromotionType, filteredAssets]);

  // Toggle media selection
  const toggleSelectMedia = (media: Assets) => {
    setSelectedMedia(
      selectedMedia.some((m) => m.id === media.id)
        ? selectedMedia.filter((m) => m.id !== media.id)
        : [...selectedMedia, media]
    );
  };

  // Handle upload completion
  const handleUploadComplete = () => {
    fetchAssets();
  };

  // Handle page changes
  const handlePageChange = (newPage: number) => {
    if (activePromotionType) {
      setCurrentPages((prevPages) => ({
        ...prevPages,
        [activePromotionType]: newPage,
      }));
    }
  };

  // Create options for the select box
  const promotionTypeOptions: OptionType[] = promotionTypes.map((type) => {
    const count =
      assets?.filter(
        (asset) => isPromotionType(asset.assetType) && asset.assetType === type
      ).length || 0;

    return {
      value: type,
      label: `${
        type.charAt(0).toUpperCase() + type.slice(1).replace(/([A-Z])/g, " $1")
      } (${count})`,
    };
  });

  return (
    <TabsContent value={value}>
      <Card>
        <CardContent className="pt-6 bg-neutral-100">
          {promotionTypes.length > 0 ? (
            <>
              {/* Promotion Type Select */}
              <div className="flex justify-center">
                <div className="w-[30%]">
                  <CustomSelect
                    options={promotionTypeOptions}
                    value={
                      activePromotionType
                        ? promotionTypeOptions.find(
                            (option) => option.value === activePromotionType
                          )
                        : null
                    }
                    onChange={(selectedOption) => {
                      setActivePromotionType(
                        selectedOption?.value as PromotionType
                      );
                    }}
                    placeholder="Select Promotion Type"
                    isClearable={false}
                    className="mb-4 w-full"
                    bg="#F5F5F5"
                  />
                </div>
              </div>
              {/* Asset Thumbnails and Controls */}
              {promotionTypes.length > 0 ? (
                <div className="bg-white p-4">
                  <MediaThumbnails
                    assets={filteredAssets?.slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )}
                    currentPage={currentPage}
                    toggleSelectMedia={toggleSelectMedia}
                    selectedMedia={selectedMedia}
                    isEditing={isEditing}
                    carouselApi={carouselApi}
                    carouselAssetIndexMap={carouselAssetIndexMap}
                  />
                  <div className="flex justify-between items-center mt-4">
                    <Button
                      variant="outline"
                      onClick={() =>
                        handlePageChange(Math.max(currentPage - 1, 1))
                      }
                      disabled={currentPage === 1}
                    >
                      <FaArrowLeft className="mr-2 h-4 w-4" /> Previous
                    </Button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <Button
                      variant="outline"
                      onClick={() =>
                        handlePageChange(Math.min(currentPage + 1, totalPages))
                      }
                      disabled={currentPage === totalPages}
                    >
                      Next <FaArrowRight className="ml-2 h-4 w-4" />
                    </Button>
                  </div>
                  {isEditing && selectedMedia.length > 0 ? (
                    <div className="my-2 flex justify-end">
                      <DeleteMediaButton
                        selectedMedia={selectedMedia}
                        setLoading={setLoading}
                        promotionId={promotionId}
                        fetchAssets={handleUploadComplete}
                        setSelectedMedia={setSelectedMedia}
                      />
                    </div>
                  ) : null}
                  <DragAndDropMedia
                    type="assetImage"
                    listingId={promotionId}
                    onUploadComplete={handleUploadComplete}
                    assetType={activePromotionType}
                  />
                </div>
              ) : (
                <span>No types available for this promotion.</span>
              )}
            </>
          ) : (
            <span className="text-primary">
              No promotion types selected. Please add promotion types to start
              adding media or got to{" "}
              <Link
                to="/my-content"
                className="text-secondary hover:text-black"
              >
                my content
              </Link>{" "}
              page.
            </span>
          )}
        </CardContent>
      </Card>
    </TabsContent>
  );
};

export default PromotionAssetsTab;
