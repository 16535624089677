import React, { useEffect, useState, useRef } from "react";
import { PreviewOverlayProps } from "./types";
import {
  OVERLAY_FONT_SIZE,
  OVERLAY_HEIGHT,
  OVERLAY_LOGO_HEIGHT,
  OVERLAY_WIDTH,
} from "./utils";

const PreviewOverlay: React.FC<PreviewOverlayProps> = ({
  address1,
  address2,
  city,
  state,
  zipCode,
  newOverlayHasLogo,
  logoFile,
  logoUrl,
  selectedLogo,
  logos,
}) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const [scaleFactor, setScaleFactor] = useState<number>(1);

  useEffect(() => {
    const updateScaleFactor = () => {
      if (previewRef.current) {
        const previewWidth = previewRef.current.offsetWidth;
        const factor = previewWidth / OVERLAY_WIDTH;
        setScaleFactor(factor);
      }
    };

    // Initial calculation
    updateScaleFactor();

    // Update on window resize
    window.addEventListener("resize", updateScaleFactor);
    return () => window.removeEventListener("resize", updateScaleFactor);
  }, []);

  return (
    <div className="space-y-2">
      <h4 className="text-sm font-medium">Preview</h4>
      <div
        className="relative bg-black rounded-lg overflow-hidden"
        style={{
          aspectRatio: "16 / 9",
          width: "100%",
          maxWidth: "640px",
        }}
        ref={previewRef}
      >
        {/* Overlay Content */}
        <div
          className="absolute top-0 left-0"
          style={{
            width: `${OVERLAY_WIDTH}px`,
            height: `${OVERLAY_HEIGHT}px`,
            transform: `scale(${scaleFactor})`,
            transformOrigin: "top left",
          }}
        >
          {/* Background Layer (Transparent) */}
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{ backgroundColor: "transparent" }}
          ></div>

          {/* Composite Image and Text */}
          <div className="absolute bottom-[15px] left-[20px] flex items-center gap-4 bg-transparent">
            {newOverlayHasLogo && (
              <div className="flex items-center space-x-4 bg-black bg-opacity-50 p-3 rounded">
                {(logoFile && logoUrl) || selectedLogo ? (
                  <img
                    src={
                      logoUrl || logos.find((l) => l.id === selectedLogo)?.url
                    }
                    alt="Logo"
                    className="object-contain rounded"
                    style={{
                      height: `${OVERLAY_LOGO_HEIGHT}px`,
                      width: "auto",
                    }}
                  />
                ) : (
                  <div
                    className="flex items-center justify-center bg-white rounded"
                    style={{
                      height: `${OVERLAY_LOGO_HEIGHT}px`,
                      width: "auto",
                      padding: "0 10px",
                    }}
                  >
                    <span className="text-xl font-serif italic">Logo</span>
                  </div>
                )}
                <div
                  className="text-white"
                  style={{
                    fontSize: `${OVERLAY_FONT_SIZE}px`,
                    fontFamily: "Verdana, sans-serif",
                  }}
                >
                  <div>
                    {address1}
                    {address2 && address2.length > 0 && `, ${address2}`}
                  </div>
                  <div>
                    {city}, {state} {zipCode}
                  </div>
                </div>
              </div>
            )}

            {!newOverlayHasLogo && (
              <div
                className="text-white bg-black bg-opacity-50 p-3 rounded"
                style={{
                  fontSize: `${OVERLAY_FONT_SIZE}px`,
                  fontFamily: "Verdana, sans-serif",
                }}
              >
                <div>
                  {address1}
                  {address2 && address2.length > 0 && `, ${address2}`}
                </div>
                <div>
                  {city}, {state} {zipCode}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewOverlay;
