import { getIdToken } from "firebase/auth";
import { auth } from "../firebase/firebase-config";

export const getAuthHeader = async () => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    const token = await getIdToken(currentUser, true);
    return { Authorization: `Bearer ${token}` };
  }
  throw new Error("User is not authenticated");
};
