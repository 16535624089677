export const colors = [
  "#ff5252",
  "#d50000",
  "#c62828",
  "#ff2d00",
  "#b9121b",
  "#ff1d23",
  "#d23600",
  "#ff7729",
  "#ff6600",
  "#fd7400",
  "#fa9600",
  "#f0c755",
  "#ffbe00",
  "#ffd34e",
  "#ffe11a",
  "#fff176",
  "#bedb39",
  "#bdd684",
  "#96ca2d",
  "#beeb9f",
  "#43a047",
  "#45bf55",
  "#67cc8e",
  "#1bbc9b",
  "#287d7d",
  "#546e7a",
  "#8c8c8c",
  "#bfbfbf",
  "#512da8",
  "#9575cd",
  "#9768d1",
  "#6b14a6",
  "#8b63a6",
  "#7b1fa2",
  "#9c27b0",
  "#ab45bc",
  "#04bfbf",
  "#0eeaff",
  "#59d8e6",
  "#00abd8",
  "#04668c",
  "#0288d1",
  "#7ecefd",
  "#0067a6",
  "#2980b9",
  "#799ae0",
  "#2962ff",
  "#1c3ffd",
  "#0003c7",
  "#2c1dff",
];
