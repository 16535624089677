import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiCalendarScheduleLine, RiCalendarScheduleFill } from "react-icons/ri";
import { RiFolderImageLine, RiFolderImageFill } from "react-icons/ri";
import { IoSettingsOutline, IoSettings } from "react-icons/io5";
import { BsMegaphone, BsFillMegaphoneFill } from "react-icons/bs";
import { RiHomeGearLine, RiHomeGearFill } from "react-icons/ri";

const SideBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menuItems = [
    {
      name: "My Schedule",
      path: "/",
      icon: RiCalendarScheduleLine,
      filledIcon: RiCalendarScheduleFill,
      resetCalendar: true, // Add a flag to indicate reset
    },
    {
      name: "My Listings",
      path: "/listings",
      icon: RiHomeGearLine,
      filledIcon: RiHomeGearFill,
      resetCalendar: false,
    },
    {
      name: "My Promotions",
      path: "/promotions",
      icon: BsMegaphone,
      filledIcon: BsFillMegaphoneFill,
      resetCalendar: false,
    },
    {
      name: "My Content",
      path: "/my-content",
      icon: RiFolderImageLine,
      filledIcon: RiFolderImageFill,
      resetCalendar: false,
    },
  ];

  const settingsItem = {
    name: "My Settings",
    path: "/settings",
    icon: IoSettingsOutline,
    filledIcon: IoSettings,
  };

  return (
    <div
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      className={`
        bg-primary
        text-white 
        transition-all 
        duration-500 
        ease-in-out
        overflow-hidden
        ${
          isMobile
            ? "fixed bottom-0 left-0 w-full h-20"
            : `h-full ${isExpanded ? "w-64" : "w-16"}`
        }
        z-50
      `}
    >
      <div
        className={`
          flex ${isMobile ? "flex-row" : "flex-col"}
          ${isMobile ? "justify-around" : "justify-between"}
          ${isMobile ? "items-center" : ""}
          h-full p-2
        `}
      >
        <div
          className={`
            flex ${isMobile ? "flex-row w-full" : "flex-col"}
            ${isMobile ? "justify-around" : "items-start"}
            ${isMobile ? "" : "space-y-8"}
          `}
        >
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={`
                flex ${isMobile ? "flex-col" : "flex-row"} 
                items-center cursor-pointer 
                md:hover:bg-[#F0DFAF] ${
                  location.pathname === item.path
                    ? "text-secondary"
                    : "text-white"
                } md:hover:text-primary p-2 rounded-md transition-colors duration-300 ease-in-out
                ${isMobile ? "flex-1 justify-center" : "w-full"}
                ${!isExpanded && !isMobile ? "justify-center" : ""}
              `}
              onClick={() =>
                navigate(item.path, {
                  state: item.resetCalendar ? { resetCalendar: true } : {},
                })
              }
              style={{
                height: isMobile ? "100%" : "auto",
              }}
            >
              <div className="flex-shrink-0">
                {location.pathname === item.path ? (
                  <item.filledIcon
                    size={isMobile ? 24 : 32}
                    className="transition-transform duration-300 ease-in-out text-current"
                  />
                ) : (
                  <item.icon
                    size={isMobile ? 24 : 32}
                    className="transition-transform duration-300 ease-in-out text-current"
                  />
                )}
              </div>
              {isMobile ? (
                // Text label below the icon on mobile
                <span
                  className="
                    text-xs 
                    mt-1
                    text-center
                    whitespace-nowrap
                  "
                >
                  {item.name}
                </span>
              ) : (
                // Text label beside the icon on desktop when expanded
                isExpanded && (
                  <span
                    className="
                      text-lg 
                      whitespace-nowrap
                      transition-opacity 
                      duration-500 
                      ease-in-out
                      opacity-100 delay-300
                      ml-4
                    "
                  >
                    {item.name}
                  </span>
                )
              )}
            </div>
          ))}
        </div>
        {/* Settings Item */}
        {!isMobile && (
          <div
            className={`
              flex items-center w-full cursor-pointer hover:bg-[#F0DFAF] ${
                location.pathname === settingsItem.path
                  ? "text-secondary"
                  : "text-white"
              } hover:text-primary p-4 rounded-md transition-colors duration-300 ease-in-out
              ${!isExpanded ? "justify-center" : ""}
            `}
            onClick={() => navigate(settingsItem.path)}
          >
            <div className="flex-shrink-0">
              {location.pathname === settingsItem.path ? (
                <settingsItem.filledIcon
                  size={32}
                  className="transition-transform duration-300 ease-in-out text-current"
                />
              ) : (
                <settingsItem.icon
                  size={32}
                  className="transition-transform duration-300 ease-in-out text-current"
                />
              )}
            </div>
            {isExpanded && (
              <span
                className="
                  text-lg 
                  whitespace-nowrap
                  transition-opacity 
                  duration-500 
                  ease-in-out
                  opacity-100 delay-300
                  ml-4
                "
              >
                {settingsItem.name}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
