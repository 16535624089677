import React, { ChangeEvent, useRef, useEffect } from "react";
import { LogoSelectorProps } from "./types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./Tooltip";
import { FaCheck, FaImage } from "react-icons/fa6";
import { cn } from "../../../utils/classMerger";
import { Button } from "../../misc/Button";

const LogoSelector: React.FC<LogoSelectorProps> = ({
  logos,
  selectedLogo,
  onSelectLogo,
  onUploadLogo,
}) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onUploadLogo(file);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ left: 0, behavior: "smooth" });
    }
  }, [onUploadLogo]);

  return (
    <div className="space-y-4">
      <h4 className="text-sm font-medium">Select Existing Logo</h4>
      <div
        ref={containerRef}
        className="flex flex-row gap-4 overflow-x-auto overflow-y-hidden scroll-smooth"
      >
        {logos.length > 0 ? (
          logos.map((logo) => (
            <TooltipProvider key={logo.id}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div
                    className={cn(
                      "group relative cursor-pointer overflow-hidden rounded-lg w-32 h-32 flex-shrink-0"
                    )}
                    onClick={() => onSelectLogo(logo.id)}
                  >
                    <img
                      src={logo.url}
                      alt={logo.name}
                      className="object-contain w-full h-full"
                    />
                    {selectedLogo === logo.id && (
                      <div className="absolute inset-0 bg-primary/20 flex items-center justify-center">
                        <FaCheck className="h-6 w-6 text-white" />
                      </div>
                    )}
                  </div>
                </TooltipTrigger>
                <TooltipContent>{logo.name}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))
        ) : (
          <p className="text-neutral-500 col-span-full text-center">
            No Logos available. Upload a new one below.
          </p>
        )}
      </div>
      <div className="flex items-center space-x-4">
        <Button asChild variant="outline">
          <label className="cursor-pointer">
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleFileChange}
            />
            <FaImage className="mr-2 h-4 w-4" />
            Upload New Logo
          </label>
        </Button>
      </div>
    </div>
  );
};

export default LogoSelector;
