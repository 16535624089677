import React from "react";
import "../assets/styles/loading-animation.css";

const LoadingScreen: React.FC = () => {
  return (
    <div className="h-full loader-overlay">
      <div className="loader"></div>
    </div>
  );
};

export default LoadingScreen;
