import React from "react";

interface CustomToastProps {
  message: string;
  type: "success" | "error" | "warning";
}

const CustomToast: React.FC<CustomToastProps> = ({ message }) => {
  return (
    <div className="flex items-center px-4 py-3 rounded-md">
      <span className="text-sm">{message}</span>
    </div>
  );
};

export default CustomToast;
