import { Button } from "./Button";
import { FiAlertCircle } from "react-icons/fi";
import { ImCross } from "react-icons/im";

interface ReconfirmOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  messageTwo: string;
  confirmText?: string;
}

export default function Component({
  isOpen = false,
  onClose,
  onConfirm,
  title,
  message,
  messageTwo,
  confirmText = "Confirm",
}: ReconfirmOverlayProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-1/3 rounded-lg bg-white p-6 shadow-lg">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <ImCross className="h-6 w-6" />
          <span className="sr-only">Close</span>
        </button>
        <div className="flex flex-col items-center text-center">
          <FiAlertCircle className="h-16 w-16 text-yellow-500" />
          <h2 className="mt-4 text-2xl font-bold text-primary">{title}</h2>
          <p className="mt-2 text-gray-600">{message}</p>
          <p className="mt-2 text-gray-600">{messageTwo}</p>
          <div className="mt-10 flex justify-between space-x-8 w-full">
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button onClick={onConfirm}>{confirmText}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
