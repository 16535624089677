import React from "react";
import { Assets } from "../../types/asset-type";
import { FiCheckCircle } from "react-icons/fi";
import { MediaThumbnailsProps } from "./types";
import VideoThumbnail from "../listings/VideoThumbnail";
import { FaPlay } from "react-icons/fa";

const MediaThumbnails: React.FC<MediaThumbnailsProps> = ({
  assets,
  toggleSelectMedia,
  selectedMedia,
  isEditing,
  carouselApi,
  carouselAssetIndexMap,
}) => {
  const handleThumbnailClick = (asset: Assets) => {
    if (isEditing) {
      // Toggle selection for deletion
      toggleSelectMedia(asset);
    } else {
      // Navigate to the corresponding image in the carousel
      if (carouselApi && carouselAssetIndexMap[asset.id] !== undefined) {
        const index = carouselAssetIndexMap[asset.id];
        carouselApi.scrollTo(index);
      }
    }
  };

  return (
    <div className="grid grid-cols-5 gap-2 mt-4">
      {assets?.map((media) => {
        const isSelected = selectedMedia.some((m) => m.id === media.id);

        return (
          <div
            key={media.id || media.name}
            className={`relative h-32 cursor-pointer ${
              isSelected ? "ring-2 ring-secondary rounded" : ""
            }`}
            onClick={() => handleThumbnailClick(media)}
          >
            {media.assetType === "Video" ? (
              <VideoThumbnail
                videoUrl={media.url}
                className="w-full h-full object-cover rounded"
              />
            ) : (
              <img
                src={media.url}
                alt={`${media.name}`}
                className="w-full h-full object-cover rounded"
              />
            )}
            {media.assetType === "Video" && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded">
                <FaPlay className="text-white" size={24} />
              </div>
            )}
            {/* Overlay when item is selected */}
            {isSelected && (
              <div className="absolute top-2 right-2 bg-primary flex flex-row justify-center items-center p-1 rounded-xl">
                <FiCheckCircle className="text-secondary" />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MediaThumbnails;
