import React, { useEffect, useState } from "react";
import ChoosePlatforms from "../components/campaign/ChoosePlatforms";
import StepProgressBar from "../components/progress-bar";
import ChooseFrequency from "../components/campaign/ChooseFrequency";
import ChoosePostTime from "../components/campaign/ChoosePostTime";
import ChooseCampaignOptions from "../components/campaign/ChooseCampaignOptions";
import { useLocation } from "react-router-dom";
import {
  LocationState,
  postTime,
  SocialsConnected,
} from "../components/campaign/types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToast from "../components/misc/CustomToast";
import { getAuthHeader } from "../utils/authHeader";
import axios from "axios";
import LoadingScreen from "./LoadingScreen";

const CreateCampaign = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedPlatforms, setSelectedPlatforms] = useState<SocialsConnected>({
    facebook: false,
    instagram: false,
  });
  const [frequency, setFrequency] = React.useState("daily");
  const [selectedDays, setSelectedDays] = React.useState<string[]>([]);
  const [date, setDate] = React.useState<Date>();

  const [postsPerDay, setPostsPerDay] = useState<number>(1);
  const [times, setTimes] = useState<postTime[]>([
    { hour: 8, minute: 30 },
    { hour: 12, minute: 30 },
    { hour: 18, minute: 30 },
  ]);

  const [autoApprove, setAutoApprove] = React.useState<boolean>(true);
  const [soldPost, setSoldPost] = React.useState<boolean>(true);
  const [useEmojis, setUseEmojis] = React.useState<boolean>(true);

  const listingId =
    (location.state as LocationState)?.listingId ||
    sessionStorage.getItem("createCampaign");

  const listingType =
    (location.state as LocationState)?.listingType ||
    sessionStorage.getItem("listingType");

  const from =
    (location.state as LocationState)?.from || sessionStorage.getItem("from");

  useEffect(() => {
    if (listingId && listingType && from) {
      // Store listingId in session storage
      console.log("Listing Id: ", listingId);
      sessionStorage.setItem("createCampaign", listingId);
      sessionStorage.setItem("listingType", listingType);
      sessionStorage.setItem("from", from);
    } else {
      toast.error(
        <CustomToast
          message="Listing Id and/or Listing Type is not found. Try again from listings page."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
      navigate(-1);
    }
  }, [listingId, listingType, navigate, from]);

  let progressSteps = [
    { label: "Choose Platforms", isActive: true, isCompleted: false },
    { label: "Choose Frequency", isActive: false, isCompleted: false },
    { label: "Choose Post Time", isActive: false, isCompleted: false },
    { label: "Choose Campaign Options", isActive: false, isCompleted: false },
  ];

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ChoosePlatforms
            setCurrentStep={setCurrentStep}
            selectedPlatforms={selectedPlatforms}
            setSelectedPlatforms={setSelectedPlatforms}
            from={from}
          />
        );
      case 2:
        progressSteps[0].isCompleted = true;
        progressSteps[1].isActive = true;
        return (
          <ChooseFrequency
            setCurrentStep={setCurrentStep}
            frequency={frequency}
            setFrequency={setFrequency}
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            date={date}
            setDate={setDate}
          />
        );
      case 3:
        progressSteps[0].isCompleted = true;
        progressSteps[1].isActive = true;
        progressSteps[1].isCompleted = true;
        progressSteps[2].isActive = true;
        return (
          <ChoosePostTime
            setCurrentStep={setCurrentStep}
            postsPerDay={postsPerDay}
            setPostsPerDay={setPostsPerDay}
            times={times}
            setTimes={setTimes}
          />
        );
      case 4:
        progressSteps[0].isCompleted = true;
        progressSteps[1].isActive = true;
        progressSteps[1].isCompleted = true;
        progressSteps[2].isActive = true;
        progressSteps[2].isCompleted = true;
        progressSteps[3].isActive = true;
        return (
          <ChooseCampaignOptions
            setCurrentStep={setCurrentStep}
            autoApprove={autoApprove}
            setAutoApprove={setAutoApprove}
            soldPost={soldPost}
            setSoldPost={setSoldPost}
            useEmojis={useEmojis}
            setUseEmojis={setUseEmojis}
            createCampaign={handleCreateCampaign}
            listingType={listingType}
          />
        );
      default:
        return (
          <ChoosePlatforms
            setCurrentStep={setCurrentStep}
            selectedPlatforms={selectedPlatforms}
            setSelectedPlatforms={setSelectedPlatforms}
            from={from}
          />
        );
    }
  };

  const handleCreateCampaign = async () => {
    const platforms = [];
    if (selectedPlatforms.facebook) platforms.push("Facebook");
    if (selectedPlatforms.instagram) platforms.push("Instagram");
    console.log(platforms);
    setLoading(true);
    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await axios.post(
        `${backendUrl}/campaign`,
        {
          listingId: listingId,
          listingType: listingType,
          schedules: [
            {
              platforms: platforms,
              frequency: frequency.charAt(0).toUpperCase() + frequency.slice(1),
              postsPerDay: postsPerDay,
              postTimes: times,
              dayOfWeek: selectedDays.map(
                (day) =>
                  day.charAt(0).toUpperCase() + day.slice(1).toLowerCase()
              ),
            },
          ],
          emojis: useEmojis,
          startDate: date,
          autoApprove: autoApprove,
          soldPost: soldPost,
        },
        { headers }
      );

      if (response.status === 201) {
        setLoading(false);
        toast.success(
          <CustomToast message="Campaign generation started!" type="success" />,
          {
            autoClose: 3000,
          }
        );
        // clear session storage
        sessionStorage.removeItem("createCampaign");
        sessionStorage.removeItem("listingType");

        if (listingType === "Promotion") {
          navigate("/promotions");
        } else {
          navigate("/listings");
        }
      }
    } catch (error: unknown) {
      console.error(error);
      setLoading(false);
      toast.error(
        <CustomToast
          message="An error occurred while creating the campaign. Please try again."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="flex overflow-hidden flex-col justify-center items-center min-h-full bg-background shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
      {loading && <LoadingScreen />}
      <section className="flex flex-col items-center px-14 py-20 max-w-full bg-white rounded-2xl h-full w-[905px] max-md:px-5 max-md:pb-24 relative">
        <header className="flex flex-col mx-auto w-full max-md:max-w-full">
          <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
            Create a Campaign
          </h1>
          <StepProgressBar steps={progressSteps.filter((step) => step)} />
        </header>
        <div className="flex flex-col mx-auto mt-2 w-full max-md:max-w-full">
          {renderStep()}
        </div>
      </section>
    </main>
  );
};

export default CreateCampaign;
