import { postTime } from "../types";

export const timeStringToPostTime = (timeStr: string): postTime => {
  const [hourStr, minuteStr] = timeStr.split(":");
  return {
    hour: parseInt(hourStr, 10),
    minute: parseInt(minuteStr, 10),
  };
};

export const postTimeToTimeString = (timeObj: postTime): string => {
  const hour = timeObj.hour.toString().padStart(2, "0");
  const minute = timeObj.minute.toString().padStart(2, "0");
  return `${hour}:${minute}`;
};
