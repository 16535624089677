import React from "react";
import { NewPostProps } from "./types";
import { FaTimes } from "react-icons/fa";

const NewPost: React.FC<NewPostProps> = ({ setAddPostOverlay }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-1/3 rounded-lg bg-white p-6 shadow-lg">
        <FaTimes
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 cursor-pointer"
          onClick={() => setAddPostOverlay(false)}
        />
        <div className="absolute">NewPost</div>
      </div>
    </div>
  );
};

export default NewPost;
