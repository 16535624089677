import React from "react";
import { NoItemProps } from "./types";

const NoItems: React.FC<NoItemProps> = ({
  title,
  description,
  descriptionTwo,
  buttonText,
  clickHandler,
}) => {
  return (
    <div
      className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center h-full w-full z-50"
      aria-modal="true"
      role="dialog"
    >
      <div className="bg-white p-8 rounded-xl shadow-2xl text-center max-w-md w-full mx-4 backdrop-blur-md border border-white border-opacity-20">
        <h2 className="text-3xl font-bold mb-4 text-primary">{title}</h2>
        <p className="text-gray-600">{description}</p>
        <p className="mb-6 text-gray-600">{descriptionTwo}</p>
        <button
          className="px-6 py-3 bg-primary text-white rounded-lg hover:bg-secondary transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
          onClick={clickHandler}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default NoItems;
