import { useEffect, useState } from "react";
import { auth } from "../firebase/firebase-config";
import { onAuthStateChanged, getIdToken } from "firebase/auth";
import axios from "axios";
import useAppDispatch from "../hooks/useAppDispatch";
import {
  setUser,
  setRole,
  setIsOnboarded,
  setIsAdminView,
  setLoading,
  setError,
  setEmailVerified,
} from "../store/authSlice";

const useAuth = () => {
  const dispatch = useAppDispatch();
  const [authCheckCompleted, setAuthCheckCompleted] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      dispatch(setLoading(true));
      dispatch(setError(null));

      if (currentUser) {
        try {
          const token = await getIdToken(currentUser, true);
          const authPayload = {
            authId: currentUser.uid,
            token,
          };
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/validateUser`,
            authPayload,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.data || !response.data.user) {
            throw new Error("User validation failed");
          }

          const userRole = response.data.user.role || "USER";
          const userData = {
            ...response.data.user,
            ...currentUser,
          };
          dispatch(setUser(userData));
          dispatch(setRole(userRole));
          dispatch(setIsOnboarded(response.data.user.onboarded || false));
          dispatch(setEmailVerified(currentUser.emailVerified || false));

          // Automatically set `isAdminView` to true for admins and store in sessionStorage
          if (userRole === "ADMIN") {
            dispatch(setIsAdminView(true));
            sessionStorage.setItem("isAdminView", "true");
          } else {
            dispatch(setIsAdminView(false));
            sessionStorage.setItem("isAdminView", "false");
          }
        } catch (error) {
          console.error("User validation error:", error);
          dispatch(setError("User validation failed. Please try again later."));
          dispatch(setUser(null));
          dispatch(setRole(null));
          dispatch(setIsOnboarded(false));
          dispatch(setIsAdminView(false));
          sessionStorage.setItem("isAdminView", "false");
        }
      } else {
        dispatch(setUser(null));
        dispatch(setRole(null));
        dispatch(setIsOnboarded(false));
        dispatch(setIsAdminView(false));
        sessionStorage.setItem("isAdminView", "false");
      }

      // Ensure loading is false after the process is complete
      setTimeout(() => {
        dispatch(setLoading(false));
        setAuthCheckCompleted(true);
      }, 500);
    });

    return () => unsubscribe();
  }, [dispatch]);

  return { authCheckCompleted };
};

export default useAuth;
