import React, { ChangeEvent } from "react";
import { DetailsTabProps } from "./types";
import { TabsContent } from "../../../misc/Tabs";
import { Card, CardContent } from "../../../misc/Card";
import InputField from "../../../misc/InputField";
import { Label } from "../../../misc/Label";
import { FaBath, FaBed, FaCalendarDays } from "react-icons/fa6";
import { MdSquareFoot } from "react-icons/md";
import { PiMapPinSimpleAreaFill } from "react-icons/pi";
import { Features } from "../../../../types/userListing";
import { compileAddress } from "../../../../utils/addressCompiler";
import { Button } from "../../../misc/Button";
import { AllFeatureKeys, MLSFeatures } from "../../types";
import { Popover, PopoverContent, PopoverTrigger } from "../../../misc/Popover";
import { Paintbrush } from "lucide-react";
import { colors } from "../../../../utils/colors";

const DetailsTab: React.FC<DetailsTabProps> = ({
  isEditing,
  propertyData,
  setPropertyData,
  handleSave,
  mlsApproved,
}) => {
  const excludeKeys = ["_id", "createdAt", "updatedAt"];

  const featureKeys = Object.keys(propertyData.features || {}).filter(
    (key) => !excludeKeys.includes(key)
  ) as AllFeatureKeys[];

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (!propertyData) return;

    setPropertyData({
      ...propertyData,
      [name]:
        name === "Bedrooms" ||
        name === "Bathrooms" ||
        name === "Square Footage" ||
        name === "Year Built"
          ? parseInt(value, 10)
          : name === "lotSize"
          ? parseFloat(value)
          : value,
    });
  };

  const handleFeatureInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    const { value } = e.target;
    if (!propertyData) return;

    setPropertyData({
      ...propertyData,
      features: {
        ...propertyData.features,
        [key]:
          getFeatureType(propertyData.features, key as keyof Features) ===
          "number"
            ? parseFloat(value)
            : value,
      },
    });
  };

  const handleFooterInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const { value } = e.target;
    if (!propertyData || !propertyData.footer) return;

    setPropertyData({
      ...propertyData,
      footer: {
        ...propertyData.footer,
        [field]: value,
      },
    });
  };

  // Handle changes for agency address fields
  const handleAgencyAddressChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const { value } = e.target;
    if (!propertyData || !propertyData.footer) return;

    setPropertyData({
      ...propertyData,
      footer: {
        ...propertyData.footer,
        agencyAddress: {
          ...propertyData.footer.agencyAddress,
          [field]: value,
        },
      },
    });
  };

  const isFeatures = (
    features: Features | MLSFeatures
  ): features is Features => {
    return (features as Features).architectureType !== undefined;
  };

  const isMLSFeatures = (
    features: Features | MLSFeatures
  ): features is MLSFeatures => {
    return (features as MLSFeatures).accessibility !== undefined;
  };

  const getFeatureType = (
    features: Features | MLSFeatures,
    key: AllFeatureKeys
  ): "boolean" | "string" | "number" | "undefined" => {
    if (isFeatures(features)) {
      const featureValue = features[key as keyof Features];
      if (typeof featureValue === "boolean") return "boolean";
      if (typeof featureValue === "number") return "number";
      if (typeof featureValue === "string") return "string";
      return "undefined";
    } else if (isMLSFeatures(features)) {
      const featureValue = features[key as keyof MLSFeatures];
      if (typeof featureValue === "boolean") return "boolean";
      if (typeof featureValue === "number") return "number";
      if (typeof featureValue === "string") return "string";
      return "undefined";
    }
    return "undefined";
  };

  const getFeatureValue = (
    features: Features | MLSFeatures,
    key: AllFeatureKeys
  ): string | number | boolean | undefined => {
    if (isFeatures(features)) {
      return features[key as keyof Features];
    } else if (isMLSFeatures(features)) {
      return features[key as keyof MLSFeatures];
    }
    return undefined;
  };

  console.log("lotSize", propertyData.lotSize);
  return (
    <TabsContent value="details">
      <Card>
        <CardContent className="pt-6">
          <h3
            className={`font-bold text-xl ${
              isEditing ? "mb-0" : "mb-4"
            } text-primary`}
          >
            Property Details
          </h3>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {/* Bedrooms */}
            {propertyData.bedrooms ? (
              <div className="space-y-2">
                {!mlsApproved && isEditing ? (
                  <InputField
                    label="Bedrooms"
                    name="bedrooms"
                    placeholder="Bedrooms"
                    type="number"
                    value={propertyData?.bedrooms}
                    onChange={handleInputChange}
                  />
                ) : (
                  <>
                    <Label
                      className="font-bold text-base text-primary"
                      htmlFor="bedrooms"
                    >
                      Bedrooms
                    </Label>
                    <div className="flex items-center gap-2">
                      <FaBed
                        size={25}
                        className="text-muted-foreground text-secondary"
                      />
                      <span>{propertyData.bedrooms} Bedrooms</span>
                    </div>
                  </>
                )}
              </div>
            ) : null}
            {/* Bathrooms */}
            {propertyData.bathrooms ? (
              <div className="space-y-2">
                {!mlsApproved && isEditing ? (
                  <InputField
                    label="Bathrooms"
                    name="bathrooms"
                    placeholder="Bathrooms"
                    type="number"
                    value={propertyData?.bathrooms}
                    onChange={handleInputChange}
                  />
                ) : (
                  <>
                    <Label
                      className="font-bold text-base text-primary"
                      htmlFor="bathrooms"
                    >
                      Bathrooms
                    </Label>
                    <div className="flex items-center gap-2">
                      <FaBath
                        size={25}
                        className="text-muted-foreground text-secondary"
                      />
                      <span>{propertyData?.bathrooms} Bathrooms</span>
                    </div>
                  </>
                )}
              </div>
            ) : null}
            {/* Square Footage */}
            {propertyData.squareFootage ? (
              <div className="space-y-2">
                {!mlsApproved && isEditing ? (
                  <InputField
                    label="Square Footage"
                    name="squareFootage"
                    placeholder="Square Footage"
                    type="number"
                    value={propertyData?.squareFootage}
                    onChange={handleInputChange}
                  />
                ) : (
                  <>
                    <Label
                      className="font-bold text-base text-primary"
                      htmlFor="squareFootage"
                    >
                      Square Footage
                    </Label>
                    <div className="flex items-center gap-2">
                      <MdSquareFoot
                        size={25}
                        className="text-muted-foreground text-secondary"
                      />
                      <span>{propertyData.squareFootage} sqft</span>
                    </div>
                  </>
                )}
              </div>
            ) : null}
            {/* Lot Size */}
            {propertyData.lotSize ? (
              <div className="space-y-2">
                {!mlsApproved && isEditing ? (
                  <InputField
                    label="Lot Size"
                    name="lotSize"
                    placeholder="Lot Size"
                    type="number"
                    value={propertyData?.lotSize}
                    onChange={handleInputChange}
                  />
                ) : (
                  <>
                    <Label
                      className="font-bold text-base text-primary"
                      htmlFor="lotSize"
                    >
                      Lot Size
                    </Label>
                    <div className="flex items-center gap-2">
                      <PiMapPinSimpleAreaFill
                        size={25}
                        className="text-muted-foreground text-secondary"
                      />
                      <span>
                        {!mlsApproved
                          ? (parseInt(propertyData.lotSize) / 43560).toFixed(2)
                          : propertyData.lotSize}{" "}
                        acres
                      </span>
                    </div>
                  </>
                )}
              </div>
            ) : null}
            {/* Year Built */}
            {propertyData.yearBuilt ? (
              <div className="space-y-2">
                {!mlsApproved && isEditing ? (
                  <InputField
                    label="Year Built"
                    name="yearBuilt"
                    placeholder="Year Built"
                    type="number"
                    value={propertyData?.yearBuilt}
                    onChange={handleInputChange}
                  />
                ) : (
                  <>
                    <Label
                      className="font-bold text-base text-primary"
                      htmlFor="yearBuilt"
                    >
                      Year Built
                    </Label>
                    <div className="flex items-center gap-2">
                      <FaCalendarDays
                        size={25}
                        className="text-muted-foreground text-secondary"
                      />
                      <span>Built in {propertyData.yearBuilt}</span>
                    </div>
                  </>
                )}
              </div>
            ) : null}
            {/* Color Selector */}
            {propertyData.color && (
              <div className="space-y-2">
                <Label className="font-bold text-base text-primary">
                  Campaign Color
                </Label>
                <div className="flex items-center gap-4">
                  <div
                    className="w-8 h-8 rounded-md border shadow-sm"
                    style={{ backgroundColor: propertyData.color }}
                  />
                  {isEditing && (
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className="flex items-center gap-2 h-8"
                        >
                          <Paintbrush className="w-4 h-4" />
                          Select Color
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-80">
                        <div className="grid grid-cols-10 grid-rows-5 gap-1">
                          {colors.map((color) => (
                            <button
                              key={color}
                              className="w-6 h-6 rounded-sm border shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                              style={{ backgroundColor: color }}
                              onClick={() =>
                                setPropertyData({ ...propertyData, color })
                              }
                            />
                          ))}
                        </div>
                        <p className="mt-4 text-sm text-muted-foreground">
                          The campaign color is used to quickly identify social
                          media posts that are connected to this listings
                          marketing campaign. This color will not be visible on
                          social media.
                        </p>
                      </PopoverContent>
                    </Popover>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* Legal Description */}
          {propertyData.legalDescription && (
            <div className="mt-4">
              {!mlsApproved && isEditing ? (
                <InputField
                  label="Description"
                  name="legalDescription"
                  placeholder="Description"
                  value={propertyData?.legalDescription}
                  onChange={handleInputChange}
                  type="textarea"
                  rows={6}
                />
              ) : (
                <>
                  <Label
                    className="font-bold text-base text-primary"
                    htmlFor="legalDescription"
                  >
                    Description
                  </Label>
                  <p className="text-sm text-muted-foreground">
                    {propertyData.legalDescription}
                  </p>
                </>
              )}
            </div>
          )}
          {/* Additional Content */}
          {mlsApproved && (
            <div className="mt-4">
              {isEditing ? (
                <InputField
                  label="Additional Content"
                  name="additionalContent"
                  placeholder="Additional Content"
                  value={propertyData?.additionalContent || ""}
                  onChange={handleInputChange}
                  type="textarea"
                  rows={6}
                />
              ) : (
                <>
                  {propertyData.additionalContent && (
                    <>
                      <Label
                        className="font-bold text-base text-primary"
                        htmlFor="legalDescription"
                      >
                        Additional Content
                      </Label>
                      <p className="text-sm text-muted-foreground">
                        {propertyData.additionalContent}
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {/* Features */}
          {featureKeys.length > 0 && (
            <div className="mt-6">
              <h3 className="font-bold text-xl mb-2 text-primary">Features</h3>
              <ul className="grid grid-cols-2 gap-4 text-sm">
                {featureKeys.map((key) => {
                  const featureType = getFeatureType(
                    propertyData.features,
                    key
                  );
                  const displayName = key
                    .replace(/^has/, "")
                    .replace(/([A-Z])/g, " $1")
                    .trim()
                    .replace(/^./, (str) => str.toUpperCase());

                  const featureValue = getFeatureValue(
                    propertyData.features,
                    key
                  );

                  return (
                    <li
                      key={key}
                      className={`flex ${
                        isEditing
                          ? "flex-col gap-2 justify-end"
                          : "flex-row items-center gap-4"
                      }`}
                    >
                      {!mlsApproved && isEditing ? (
                        featureType !== "boolean" ? (
                          <InputField
                            label={displayName}
                            placeholder={displayName}
                            type={featureType === "number" ? "number" : "text"}
                            value={
                              featureValue !== undefined &&
                              featureValue !== null
                                ? String(featureValue)
                                : ""
                            }
                            onChange={(e) => handleFeatureInputChange(e, key)}
                            className="w-full"
                          />
                        ) : (
                          <>
                            <Label
                              className={`font-bold text-sm text-primary ${
                                isEditing ? "w-full" : "w-1/3"
                              }`}
                              htmlFor={key}
                            >
                              {displayName}:
                            </Label>
                            <div className="flex space-x-2">
                              <button
                                type="button"
                                className={`px-4 py-2 rounded ${
                                  featureValue
                                    ? "bg-secondary text-white hover:bg-primary"
                                    : "bg-gray-200 text-gray-700 hover:bg-secondary"
                                }`}
                                onClick={() =>
                                  handleFeatureInputChange(
                                    { target: { value: true } } as any,
                                    key
                                  )
                                }
                              >
                                Yes
                              </button>
                              <button
                                type="button"
                                className={`px-4 py-2 rounded ${
                                  !featureValue
                                    ? "bg-secondary text-white hover:bg-primary"
                                    : "bg-gray-200 text-gray-700 hover:bg-secondary"
                                }`}
                                onClick={() =>
                                  handleFeatureInputChange(
                                    { target: { value: false } } as any,
                                    key
                                  )
                                }
                              >
                                No
                              </button>
                            </div>
                          </>
                        )
                      ) : (
                        <>
                          <Label
                            className={`font-bold text-sm text-primary ${
                              !mlsApproved && isEditing ? "w-full" : "w-1/3"
                            }`}
                            htmlFor={key}
                          >
                            {displayName}:
                          </Label>
                          <span
                            className={`text-black ${
                              !mlsApproved && isEditing ? "w-full" : "w-2/3"
                            }`}
                          >
                            {featureValue !== undefined && featureValue !== null
                              ? featureType === "boolean"
                                ? (featureValue as boolean)
                                  ? "Yes"
                                  : "No"
                                : featureValue.toString()
                              : "N/A"}
                          </span>
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {/* Agent Details */}
          <div className="mt-6">
            <Label
              className="font-bold text-xl text-primary"
              htmlFor="agentDetails"
            >
              Agent Details
            </Label>
            <div className="space-y-2">
              {isEditing ? (
                <>
                  {/* Agent Name */}
                  <InputField
                    label="Agent Name"
                    placeholder="Agent Name"
                    value={propertyData.footer?.agentName || ""}
                    onChange={(e) => handleFooterInputChange(e, "agentName")}
                    type="text"
                  />
                  {/* Agent Agency */}
                  <InputField
                    label="Agent Agency"
                    placeholder="Agent Agency"
                    value={propertyData.footer?.agentAgency || ""}
                    onChange={(e) => handleFooterInputChange(e, "agentAgency")}
                    type="text"
                  />
                  {/* Agency Phone */}
                  <InputField
                    label="Agency Phone"
                    placeholder="Agency Phone"
                    value={propertyData.footer?.agencyPhone || ""}
                    onChange={(e) => handleFooterInputChange(e, "agencyPhone")}
                    type="text"
                  />
                  {/* Agency Address */}
                  <div className="mt-2">
                    <Label
                      className="font-bold text-md text-primary"
                      htmlFor="agencyAddress"
                    >
                      Agency Address
                    </Label>
                    <InputField
                      label="Address Line 1"
                      placeholder="Address Line 1"
                      value={propertyData.footer?.agencyAddress?.address1 || ""}
                      onChange={(e) => handleAgencyAddressChange(e, "address1")}
                      type="text"
                    />
                    <InputField
                      label="Address Line 2"
                      placeholder="Address Line 2"
                      value={propertyData.footer?.agencyAddress?.address2 || ""}
                      onChange={(e) => handleAgencyAddressChange(e, "address2")}
                      type="text"
                    />
                    <InputField
                      label="City"
                      placeholder="City"
                      value={propertyData.footer?.agencyAddress?.city || ""}
                      onChange={(e) => handleAgencyAddressChange(e, "city")}
                      type="text"
                    />
                    <InputField
                      label="State"
                      placeholder="State"
                      value={propertyData.footer?.agencyAddress?.state || ""}
                      onChange={(e) => handleAgencyAddressChange(e, "state")}
                      type="text"
                    />
                    <InputField
                      label="Zip Code"
                      placeholder="Zip Code"
                      value={propertyData.footer?.agencyAddress?.zipCode || ""}
                      onChange={(e) => handleAgencyAddressChange(e, "zipCode")}
                      type="text"
                    />
                  </div>
                </>
              ) : (
                <>
                  <p className="font-medium">
                    {propertyData.footer?.agentName || "N/A"}
                  </p>
                  <p>{propertyData.footer?.agentAgency || "N/A"}</p>
                  <p>
                    {propertyData.footer?.agencyAddress
                      ? `${compileAddress(propertyData.footer.agencyAddress)}`
                      : "N/A"}
                  </p>
                  <p>{propertyData.footer?.agencyPhone || "N/A"}</p>
                </>
              )}
            </div>
          </div>
          {isEditing && (
            <Button className="mt-4" onClick={handleSave}>
              Save Changes
            </Button>
          )}
        </CardContent>
      </Card>
    </TabsContent>
  );
};

export default DetailsTab;
