import React, { useEffect, useState } from "react";
import {
  ChoosePlatformsProps,
  ConnectedSocialsData,
  SocialsConnected,
} from "./types";
import { Label } from "../misc/Label";
import { FaCheck } from "react-icons/fa6";
import facebookLogo from "../../assets/images/facebook_logo.png";
import instagramLogo from "../../assets/images/instagram_logo.png";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { Button } from "../misc/Button";
import { Card } from "../misc/Card";
import { Avatar, AvatarImage, AvatarFallback } from "../misc/Avatar";
import LoadingScreen from "../../screens/LoadingScreen";
import { useNavigate } from "react-router-dom";
import ConnectSocialAccountsButton from "../misc/ConnectSocialAccountsButton";

const ChoosePlatforms: React.FC<ChoosePlatformsProps> = ({
  setCurrentStep,
  selectedPlatforms,
  setSelectedPlatforms,
  from,
}) => {
  const navigate = useNavigate();

  const [connections, setConnections] = useState<SocialsConnected>({
    facebook: false,
    instagram: false,
  });

  const [connectionData, setConnectionData] = useState<ConnectedSocialsData[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(true);

  const [socialLoading, setSocialLoading] = useState<SocialsConnected>({
    facebook: false,
    instagram: false,
  });

  const socialPlatforms = [
    {
      id: "facebook",
      name: "Facebook",
      icon: <img src={facebookLogo} alt="Facebook Logo" className="h-6 w-6" />,
      color: "bg-[#0866FF] text-white",
      hoverColor: "hover:bg-[#1877F2]",
    },
    {
      id: "instagram",
      name: "Instagram",
      icon: (
        <img src={instagramLogo} alt="Instagram Logo" className="h-6 w-6" />
      ),
      color:
        "bg-gradient-to-br from-[#F58529] via-[#DD2A7B] to-[#8134AF] text-white",
      hoverColor: "hover:from-[#E1731F] hover:via-[#C9246D] hover:to-[#722B9A]",
    },
  ];

  useEffect(() => {
    const fetchConnections = async () => {
      setLoading(true);
      try {
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const response = await axios.get(
          `${backendUrl}/users/social-profiles`,
          {
            headers,
          }
        );

        console.log("Full Response:", response);
        console.log("Response Data:", response.data);

        const responseData = response.data.data || response.data;

        const activeSocialAccounts = responseData.activeSocialAccounts || [];
        const allConnectionData = responseData.displayNames || {};

        setConnections({
          facebook: activeSocialAccounts.includes("facebook"),
          instagram: activeSocialAccounts.includes("instagram"),
        });

        const connectionDataArray = [];

        for (const platform in allConnectionData) {
          if (allConnectionData[platform]) {
            connectionDataArray.push(allConnectionData[platform]);
          }
        }

        setConnectionData(connectionDataArray);
      } catch (error) {
        console.error("Fetch Connections Error:", error);
        let errorMessage =
          "Error fetching social profiles. Please try again later.";
        if (axios.isAxiosError(error) && error.response) {
          errorMessage += ` Server responded with status ${error.response.status}.`;
        }

        toast.error(<CustomToast message={errorMessage} type="error" />, {
          autoClose: 3000,
        });

        // Redirect to the previous page
        setTimeout(() => {
          navigate(from || "/");
        }, 300);
      } finally {
        setLoading(false);
      }
    };

    fetchConnections();
  }, [navigate, from]);

  const handlePlatformToggle = (platformId: keyof SocialsConnected) => {
    console.log(`handlePlatformToggle called for: ${platformId}`);

    setSocialLoading((prev) => ({ ...prev, [platformId]: true }));

    setSelectedPlatforms({
      ...selectedPlatforms,
      [platformId]: !selectedPlatforms[platformId],
    });

    setSocialLoading((prev) => ({ ...prev, [platformId]: false }));
  };

  const handleNextStep = () => {
    // check if at least one platform is selected and all selected platforms are connected
    const hasSelectedPlatforms = Object.values(selectedPlatforms).some(
      (isSelected) => isSelected
    );

    const hasUnconnectedSelectedPlatforms = Object.keys(selectedPlatforms).some(
      (platformId) =>
        selectedPlatforms[platformId as keyof SocialsConnected] &&
        !connections[platformId as keyof SocialsConnected]
    );

    if (!hasSelectedPlatforms) {
      toast.error(
        <CustomToast
          message="Please select at least one social platform."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
      return;
    }

    if (hasUnconnectedSelectedPlatforms) {
      toast.error(
        <CustomToast
          message="Please connect all selected social platforms."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
      return;
    }

    setCurrentStep(2);
  };

  const handleBackStep = () => {
    navigate(from || "/");
  };

  // Check if any selected platform is not connected
  const hasUnconnectedSelectedPlatforms = Object.keys(selectedPlatforms).some(
    (platformId) =>
      selectedPlatforms[platformId as keyof SocialsConnected] &&
      !connections[platformId as keyof SocialsConnected]
  );

  // Disable Next button if any selected platform is not connected or no platform is selected
  const selectedPlatfromNum = Object.values(selectedPlatforms).filter(
    (isSelected) => isSelected
  ).length;

  const isNextDisabled =
    hasUnconnectedSelectedPlatforms || selectedPlatfromNum <= 0;

  return (
    <div className="flex flex-col justify-between h-full">
      <Label className="font-bold text-xl text-primary mb-4">
        Choose Your Social Platforms
      </Label>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {socialPlatforms.map((platform) => {
            const platformId = platform.id as keyof SocialsConnected;
            const isSelected = selectedPlatforms[platformId];
            const isConnected = connections[platformId];
            const platformConnections = connectionData.filter(
              (data) => data.platform === platform.id
            );

            return (
              <Card
                key={platform.id}
                className={`p-4 flex flex-col items-center cursor-pointer ${
                  isSelected ? `${platform.color}` : "bg-white text-primary"
                } rounded-lg shadow-md transition-colors duration-300 relative`}
                onClick={() => handlePlatformToggle(platformId)}
              >
                {socialLoading[platformId] && <LoadingScreen />}

                {isSelected && isConnected && (
                  <FaCheck className="absolute top-2 right-2 text-green-500 h-5 w-5" />
                )}

                <div className="flex flex-col items-center w-full">
                  <div className="flex items-center justify-center mb-2 rounded-full bg-white p-1">
                    {platform.icon}
                  </div>
                  <h3 className="text-lg font-semibold">{platform.name}</h3>
                  {isConnected ? (
                    <div className="mt-2 w-full">
                      {platformConnections.map((data) => (
                        <div
                          key={data.id}
                          className="flex items-center space-x-3 mb-2"
                        >
                          <Avatar>
                            {data.userImage ? (
                              <AvatarImage
                                src={data.userImage}
                                alt={data.displayName}
                              />
                            ) : (
                              <AvatarFallback>
                                {data.displayName.charAt(0)}
                              </AvatarFallback>
                            )}
                          </Avatar>
                          <div>
                            <div className="font-semibold">
                              {data.displayName}
                            </div>

                            {platform.id === "facebook" ? (
                              <div className="text-xs text-muted-foreground">
                                Page Name: {data.pageName}
                              </div>
                            ) : (
                              <div className="text-xs text-muted-foreground">
                                Username: {data.username}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p
                      className={`${
                        isSelected ? "text-white" : "text-red-600"
                      } text-sm mt-1`}
                    >
                      Not Connected
                    </p>
                  )}
                </div>
              </Card>
            );
          })}
        </div>
      )}

      {/* Connect Accounts button */}
      {hasUnconnectedSelectedPlatforms && <ConnectSocialAccountsButton />}

      {/* Navigation Buttons */}
      <div className="flex justify-between mt-6">
        <Button variant="secondary" type="button" onClick={handleBackStep}>
          Back
        </Button>
        <Button
          type="button"
          onClick={handleNextStep}
          disabled={isNextDisabled}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ChoosePlatforms;
