import React from "react";
import { Route } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import ListingsScreen from "../screens/ListingsScreen";
import PromotionsScreen from "../screens/PromotionsScreen";
import ContentScreen from "../screens/ContentScreen";
import SettingsScreen from "../screens/SettingsScreen";
import Post from "../components/post/Post";
import UserListing from "../components/listings/UserListing";
import CreateListings from "../components/listings/CreateListings";
import CreateCampaign from "../screens/CreateCampaign";

const userRoutes = (mlsApproved: boolean | null) => (
  <>
    <Route path="/" element={<HomeScreen />} />
    <Route
      path="/listings"
      element={<ListingsScreen mlsApproved={mlsApproved} />}
    />
    <Route
      path="/create-listing"
      element={<CreateListings mlsApproved={mlsApproved || false} />}
    />
    <Route
      path="/listing"
      element={<UserListing mlsApproved={mlsApproved} />}
    />
    <Route path="/create-campaign" element={<CreateCampaign />} />
    <Route path="/promotions" element={<PromotionsScreen />} />
    <Route
      path="/my-content"
      element={<ContentScreen mlsApproved={mlsApproved} />}
    />
    <Route path="/settings" element={<SettingsScreen />} />
    <Route path="/post/:id" element={<Post />} />
  </>
);

export default userRoutes;
