import React from "react";
import UserListings from "../components/listings/UserListings";

interface ListingsScreenProps {
  mlsApproved: boolean | null;
}

const ListingsScreen: React.FC<ListingsScreenProps> = ({ mlsApproved }) => {
  return <UserListings mlsApproved={mlsApproved} />;
};

export default ListingsScreen;
