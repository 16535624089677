import React, { useState, useMemo, useEffect, useRef } from "react";
import { Promotion as ProType, PromotionProps, PromotionType } from "./types";
import CampaignCard from "./CampaignCard";
import TabsComponent from "./TabsComponent";
import MediaCarousel from "../listings/listing/MediaCarousel";
import { CarouselApi } from "../listings/Carousel";
import UserNotesCard from "./UserNotesCard";
import { useSocket } from "../../context/SocketContext";
import { CampaignProgress } from "../campaign/types";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";

const Promotion: React.FC<PromotionProps> = ({
  promotion,
  setPromotion,
  isEditing,
  setIsEditing,
  assets,
  setLoading,
  fetchAssets,
  fetchPromotions,
  originalPromotion,
}) => {
  const { socket } = useSocket();

  // State to manage the active tab: "categories" or "assets"
  const [activeTab, setActiveTab] = useState<"categories" | "assets">(
    "categories"
  );

  // State to manage the active promotion type when "assets" tab is active
  const [activePromotionType, setActivePromotionType] = useState<PromotionType>(
    promotion?.types[0] || PromotionType.Mortage
  );

  // State to manage the carousel API
  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);

  // Memoized carousel assets: All promotion type assets ordered by promotion.types
  const carouselAssets = useMemo(() => {
    return promotion?.types?.flatMap((type) =>
      assets.filter((asset) => asset.assetType === type)
    );
  }, [assets, promotion?.types]);

  // Mapping from asset ID to index in carouselAssets
  const [carouselAssetIndexMap, setCarouselAssetIndexMap] = useState<{
    [id: string]: number;
  }>({});

  const subscribedCampaignsRef = useRef<Set<string>>(new Set());

  useEffect(() => {
    const map: { [id: string]: number } = {};
    carouselAssets?.forEach((asset, index) => {
      map[asset.id] = index;
    });
    setCarouselAssetIndexMap(map);
  }, [carouselAssets]);

  // Effect to reset activePromotionType when activeTab or promotion.types change
  useEffect(() => {
    if (
      activeTab === "assets" &&
      promotion?.types &&
      promotion?.types.length > 0
    ) {
      setActivePromotionType(promotion?.types[0]);
    }
  }, [activeTab, promotion?.types]);

  console.log("Promotion:", promotion);
  useEffect(() => {
    if (!socket) return;

    if (
      promotion?.campaignId &&
      promotion?.campaignStatus === "PROCESSING" &&
      !subscribedCampaignsRef.current.has(promotion?.campaignId)
    ) {
      socket.emit("subscribeToCampaign", promotion?.campaignId);
      subscribedCampaignsRef.current.add(promotion?.campaignId);
    }

    const handleProgressUpdate = (data: {
      entityId: string;
      entityType: "campaign";
      progress: CampaignProgress;
    }) => {
      const { entityId, entityType, progress } = data;

      if (entityType === "campaign" && promotion?.campaignId === entityId) {
        if (progress.currentStep === "Completed") {
          toast.success(
            <CustomToast
              message="Campaign completed successfully."
              type="success"
            />,
            {
              autoClose: 3000,
            }
          );
          fetchPromotions();
        }

        const updatedPromotion: ProType = {
          ...promotion,
          campaignProgress: progress,
          campaignStatus:
            progress.currentStep === "Completed"
              ? "ACTIVE"
              : promotion.campaignStatus,
        };

        setPromotion(updatedPromotion);
      }
    };
    socket.on("progressUpdate", handleProgressUpdate);

    // Copy current ref values

    const currentSubscribedCampaigns = subscribedCampaignsRef.current;

    return () => {
      socket.off("progressUpdate", handleProgressUpdate);

      if (
        promotion?.campaignId &&
        currentSubscribedCampaigns.has(promotion?.campaignId)
      ) {
        socket.emit("unsubscribeFromCampaign", promotion?.campaignId);
        currentSubscribedCampaigns.delete(promotion?.campaignId);
      }
    };
  }, [socket, promotion, fetchPromotions, setPromotion]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-row gap-8">
        {/* Left Column */}
        <div className="w-full lg:flex-1 max-w-[70%]">
          <div className="flex flex-col mb-4">
            <h1 className="text-3xl font-bold text-primary">Promotion</h1>
          </div>

          {/* Media Carousel: Display if there are any promotion type assets */}
          {carouselAssets?.length && carouselAssets?.length > 0 ? (
            <MediaCarousel
              setCarouselApi={setCarouselApi}
              combinedMedia={carouselAssets.map((asset) => ({
                ...asset,
                type: "image", // Adjust if asset types vary
                assetType: asset.assetType,
              }))}
            />
          ) : null}

          {/* Tabs Component */}
          <TabsComponent
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            promotion={promotion}
            assets={assets}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            activePromotionType={activePromotionType}
            setActivePromotionType={setActivePromotionType}
            carouselApi={carouselApi}
            carouselAssetIndexMap={carouselAssetIndexMap}
            setLoading={setLoading}
            setPromotion={setPromotion}
            fetchAssets={fetchAssets}
            originalPromotion={originalPromotion}
            fetchPromotions={fetchPromotions}
          />
        </div>

        {/* Right Column */}
        <div className="w-full lg:w-1/3 max-w-[30%]">
          {/* Campaign Card */}
          {promotion ? <CampaignCard promotion={promotion} /> : null}
          {/* User Notes Card */}
          {promotion ? (
            <UserNotesCard promotion={promotion} setPromotion={setPromotion} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Promotion;
