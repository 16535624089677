import React from "react";
import { CombinedMedia } from "../types";
import VideoThumbnail from "../../VideoThumbnail";
import { FaPlay } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { MediaThumbnailsProps } from "./types";
import { Badge } from "../../../calendar/Badge";

const MediaThumbnails: React.FC<MediaThumbnailsProps> = ({
  mediaItems,
  currentPage,
  itemsPerPage,
  toggleSelectMedia,
  selectedMedia,
  isEditing,
  carouselApi,
  combinedMedia,
  mlsApproved,
  carouselIndexMap,
}) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = mediaItems.slice(startIndex, endIndex);

  return (
    <div className="grid grid-cols-5 gap-2">
      {currentItems.map((item) => {
        const mediaType = item.type;
        const media = item as CombinedMedia;

        const isSelected = selectedMedia.some((m) => m.id === media.id);
        const isDisabled = isEditing && mlsApproved && item.isMls;

        const handleClick = () => {
          if (isDisabled) return;
          if (isEditing) {
            toggleSelectMedia(media);
          } else {
            if (carouselApi) {
              const index = !carouselIndexMap
                ? combinedMedia.findIndex((m) => m.id === media.id)
                : carouselIndexMap[media.id];
              console.log("media", media);
              console.log("index", index);
              carouselApi.scrollTo(index);
            }
          }
        };

        return (
          <div
            key={media.id || item.name}
            className={`relative h-32 cursor-pointer ${
              isSelected ? "ring-2 ring-secondary rounded" : ""
            } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleClick}
          >
            {mlsApproved && isEditing && (
              <Badge
                className="absolute top-1 right-1 z-40"
                variant="secondary"
              >
                <span className="text-[10px]">
                  {item.isMls ? "MLS" : "USER"}
                </span>
              </Badge>
            )}
            {mediaType === "image" ? (
              <img
                src={item.url}
                alt={`${item.name}`}
                className="w-full h-full object-cover rounded"
              />
            ) : (
              <VideoThumbnail
                videoUrl={item.url}
                alt=""
                className="w-full h-full object-cover rounded"
              />
            )}
            {/* Play icon overlay for videos */}
            {mediaType === "video" && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded">
                <FaPlay className="text-white" size={24} />
              </div>
            )}
            {/* Overlay when item is selected */}
            {isSelected && (
              <div className="absolute top-2 left-2 bg-primary flex flex-row justify-center items-center p-1 rounded-xl">
                <FiCheckCircle className=" text-secondary" />
              </div>
            )}
            {/* Disabled overlay */}
            {isDisabled && (
              <div className="absolute inset-0 bg-gray-500 bg-opacity-30 rounded"></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MediaThumbnails;
