/**
 * This code was generated by Builder.io.
 */
import React from "react";
import VerificationMessage from "../components/Email-Verification/VerificationMessage";
import Button from "../components/Button";
import { handleLogoutGenerator } from "../global-actions/handleLogout";
import {auth} from "../firebase/firebase-config";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "firebase/auth";
import axios from "axios";

const VerifyEmailScreen: React.FC = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const handleResendEmail = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error("User not authenticated");
      const token = await getIdToken(currentUser, true);
      await axios.get(`${backendUrl}/send-verification-email`, {
        headers: { Authorization: `Bearer ${token}` },
      }).catch((error: Error) => {console.error(error)});
    } catch (error) {
      console.error("Error resending email verification: ", error);
    }
  }
  return (
    <main className="flex overflow-hidden flex-col justify-center items-center h-screen bg-gray-300 shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
      <section className="flex relative gap-2.5 items-start px-14 py-24 max-w-full min-h-[833px] w-[605px] max-md:px-5">
        <div className="flex absolute right-0 bottom-0 z-0 flex-col self-start rounded-2xl h-[833px] min-w-[240px] w-[605px] max-md:max-w-full">
          <div className="flex shrink-0 gap-2.5 bg-white rounded-2xl h-[833px] max-md:max-w-full" />
        </div>
        <div className="flex z-0 flex-col items-center my-auto h-[645px] min-w-[240px] w-[499px] max-md:max-w-full">
          <div className="flex flex-col max-w-full w-[395px]">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f02578b42759dd740478965bd8afaed409ec67c254c6387c83e0997d13df6599?placeholderIfAbsent=true&apiKey=dbe43292bfea4986bc9beb1eef1ca547"
              alt=""
              className="object-contain w-full aspect-[8.77]"
            />
          </div>
          <VerificationMessage />
          <Button
          type="button"
          text="Log Out"
          onClick={handleLogoutGenerator(auth, navigate)}
          className="w-full mt-4"
        />
        <Button
          type="button"
          text="Resend Verification Email"
          onClick={handleResendEmail}
          className="w-full mt-4"
        />
          <div className="flex mt-11 max-w-full min-h-[159px] w-[402px] max-md:mt-10" />
        </div>
      </section>
    </main>
  );
};

export default VerifyEmailScreen;
