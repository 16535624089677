import React from "react";
import { Card, CardContent } from "../../misc/Card";
import { Button } from "../../misc/Button";
import { FaPlus } from "react-icons/fa";
import { SlideshowGeneratorCardProps } from "./types";
import { Loader2 } from "lucide-react";

const SlideshowGeneratorCard: React.FC<SlideshowGeneratorCardProps> = ({
  openSlideshow,
  isGenerating,
}) => {
  return (
    <Card className="mt-4">
      <CardContent className="pt-6">
        <h2 className="text-xl font-semibold mb-4 text-primary text-center">
          Slideshow Generator
        </h2>
        {/* Trigger Button Inside the Card */}
        <Button
          size="lg"
          className="w-full font-semibold"
          onClick={() => openSlideshow()}
          disabled={isGenerating}
        >
          {isGenerating ? (
            <>
              <Loader2 className="mr-1 h-6 w-6 animate-spin" />
              <span>Generating ...</span>
            </>
          ) : (
            <>
              <FaPlus className="mr-1 h-6 w-6" />
              <span>Create Slideshow</span>
            </>
          )}
        </Button>
      </CardContent>
    </Card>
  );
};

export default SlideshowGeneratorCard;
