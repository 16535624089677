import React from "react";

interface StepItemProps {
  label: string;
  isActive: boolean;
}

const StepItem: React.FC<StepItemProps> = ({ label, isActive }) => {
  return (
    <div className="flex flex-col items-center self-stretch my-auto w-12">
      <div
        className={`flex justify-center items-center px-0.5 w-5 h-5 bg-white border ${
          isActive ? "border-secondary" : "border-stone-300"
        } border-solid min-h-[20px] rounded-full relative`}
      >
        {isActive && (
          <div className="w-2.5 h-2.5 bg-secondary rounded-full"></div>
        )}
      </div>
      <div className="text-xs font-medium tracking-wide leading-3 text-center text-primary">
        {label}
      </div>
    </div>
  );
};

export default StepItem;
