import React, { useState } from "react";
import {
  Dialog,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogContent,
} from "../../misc/Dialog";
import { SlideshowProps } from "./types";
import ImageSelector from "./ImageSelector";
import SlideshowOptions from "./SlideshowOptions";
import LoadingScreen from "../../../screens/LoadingScreen";
import { Button } from "../../misc/Button";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import axios from "axios";
import { getAuthHeader } from "../../../utils/authHeader";
import { FaTimes } from "react-icons/fa";
import { Loader2 } from "lucide-react";
import { MAX_IMAGES, MIN_IMAGES } from "./utils";

const Slideshow: React.FC<SlideshowProps> = ({
  listingId,
  images,
  isOpen,
  onClose,
  videos,
  setVideos,
  formattedAddress,
  isGenerating,
  setIsGenerating,
}) => {
  const [step, setStep] = useState(1);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectedOverlay, setSelectedOverlay] = useState<string | null>(null);
  const [newOverlayHasLogo, setNewOverlayHasLogo] = useState(false);
  const [includeMusic, setIncludeMusic] = useState(false);
  const [music, setMusic] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [includeOverlay, setIncludeOverlay] = useState(false);
  const [notSeleted, setNotSelected] = useState(false);

  const handleImageSelect = (id: string) => {
    setSelectedImages((prevSelectedImages) => {
      // If the image is already selected, remove it
      if (prevSelectedImages.includes(id)) {
        return prevSelectedImages.filter((imageId) => imageId !== id);
      } else {
        // Check if maximum limit is reached
        if (prevSelectedImages.length >= MAX_IMAGES) {
          toast.error(
            <CustomToast
              message={`You can select up to ${MAX_IMAGES} images.`}
              type="error"
            />
          );
          return prevSelectedImages;
        }
        // Add the new image ID to the end of the array
        return [...prevSelectedImages, id];
      }
    });
  };

  const handleGenerate = async () => {
    setIsGenerating(true);
    // Simulate slideshow generation
    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    try {
      console.log(selectedOverlay);

      const response = await axios.post(
        `${backendUrl}/slideshow/`,
        {
          listingId,
          imageList: selectedImages,
          overlay: selectedOverlay,
          audioLink: music,
        },
        { headers }
      );

      if (response.status === 201) {
        const { id, name, url } = response.data;

        setVideos([
          ...videos,
          {
            id,
            name,
            url,
          },
        ]);

        setIsGenerating(false);
        onClose();
        toast.success(
          <CustomToast
            message="Slideshow generated successfully."
            type="success"
          />,
          { autoClose: 3000 }
        );
      } else {
        toast.error(
          <CustomToast
            message="An error occurred while generating the slideshow. Please try again."
            type="error"
          />,
          { autoClose: 3000 }
        );

        setIsGenerating(false);
        onClose();
      }
    } catch (error: unknown) {
      setIsGenerating(false);
      toast.error(
        <CustomToast
          message="An error occurred while generating the slideshow. Please try again."
          type="error"
        />,
        { autoClose: 3000 }
      );
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      {/* DialogTrigger can be omitted if not needed */}
      <DialogContent className=" relative flex flex-col space-y-6">
        {loading && <LoadingScreen />}

        <button
          className="absolute top-4 right-4 text-secondary hover:text-primary"
          onClick={onClose}
        >
          <FaTimes className="h-6 w-6" />
        </button>
        <DialogHeader>
          <DialogTitle>Create Slideshow</DialogTitle>
          <DialogDescription>
            Create a custom slideshow from your images
          </DialogDescription>
        </DialogHeader>

        {step === 1 && (
          <ImageSelector
            images={images}
            selectedImages={selectedImages}
            onSelect={handleImageSelect}
            maxImages={MAX_IMAGES}
            minImages={MIN_IMAGES}
          />
        )}

        {step === 2 && (
          <SlideshowOptions
            includeOverlay={includeOverlay}
            setIncludeOverlay={setIncludeOverlay}
            includeMusic={includeMusic}
            setIncludeMusic={setIncludeMusic}
            selectedOverlay={selectedOverlay}
            setSelectedOverlay={setSelectedOverlay}
            newOverlayHasLogo={newOverlayHasLogo}
            setNewOverlayHasLogo={setNewOverlayHasLogo}
            formattedAddress={formattedAddress}
            toggleMusic={() => setIncludeMusic((prev) => !prev)}
            setLoading={setLoading}
            setNotSelected={setNotSelected}
            music={music}
            setMusic={setMusic}
            listingId={listingId}
          />
        )}

        {/* Navigation Buttons */}
        <div
          className={`flex ${step === 2 ? "justify-between" : "justify-end"}`}
        >
          {step === 2 && (
            <Button variant="secondary" onClick={() => setStep(1)}>
              Back to Image Selection
            </Button>
          )}
          {step === 1 && (
            <Button
              onClick={() => setStep(2)}
              disabled={selectedImages.length < MIN_IMAGES}
            >
              Continue to Overlay & Music
            </Button>
          )}
          {step === 2 && (
            <Button
              onClick={handleGenerate}
              disabled={
                isGenerating || selectedImages.length === 0 || notSeleted
              }
            >
              {isGenerating ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Generating...
                </>
              ) : (
                "Generate Slideshow"
              )}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Slideshow;
