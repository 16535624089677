import React, { useState } from "react";
import { TabsContent } from "../misc/Tabs";
import { Card, CardContent } from "../misc/Card";
import {
  categories,
  PromotionTabProps,
  PromotionType,
  samplePosts,
} from "./types";
import { Switch } from "../misc/Switch";
import { FaCircleInfo, FaComment, FaShare, FaThumbsUp } from "react-icons/fa6";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../misc/Dialog";
import { FaTimes, FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Label } from "../misc/Label";
import { Popover, PopoverContent, PopoverTrigger } from "../misc/Popover";
import { Button } from "../misc/Button";
import { Paintbrush } from "lucide-react";
import { colors } from "../../utils/colors";

const PromotionTab: React.FC<PromotionTabProps> = ({
  isEditing,
  selectedCategories,
  setSelectedCategories,
  promotion,
  setPromotion,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const [infoOverlay, setInfoOverlay] = useState<boolean>(false);
  const [selectedInfo, setSelectedInfo] = useState<string | null>(null);

  const userName = user?.nameFirst + " " + user?.nameLast || "Jane Doe";
  const userAvatar = user?.photoURL || user?.profileImage || null;

  // Function to toggle category selection
  const handleCategoryToggle = (categoryId: PromotionType) => {
    setSelectedCategories(
      selectedCategories.includes(categoryId)
        ? selectedCategories.filter((id) => id !== categoryId)
        : [...selectedCategories, categoryId]
    );
  };

  const handleCategoryInfo = (categoryLabel: string) => {
    setInfoOverlay(true);
    setSelectedInfo(categoryLabel);
  };

  const selectedPost = samplePosts.find((post) => post.label === selectedInfo);
  console.log(selectedCategories);
  return (
    <TabsContent value="categories" className="relative z-0">
      <Card>
        <CardContent className="relative pt-6">
          <h3 className={`relative font-bold text-xl mb-10 text-primary`}>
            Promotion Categories
          </h3>
          {promotion ? (
            <div className="absolute bg-white top-6 right-4 space-y-2 px-4">
              <Label className="font-bold text-base text-primary">
                Campaign Color
              </Label>
              <div className="flex items-center gap-4">
                <div
                  className="w-8 h-8 rounded-md border shadow-sm"
                  style={{ backgroundColor: promotion.color }}
                />
                {isEditing && (
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        className="flex items-center gap-2 h-8"
                      >
                        <Paintbrush className="w-4 h-4" />
                        Select Color
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-80">
                      <div className="grid grid-cols-10 grid-rows-5 gap-1">
                        {colors.map((color) => (
                          <button
                            key={color}
                            className="w-6 h-6 rounded-sm border shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            style={{ backgroundColor: color }}
                            onClick={() =>
                              setPromotion({ ...promotion, color })
                            }
                          />
                        ))}
                      </div>
                      <p className="mt-4 text-sm text-muted-foreground">
                        The campaign color is used to quickly identify social
                        media posts that are connected to this promotional
                        campaign. This color will not be visible on social
                        media.
                      </p>
                    </PopoverContent>
                  </Popover>
                )}
              </div>
            </div>
          ) : null}

          <div className="py-4 px-2 grid grid-cols-2 gap-4">
            {categories.map((category) => {
              const Icon = category.icon;
              const isSelected = selectedCategories.includes(
                category.id as PromotionType
              );
              return (
                <div
                  key={category.id}
                  className={`flex items-center space-x-3 p-3 rounded-lg transition-colors ${
                    selectedCategories.includes(category.id as PromotionType)
                      ? isEditing
                        ? "bg-lightSecondary"
                        : "bg-secondary"
                      : "bg-gray-50"
                  } cursor-pointer hover:bg-lightSecondary`}
                  onClick={() =>
                    isEditing &&
                    handleCategoryToggle(category.id as PromotionType)
                  }
                >
                  <div
                    className={`p-1 rounded ${
                      isSelected
                        ? "bg-primary text-secondary"
                        : "bg-secondary text-white"
                    }`}
                  >
                    <Icon className="w-4 h-4" />
                  </div>
                  <div className="flex-grow">
                    <label
                      htmlFor={category.id}
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {category.label}
                    </label>
                  </div>
                  {isEditing ? (
                    <Switch
                      id={category.id}
                      checked={isSelected}
                      onChange={() =>
                        handleCategoryToggle(category.id as PromotionType)
                      }
                    />
                  ) : (
                    <FaCircleInfo
                      className={`${
                        selectedCategories.includes(
                          category.id as PromotionType
                        )
                          ? "text-white"
                          : "text-secondary"
                      } w-6 h-6 hover:text-primary`}
                      onClick={() => {
                        handleCategoryInfo(category.label);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>
      {/* Category Info Overlay */}
      {infoOverlay ? (
        <Dialog
          open={infoOverlay}
          onOpenChange={() => {
            setInfoOverlay(false);
          }}
        >
          <DialogContent className="relative">
            <button
              className="absolute top-4 right-4 text-secondary hover:text-primary"
              onClick={() => {
                setInfoOverlay(false);
              }}
            >
              <FaTimes className="h-6 w-6" />
            </button>
            <DialogHeader>
              <DialogTitle>{selectedInfo}</DialogTitle>
              <DialogDescription>
                This is an example post and not a real post. The post generated
                for you will priorotize any uploaded images or will generate one
                for the selected category.
              </DialogDescription>
            </DialogHeader>

            {selectedPost ? (
              <div className="max-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden my-4">
                {/* Header */}
                <div className="flex items-center px-4 py-3">
                  {userAvatar ? (
                    <img
                      className="h-10 w-10 rounded-full object-cover"
                      src={userAvatar}
                      alt={`${userName}'s avatar`}
                    />
                  ) : (
                    <FaUserCircle className="h-10 w-10 text-gray-400" />
                  )}
                  <div className="ml-3">
                    <span className="block text-gray-800 font-semibold">
                      {userName}
                    </span>
                    <span className="block text-gray-500 text-sm">
                      {new Date().toLocaleDateString()}
                    </span>
                  </div>
                </div>

                {/* Post Image */}
                <img
                  className="w-full h-64 object-cover"
                  src={selectedPost.img}
                  alt={selectedPost.label}
                />

                {/* Post Text */}
                <div className="px-4 py-3">
                  <p className="text-gray-700">{selectedPost.text}</p>
                </div>

                {/* Action Buttons */}
                <div className="flex items-center justify-between px-4 py-2 border-t">
                  <button className="flex items-center justify-center text-gray-600 hover:text-blue-500">
                    <FaThumbsUp className="h-5 w-5 text-secondary mr-1" />
                    <span className="flex items-center text-primary">
                      Like (100)
                    </span>
                  </button>
                  <button className="flex items-center text-gray-600 hover:text-blue-500">
                    <FaComment className="h-5 w-5 text-secondary mr-1" />
                    <span className="flex items-center text-primary">
                      Comments (50)
                    </span>
                  </button>
                  <button className="flex items-center text-gray-600 hover:text-blue-500">
                    <FaShare className="h-5 w-5 text-secondary mr-1" />
                    <span className="flex items-center text-primary">
                      Share (25)
                    </span>
                  </button>
                </div>
              </div>
            ) : null}
          </DialogContent>
        </Dialog>
      ) : null}
    </TabsContent>
  );
};

export default PromotionTab;
