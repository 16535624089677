import React from "react";
import { Badge } from "../../calendar/Badge";

interface PropertyHeaderProps {
  formattedAddress: string;
  propertyType: string;
  status: string | undefined;
}

const ListingHeader: React.FC<PropertyHeaderProps> = ({
  formattedAddress,
  propertyType,
  status,
}) => {
  return (
    <div className="flex flex-col mb-4">
      <h1 className="text-3xl font-bold text-primary">{formattedAddress}</h1>
      <div className="flex items-center gap-2 mt-2">
        {propertyType && (
          <Badge variant="secondary">{propertyType.replace(/,/g, ", ")}</Badge>
        )}
        {status && (
          <Badge variant={status !== "Active" ? "destructive" : "primary"}>
            {status}
          </Badge>
        )}
      </div>
    </div>
  );
};

export default ListingHeader;
