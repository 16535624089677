import React from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebase-config";
import { useDispatch } from "react-redux";
import { setIsAdminView } from "../store/authSlice";

const AdminPanelScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoToHome = () => {
    dispatch(setIsAdminView(false));
    sessionStorage.setItem("isAdminView", "false");
    navigate("/");
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div>
      <h1>Admin Panel</h1>

      <button
        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
        onClick={handleGoToHome}
      >
        Go to Home
      </button>
      <button
        onClick={handleLogout}
        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
      >
        Logout
      </button>
    </div>
  );
};

export default AdminPanelScreen;
