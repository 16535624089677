import React, { useEffect, useRef, useState } from "react";
import LoadingScreen from "../../screens/LoadingScreen";

interface VideoThumbnailProps {
  videoUrl: string;
  className?: string;
  alt?: string;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  videoUrl,
  className,
  alt,
}) => {
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(document.createElement("canvas"));

  useEffect(() => {
    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;
    if (!videoElement || !canvasElement) return;

    const handleLoadedMetadata = () => {
      videoElement.currentTime = 0;
    };

    const handleTimeUpdate = () => {
      try {
        const ctx = canvasElement.getContext("2d");
        if (ctx) {
          canvasElement.width = videoElement.videoWidth;
          canvasElement.height = videoElement.videoHeight;
          ctx.drawImage(
            videoElement,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );
          const dataURL = canvasElement.toDataURL("image/png");
          setThumbnail(dataURL);
          videoElement.pause();
        }
      } catch (error) {
        console.error("Error generating thumbnail:", error);
      }
    };

    videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
    videoElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      videoElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [videoUrl]);

  return (
    <>
      {thumbnail ? (
        <img src={thumbnail} alt={alt} className={className} />
      ) : (
        <div className="relative h-full w-full">
          <LoadingScreen />
        </div>
      )}
      {/* Hidden video element with crossOrigin attribute */}
      <video
        ref={videoRef}
        src={videoUrl}
        crossOrigin="anonymous"
        style={{ display: "none" }}
      />
    </>
  );
};

export default VideoThumbnail;
