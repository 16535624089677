import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import calendarReducer from "./calendarSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // localStorage
import sessionStorage from "redux-persist/lib/storage/session"; // sessionStorage

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["auth"],
};

const calendarPersistConfig = {
  key: "calendar",
  storage: sessionStorage,
  whitelist: [
    "selectedView",
    "selectedListingType",
    "selectedPlatform",
    "selectedApprovalStatus",
  ],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  calendar: persistReducer(calendarPersistConfig, calendarReducer),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
