import React, { useState, useRef, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { User } from "../types/user";
import { handleLogoutGenerator } from "../global-actions/handleLogout";
import { auth } from "../firebase/firebase-config";
import { useDispatch } from "react-redux";
import { setIsAdminView } from "../store/authSlice";

interface TopBarProps {
  role: string | null;
  user: User | null;
  showButton?: boolean;
  buttonTitle?: string;
  onButtonClick?: () => void;
}

const TopBar: React.FC<TopBarProps> = ({
  role,
  user,
  buttonTitle,
  showButton,
  onButtonClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleAdmin = () => {
    dispatch(setIsAdminView(true));
    sessionStorage.setItem("isAdminView", "true");
    navigate("/admin");
    setIsDropdownOpen(false); // Close dropdown after navigating
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Close dropdown when interacting outside (click, touch, scroll)
  useEffect(() => {
    const handleInteractionOutside = (event: Event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      // Add event listeners for various interaction types
      document.addEventListener("mousedown", handleInteractionOutside);
      document.addEventListener("touchstart", handleInteractionOutside);
      window.addEventListener("scroll", handleInteractionOutside, true);
    }

    // Cleanup event listeners when dropdown is closed or component unmounts
    return () => {
      document.removeEventListener("mousedown", handleInteractionOutside);
      document.removeEventListener("touchstart", handleInteractionOutside);
      window.removeEventListener("scroll", handleInteractionOutside, true);
    };
  }, [isDropdownOpen]);

  const handleLogout = handleLogoutGenerator(auth, navigate);

  return (
    <div className="flex items-center justify-between px-6 py-4 bg-white w-full h-full">
      {/* Logo Section */}
      <div
        className="flex items-center space-x-2 cursor-pointer"
        onClick={handleLogoClick}
      >
        <img src={logo} alt="Marabot Logo" className="h-10" />
      </div>

      {/* Conditional Button - Hidden on Mobile */}
      {showButton && (
        <div className="hidden md:block">
          <button
            onClick={onButtonClick}
            className="px-4 py-2 bg-primary text-white rounded-md shadow-md hover:bg-secondary transition"
          >
            {buttonTitle}
          </button>
        </div>
      )}

      {/* User Account Section */}
      <div className="flex items-center space-x-4 gap-4">
        {/* Notification Icon */}
        <FaBell
          size={24}
          className="text-2xl text-secondary hover:text-primary cursor-pointer"
        />

        {/* User Profile Icon */}
        <div className="relative" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="flex items-center justify-center w-10 h-10 bg-secondary text-white rounded-full focus:outline-none"
            aria-haspopup="true"
            aria-expanded={isDropdownOpen}
          >
            {user?.profileImage ? (
              <img
                src={user.profileImage}
                alt="User"
                className="w-10 h-10 rounded-full"
              />
            ) : user?.photoURL ? (
              <img
                src={user.photoURL}
                alt="User"
                className="w-10 h-10 rounded-full"
              />
            ) : (
              <span className="text-lg font-semibold">
                {user &&
                  user.nameFirst &&
                  user.nameLast &&
                  user?.nameFirst[0].toUpperCase() +
                    user?.nameLast[0].toUpperCase()}
              </span>
            )}
          </button>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div
              className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md z-50"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
            >
              <ul>
                {role === "ADMIN" && (
                  <li>
                    <button
                      className="w-full text-left px-4 py-2 bg-secondary text-white hover:bg-primary"
                      onClick={handleAdmin}
                    >
                      Admin
                    </button>
                  </li>
                )}
                <li>
                  <button
                    className="w-full text-left px-4 py-2 hover:bg-secondary hover:text-white"
                    onClick={() => {
                      navigate("/settings");
                      setIsDropdownOpen(false);
                    }}
                  >
                    Settings
                  </button>
                </li>
                <li>
                  <button
                    className="w-full text-left px-4 py-2 bg-[#E53935] hover:bg-primary text-white"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
