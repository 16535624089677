import { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./Select";
import { Label } from "../misc/Label"; // Assuming you have a Label component

const TimePicker = ({
  time,
  onChange,
}: {
  time: string;
  onChange: (time: string) => void;
}) => {
  // Parse the time string into hours and minutes
  const parseTime = (timeString: string) => {
    const [hourStr, minuteStr] = timeString.split(":");
    let hour = parseInt(hourStr, 10) || 0;
    const minute = parseInt(minuteStr, 10) || 0;
    const period = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12; // Convert to 12-hour format
    return {
      hour,
      minute,
      period,
    };
  };

  const initialTime = parseTime(time);

  const [hours, setHours] = useState<number>(initialTime.hour);
  const [minutes, setMinutes] = useState<number>(initialTime.minute);
  const [period, setPeriod] = useState<string>(initialTime.period);

  // Update local state when `time` prop changes
  useEffect(() => {
    const parsedTime = parseTime(time);
    setHours(parsedTime.hour);
    setMinutes(parsedTime.minute);
    setPeriod(parsedTime.period);
  }, [time]);

  const formatTime = (hours: number, minutes: number, period: string) => {
    let adjustedHours = hours;
    if (period === "PM" && hours !== 12) {
      adjustedHours += 12;
    }
    if (period === "AM" && hours === 12) {
      adjustedHours = 0;
    }
    return `${adjustedHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  const handleHourChange = (newHours: number) => {
    setHours(newHours);
    onChange(formatTime(newHours, minutes, period));
  };

  const handleMinuteChange = (newMinutes: number) => {
    setMinutes(newMinutes);
    onChange(formatTime(hours, newMinutes, period));
  };

  const handlePeriodChange = (newPeriod: string) => {
    setPeriod(newPeriod);
    onChange(formatTime(hours, minutes, newPeriod));
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <div className="flex space-x-4">
        {/* Hour Selector */}
        <div>
          <Label className="block text-sm font-semibold text-primary mb-1">
            Hour
          </Label>
          <Select
            value={hours.toString()}
            onValueChange={(value) => handleHourChange(parseInt(value))}
          >
            <SelectTrigger className="w-16">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
                <SelectItem key={hour} value={hour.toString()}>
                  {hour.toString().padStart(2, "0")}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {/* Minute Selector */}
        <div>
          <Label className="block text-sm font-semibold text-primary mb-1">
            Minute
          </Label>
          <Select
            value={minutes.toString()}
            onValueChange={(value) => handleMinuteChange(parseInt(value))}
          >
            <SelectTrigger className="w-16">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
                <SelectItem key={minute} value={minute.toString()}>
                  {minute.toString().padStart(2, "0")}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {/* AM/PM Selector */}
        <div>
          <Label className="block text-sm font-semibold text-primary mb-1">
            AM/PM
          </Label>
          <Select
            value={period}
            onValueChange={(value) => handlePeriodChange(value)}
          >
            <SelectTrigger className="w-16">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {["AM", "PM"].map((p) => (
                <SelectItem key={p} value={p}>
                  {p}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
