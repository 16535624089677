import { SelectedAddress } from "../types/selectedAddress";

export const compileAddress = (address: SelectedAddress): string => {
  const { address1, address2, city, state, zipCode } = address;
  return `${address1}${
    address2 ? `, ${address2}` : ""
  }, ${city}, ${state} ${zipCode}`;
};

export const deCompileAddress = (address: string): SelectedAddress => {
  const parts = address.split(", ").map((part) => part.trim());

  let address1 = "";
  let address2 = "";
  let city = "";
  let state = "";
  let zipCode = "";
  let stateZip = "";

  if (parts.length === 4) {
    [address1, address2, city, stateZip] = parts;
  } else if (parts.length === 3) {
    [address1, city, stateZip] = parts;
    address2 = "";
  } else {
    console.error("Unexpected address format:", address);
  }

  const stateZipParts = stateZip.split(" ");
  if (stateZipParts.length >= 2) {
    state = stateZipParts[0];
    zipCode = stateZipParts.slice(1).join(" ");
  } else {
    state = stateZipParts[0] || "";
    zipCode = "";
  }

  return {
    address1,
    address2,
    city,
    state,
    zipCode,
  };
};
