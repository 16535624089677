import { Assets, AssetType } from "../../types/asset-type";
import { Images } from "../../types/listingImages";
import { ListingVideos } from "../../types/listingVideos";
import { CarouselApi } from "../listings/Carousel";
import { PromotionType } from "../promotions/types";

export interface ContentTabsProps {
  promotions: PromotionsMedia | null;
  activeTab: "listing" | "promotion";
  setActiveTab: (value: "listing" | "promotion") => void;
  listingMedia: AllListingMedia[];
  listingTabs: string[];
  activeListingTab: string;
  setActiveListingTab: (value: string) => void;
  loading: boolean;
  mlsApproved: boolean;
  carouselApi: CarouselApi | null;
  fetchAssets: () => void;
  fetchListingMedia: () => void;
  setLoading: (value: boolean) => void;
  carouselIndexMap: { [id: string]: number };
  resources: Assets[];
  fetchResources: () => void;
}

export const promotionTypes: PromotionType[] = [
  PromotionType.BuyerEducation,
  PromotionType.BuyerTips,
  PromotionType.ClosingProcess,
  PromotionType.FirstTimeHomeBuyer,
  PromotionType.HomeImprovement,
  PromotionType.HomeMaintenance,
  PromotionType.HomeSecurity,
  PromotionType.Mortage,
  PromotionType.MythBusting,
  PromotionType.RealtorAdvocacy,
  PromotionType.Sustainability,
];

export enum ResourceType {
  Image = "Image",
  Video = "Video",
  Logo = "Logo",
}

export const resourceTypes: ResourceType[] = [
  ResourceType.Image,
  ResourceType.Video,
  ResourceType.Logo,
];

export interface ListingsTabProps {
  listingMedia: AllListingMedia[];
  listingTabs: string[];
  activeListingTab: string;
  setActiveListingTab: (value: string) => void;
  activeTab: "listing" | "promotion";
  loading: boolean;
  mlsApproved: boolean;
  carouselApi: CarouselApi | null;
  setLoading: (value: boolean) => void;
  fetchListingMedia: () => void;
  carouselIndexMap: { [id: string]: number };
  isEditing: boolean;
}

export interface PromotionTabProps {
  assets: Assets[];
  activePromotionTab: AssetType;
  setActivePromotionTab: (value: AssetType) => void;
}

export interface ContentProps {
  mlsApproved: boolean | null;
}

export interface AllListingMedia {
  listingId: string;
  listingType: string;
  address: string;
  images: Images[];
  videos: ListingVideos[];
  assets: Assets[];
}

export interface PromotionsMedia {
  id: string;
  assets: Assets[];
}

export interface ResourcesProps {
  resources: Assets[];
  isEditing: boolean;
  carouselApi: CarouselApi | null;
  carouselIndexMap: { [id: string]: number };
  fetchResources: () => void;
  setLoading: (value: boolean) => void;
}
