import React, { useState } from "react";
import { Button } from "../../../misc/Button";
import { FaEdit, FaSave, FaSync, FaTrash } from "react-icons/fa";
import { AiOutlineFileDone } from "react-icons/ai";
import { FaBoxArchive, FaPlay } from "react-icons/fa6";
import { TabButtonProps } from "./types";
import { getAuthHeader } from "../../../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../../../misc/CustomToast";
import ReconfirmOverlay from "../../../misc/ReconfirmOverlay";
import { useNavigate } from "react-router-dom";

type ConfirmAction = "markInactive" | "markSold" | "deleteListing" | null;

const TabButtons: React.FC<TabButtonProps> = ({
  isEditing,
  setIsEditing,
  setIsLoading,
  propertyData,
  fetchListing,
  handleSave,
  mlsApproved,
}) => {
  const navigate = useNavigate();

  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const [actionToConfirm, setActionToConfirm] = useState<ConfirmAction>(null);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);

  // Generic handler to set the action and open the overlay
  const handleAction = (action: ConfirmAction) => {
    setActionToConfirm(action);
    setIsOverlayOpen(true);
  };

  // Confirmation handler based on the action
  const confirmAction = async () => {
    if (!actionToConfirm) return;

    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      switch (actionToConfirm) {
        case "markInactive": {
          const response = await axios.patch(
            `${backendUrl}/listing/`,
            { status: "Inactive", listingId: propertyData?._id },
            { headers }
          );

          if (response.status === 200) {
            toast.success(
              <CustomToast
                message="Listing marked as inactive."
                type="success"
              />,
              { autoClose: 3000 }
            );
            navigate("/listings");
          } else {
            throw new Error("Failed to mark listing as inactive.");
          }
          break;
        }

        case "markSold": {
          const response = await axios.patch(
            `${backendUrl}/listing/`,
            { status: "Sold", listingId: propertyData?._id },
            { headers }
          );

          if (response.status === 200) {
            toast.success(
              <CustomToast message="Listing marked as sold." type="success" />,
              { autoClose: 3000 }
            );
            navigate("/listings");
          } else {
            throw new Error("Failed to mark listing as sold.");
          }
          break;
        }

        case "deleteListing": {
          const response = await axios.delete(
            `${backendUrl}/${mlsApproved ? "realtor-listing" : "listing"}/${
              propertyData?._id
            }`,
            { headers }
          );

          if (response.status === 200) {
            toast.success(
              <CustomToast
                message="Listing deleted successfully."
                type="success"
              />,
              { autoClose: 3000 }
            );
            navigate("/listings");
          } else {
            throw new Error("Failed to delete listing.");
          }
          break;
        }

        default:
          break;
      }
    } catch (error) {
      console.error(`Error performing action ${actionToConfirm}:`, error);
      toast.error(
        <CustomToast message={`Failed to perform the action.`} type="error" />,
        { autoClose: 3000 }
      );
    } finally {
      setIsOverlayOpen(false);
      setActionToConfirm(null);
    }
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
    setActionToConfirm(null);
  };

  const handleListingDelete = () => {
    handleAction("deleteListing");
  };

  const handleMarkInactive = () => {
    handleAction("markInactive");
  };

  const handleMarkSold = () => {
    handleAction("markSold");
  };

  const handleMLSSync = async () => {
    try {
      setIsSyncing(true);
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.post(
        `${backendUrl}/realtor-listing/sync`,
        { listingId: propertyData?._id },
        { headers }
      );
      if (response.status === 200) {
        toast.success(
          <CustomToast
            message="Synced with MLS successfully."
            type="success"
          />,
          { autoClose: 3000 }
        );
      } else {
        toast.error(
          <CustomToast message="Failed to sync with MLS." type="error" />,
          { autoClose: 3000 }
        );
      }
    } catch (error) {
      console.error("Error syncing with MLS:", error);
      toast.error(
        <CustomToast message="Failed to sync with MLS." type="error" />,
        { autoClose: 3000 }
      );
    } finally {
      setIsSyncing(false);
    }
  };

  // Define title and message based on action
  const getOverlayContent = () => {
    switch (actionToConfirm) {
      case "markInactive":
        return {
          title: "Mark as Inactive",
          message: "Are you sure you want to mark this listing as inactive?",
          messageTwo:
            "This will pause all marketing actions for this campaign.",
          confirmText: "Mark Inactive",
        };
      case "markSold":
        return {
          title: "Mark as Sold",
          message: "Are you sure you want to mark this listing as sold?",
          messageTwo: "This action cannot be undone.",
          confirmText: "Mark Sold",
        };
      case "deleteListing":
        return {
          title: "Delete Listing",
          message: "Are you sure you want to delete this listing?",
          messageTwo: "This action cannot be undone.",
          confirmText: "Delete Listing",
        };
      default:
        return {
          title: "",
          message: "",
          messageTwo: "",
          confirmText: "",
        };
    }
  };

  const { title, message, messageTwo, confirmText } = getOverlayContent();

  const handleMarkActive = async () => {
    setIsLoading(true);
    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.patch(
        `${backendUrl}/listing/`,
        { status: "Active", listingId: propertyData?._id },
        { headers }
      );

      if (response.status === 200) {
        setIsLoading(false);
        toast.success(
          <CustomToast message="Listing marked as active." type="success" />,
          { autoClose: 3000 }
        );
        fetchListing();
      } else {
        throw new Error("Failed to mark listing as active.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error marking listing as active:", error);
      toast.error(
        <CustomToast
          message="Failed to mark listing as active."
          type="error"
        />,
        { autoClose: 3000 }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePauseCampaign = async () => {
    if (!propertyData.campaignId) return;

    setIsLoading(true);

    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.patch(
        `${backendUrl}/campaign/${propertyData.campaignId}`,
        { status: "PAUSED" },
        { headers }
      );

      if (response.status === 200) {
        setIsLoading(false);
        toast.success(
          <CustomToast
            message="Campaign paused successfully."
            type="success"
          />,
          { autoClose: 3000 }
        );
        fetchListing();
      } else {
        throw new Error("Failed to pause campaign.");
      }
    } catch (error: unknown) {
      setIsLoading(false);
      console.error("Error pausing campaign", error);
      toast.error(
        <CustomToast
          message="Error pausing campaign. Please try again later."
          type="error"
        />,
        { autoClose: 3000 }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResumeCampaign = async () => {
    if (!propertyData.campaignId) return;

    setIsLoading(true);

    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.patch(
        `${backendUrl}/campaign/${propertyData.campaignId}`,
        { status: "ACTIVE" },
        { headers }
      );

      if (response.status === 200) {
        setIsLoading(false);
        toast.success(
          <CustomToast
            message="Campaign resumed successfully."
            type="success"
          />,
          { autoClose: 3000 }
        );
        fetchListing();
      } else {
        throw new Error("Failed to resume campaign.");
      }
    } catch (error: unknown) {
      setIsLoading(false);
      console.error("Error resuming campaign", error);
      toast.error(
        <CustomToast
          message="Error resuming campaign. Please try again later."
          type="error"
        />,
        { autoClose: 3000 }
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isEditing ? (
        <>
          <div className="flex flex-row justify-end space-x-2">
            {!mlsApproved ? (
              <>
                {propertyData?.status === "Inactive" ? (
                  <Button
                    className="bg-secondary hover:bg-primary"
                    onClick={handleMarkActive}
                  >
                    <FaBoxArchive className="h-4 w-4" />
                    Mark Active
                  </Button>
                ) : (
                  <Button
                    className="bg-secondary hover:bg-primary"
                    onClick={handleMarkInactive}
                  >
                    <FaBoxArchive className="h-4 w-4" />
                    Mark Inactive
                  </Button>
                )}
                <Button
                  className="bg-secondary hover:bg-primary"
                  onClick={handleMarkSold}
                >
                  <AiOutlineFileDone className="h-4 w-4" />
                  Mark Sold
                </Button>
              </>
            ) : (
              <>
                {propertyData?.campaignStatus === "PAUSED" ? (
                  <Button
                    className="bg-secondary hover:bg-primary"
                    onClick={handleResumeCampaign}
                  >
                    <FaPlay className="h-4 w-4" />
                    Resume Campaign
                  </Button>
                ) : (
                  propertyData?.campaignStatus === "ACTIVE" && (
                    <Button
                      className="bg-secondary hover:bg-primary"
                      onClick={handlePauseCampaign}
                    >
                      <FaBoxArchive className="h-4 w-4" />
                      Pause Campaign
                    </Button>
                  )
                )}
              </>
            )}
            <Button
              className="bg-secondary hover:bg-primary"
              onClick={handleListingDelete}
            >
              <FaTrash className="h-4 w-4" />
              Delete Listing
            </Button>
            <Button
              className="bg-secondary hover:bg-primary"
              onClick={handleSave}
            >
              <FaSave className="h-4 w-4" />
              Save
            </Button>
          </div>
          <ReconfirmOverlay
            isOpen={isOverlayOpen}
            onClose={closeOverlay}
            onConfirm={confirmAction}
            title={title}
            message={message}
            messageTwo={messageTwo}
            confirmText={confirmText}
          />
        </>
      ) : (
        <div className="flex flex-row space-x-4">
          {mlsApproved && (
            <Button
              className="bg-secondary hover:bg-primary"
              onClick={handleMLSSync}
              disabled={isSyncing}
            >
              <FaSync
                className={`h-4 w-4 ${isSyncing ? "animate-spin" : ""}`}
              />
              {isSyncing ? "Syncing..." : "Sync with MLS"}
            </Button>
          )}
          <Button
            className="bg-secondary hover:bg-primary"
            onClick={() => setIsEditing(true)}
          >
            <FaEdit className="mr-2 h-6 w-6 text-white" />
            Edit
          </Button>
        </div>
      )}
    </>
  );
};

export default TabButtons;
