import React from "react";
import StepItem from "./StepItem";

interface Step {
  label: string;
  isActive: boolean;
  isCompleted: boolean;
}

interface StepProgressBarProps {
  steps: Step[];
}

interface LineConnectorProps {
  isCompleted: boolean;
}

const LineConnector: React.FC<LineConnectorProps> = ({ isCompleted }) => (
  <div
    className={`w-full mx-4 h-0.5 ${
      isCompleted ? "bg-secondary" : "bg-stone-300"
    }`}
  ></div>
);

const StepProgressBar: React.FC<StepProgressBarProps> = ({ steps }) => {
  return (
    <nav
      className="flex items-center justify-center px-6 py-4 bg-white rounded-lg max-md:px-5 w-full"
      aria-label="Progress"
    >
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <StepItem label={step.label} isActive={step.isActive} />
          {index < steps.length - 1 && (
            <LineConnector isCompleted={step.isCompleted} />
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default StepProgressBar;
