import React from "react";
import { ChooseFrequencyProps, weekDays } from "./types";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../misc/Card";
import { Label } from "../misc/Label";
import { RadioGroup, RadioGroupItem } from "../misc/RadioGroup";
import { Popover, PopoverContent, PopoverTrigger } from "../misc/Popover";
import { Button } from "../misc/Button";
import { cn } from "../../utils/classMerger";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import CustomToast from "../misc/CustomToast";
import { toast } from "react-toastify";
import Checkbox from "../misc/Checkbox";
import CustomDatePicker from "../misc/CutomDatePicker";

const ChooseFrequency: React.FC<ChooseFrequencyProps> = ({
  setCurrentStep,
  frequency,
  setFrequency,
  selectedDays,
  setSelectedDays,
  date,
  setDate,
}) => {
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

  const handleBack = () => {
    setCurrentStep(1);
  };

  const nextHandler = () => {
    if (!isDisabled) {
      setCurrentStep(3);
    } else {
      toast.error(
        <CustomToast
          message="Please select a date and day(s) to proceed."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    }
  };

  // Refined logic to manage the isDisabled state based on form validation
  React.useEffect(() => {
    const isDateSelected = date instanceof Date && !isNaN(date.getTime());
    const isWeeklyWithDays =
      frequency !== "weekly" ||
      (frequency === "weekly" && selectedDays.length > 0);

    if (isDateSelected && isWeeklyWithDays) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [frequency, selectedDays, date]);

  const toggleDaySelection = (dayId: string) => {
    setSelectedDays(
      selectedDays.includes(dayId)
        ? selectedDays.filter((d) => d !== dayId)
        : [...selectedDays, dayId]
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Post Frequency</CardTitle>
        <CardDescription>
          Set your campaign frequency and start date
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Frequency Selection */}
        <div className="space-y-4">
          <Label className="text-primary">Frequency</Label>
          <RadioGroup
            defaultValue={frequency || "daily"}
            onValueChange={setFrequency}
            className="grid gap-3"
          >
            <Label
              htmlFor="daily"
              className={cn(
                "flex items-center space-x-3 space-y-0 rounded-md border p-3 cursor-pointer hover:bg-lightSecondary transition-colors",
                frequency === "daily" && "bg-secondary text-white"
              )}
            >
              <RadioGroupItem value="daily" id="daily" />
              <span className={frequency === "daily" ? "text-white" : ""}>
                Daily
              </span>
            </Label>
            <Label
              htmlFor="weekly"
              className={cn(
                "flex items-center space-x-3 space-y-0 rounded-md border p-3 cursor-pointer hover:bg-lightSecondary transition-colors",
                frequency === "weekly" && "bg-secondary text-white"
              )}
            >
              <RadioGroupItem value="weekly" id="weekly" />
              <span className={frequency === "weekly" ? "text-white" : ""}>
                Weekly
              </span>
            </Label>
          </RadioGroup>
        </div>

        {/* Weekly Day Selection */}
        {frequency === "weekly" && (
          <div className="space-y-4">
            <Label className="text-primary font-semibold">Select Days</Label>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
              {weekDays.map((day) => (
                <div
                  key={day.id}
                  className={cn(
                    "flex items-center p-3 rounded-md border cursor-pointer transition-colors",
                    selectedDays.includes(day.id)
                      ? "bg-secondary text-secondary-foreground"
                      : "bg-white hover:bg-gray-50"
                  )}
                  onClick={() => toggleDaySelection(day.id)}
                >
                  <Checkbox
                    id={day.id}
                    checked={selectedDays.includes(day.id)}
                    onChange={() => toggleDaySelection(day.id)}
                    className="mr-2"
                    variant="radix"
                  />
                  <span className="text-primary">{day.label}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Start Date Selection */}
        <div className="space-y-4">
          <Label className="text-primary">Start Date</Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4 text-secondary" />
                {date ? format(date, "PPP") : "Pick a date"}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <CustomDatePicker date={date} setDate={setDate} />
            </PopoverContent>
          </Popover>
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between">
          <Button variant="secondary" onClick={handleBack}>
            Back
          </Button>
          <Button onClick={nextHandler} disabled={isDisabled}>
            Next
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default ChooseFrequency;
