import { Auth, signOut } from "firebase/auth";
import { MouseEventHandler } from "react";
import { NavigateFunction } from "react-router-dom";

export const handleLogoutGenerator = (
  auth: Auth,
  navigate: NavigateFunction
) => {
  const handleLogout: MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };
  return handleLogout;
};
