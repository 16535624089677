import React, { useEffect, useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "../misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import NoItems from "../misc/NoItems";
import LoadingScreen from "../../screens/LoadingScreen";
import ListingDisplay from "./ListingDisplay";
import { AllListings } from "../../types/allListings";
import { useNavigate } from "react-router-dom";

interface UserListingsProps {
  mlsApproved: boolean | null;
}

const UserListings: React.FC<UserListingsProps> = ({ mlsApproved }) => {
  const navigate = useNavigate();

  const [listings, setListings] = useState<AllListings[]>([]);
  const [loading, setLoading] = useState(true);

  const hasFetched = useRef(false); // Ref to prevent duplicate fetches

  const fetchListings = useCallback(async () => {
    try {
      setLoading(true);
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      if (!backendUrl) {
        throw new Error("REACT_APP_BACKEND_URL is not defined.");
      }

      const response = await fetch(
        `${backendUrl}/${mlsApproved ? "realtor-listing" : "listing"}`,
        { headers }
      );

      console.log("response in UserListings:", response);

      if (!response.ok) {
        throw new Error("Failed to fetch listings. Please try again later.");
      }

      const responseBody = response.body;
      if (!responseBody) {
        throw new Error("Response body is null.");
      }

      const reader = responseBody.getReader();
      const decoder = new TextDecoder();
      let { value, done } = await reader.read();
      let buffer = "";
      const fetchedListings: AllListings[] = [];

      while (!done) {
        buffer += decoder.decode(value, { stream: true });
        let lines = buffer.split("\n");
        buffer = lines.pop() || "";

        for (let line of lines) {
          if (line.trim()) {
            try {
              const listing = JSON.parse(line);
              fetchedListings.push(listing);
            } catch (parseError) {
              console.error(
                "Error parsing listing:",
                parseError,
                "Line:",
                line
              );
            }
          }
        }

        const result = await reader.read();
        value = result.value;
        done = result.done;
      }

      // Process any remaining data
      if (buffer.trim()) {
        try {
          const listing = JSON.parse(buffer);
          fetchedListings.push(listing);
        } catch (parseError) {
          console.error(
            "Error parsing final listing:",
            parseError,
            "Buffer:",
            buffer
          );
        }
      }

      // Optionally remove duplicates based on unique identifier
      const uniqueListings = fetchedListings.reduce(
        (acc: AllListings[], current) => {
          const exists = acc.some((item) => item.id === current.id);
          if (!exists) acc.push(current);
          return acc;
        },
        []
      );

      setListings(uniqueListings);
    } catch (error) {
      toast.error(
        <CustomToast
          message="Failed to fetch listings. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
      console.error("Error fetching listings:", error);
    } finally {
      setLoading(false);
    }
  }, [mlsApproved]);

  useEffect(() => {
    if (hasFetched.current) return; // Prevent duplicate fetches
    hasFetched.current = true;

    fetchListings();
  }, [mlsApproved, fetchListings]);

  const createListingHandler = () => {
    navigate("/create-listing");
  };

  return (
    <div className="relative w-full h-full">
      {loading && <LoadingScreen />}
      {!loading && listings.length === 0 && (
        <NoItems
          title="No Listings Found"
          description="You have not created any listings yet."
          descriptionTwo="Click the button below to create a new listing."
          buttonText="Create Listing"
          clickHandler={createListingHandler}
        />
      )}
      {!loading && listings.length > 0 && (
        <ListingDisplay
          listings={listings}
          mlsApproved={mlsApproved || false}
          fetchListings={fetchListings}
        />
      )}
    </div>
  );
};

export default UserListings;
