import { Assets } from "../../types/asset-type";
import { CampaignProgress } from "../campaign/types";
import { CarouselApi } from "../listings/Carousel";
import { Users, BookOpen, Wrench, Shield, Zap, Leaf } from "lucide-react";
import capeCod from "../../assets/images/cape-cod.svg";
import { RiContractLine, RiHomeGearLine } from "react-icons/ri";
import { BiPurchaseTag } from "react-icons/bi";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { BsExclamationTriangle } from "react-icons/bs";
import { Dispatch, SetStateAction } from "react";

export interface Promotion {
  id: string;
  types: PromotionType[];
  color: string;
  campaignStatus?: string;
  campaignType?: string;
  generatedPosts?: number;
  publishedPosts?: number;
  scheduledPosts?: number;
  unapprovedPosts: number;
  contentRefresh: number;
  campaignId?: string;
  campaignProgress?: CampaignProgress;
  userNotes?: string;
}

export enum PromotionType {
  Mortage = "Mortage",
  FirstTimeHomeBuyer = "FirstTimeHomeBuyer",
  ClosingProcess = "ClosingProcess",
  RealtorAdvocacy = "RealtorAdvocacy",
  BuyerTips = "BuyerTips",
  BuyerEducation = "BuyerEducation",
  HomeMaintenance = "HomeMaintenance",
  HomeImprovement = "HomeImprovement",
  HomeSecurity = "HomeSecurity",
  MythBusting = "MythBusting",
  Sustainability = "Sustainability",
}

export interface PromotionProps {
  promotion: Promotion | null;
  setPromotion: (promotion: Promotion | null) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  assets: Assets[];
  setLoading: (loading: boolean) => void;
  fetchAssets: () => void;
  fetchPromotions: () => void;
  originalPromotion: Promotion | null;
}

export interface CampaignCardProps {
  promotion: Promotion;
}

export interface PromotionTabsComponentProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  promotion: Promotion | null;
  assets: Assets[];
  activeTab: "categories" | "assets";
  setActiveTab: (activeTab: "categories" | "assets") => void;
  carouselApi: CarouselApi | null;
  setLoading: (loading: boolean) => void;
  setPromotion: (promotion: Promotion | null) => void;
  fetchAssets: () => void;
  activePromotionType: PromotionType | undefined;
  setActivePromotionType: Dispatch<SetStateAction<PromotionType>>;
  carouselAssetIndexMap: { [id: string]: number };
  originalPromotion: Promotion | null;
  fetchPromotions: () => void;
}
export interface PromotionTabProps {
  isEditing: boolean;
  selectedCategories: PromotionType[];
  setSelectedCategories: (selectedCategories: PromotionType[]) => void;
  promotion: Promotion | null;
  setPromotion: (promotion: Promotion) => void;
}

export interface PromotionTabButtonProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  promotion: Promotion | null;
  setLoading: (loading: boolean) => void;
  selectedCategories: PromotionType[];
  setSelectedCategories: (selectedCategories: PromotionType[]) => void;
  setPromotion: (promotion: Promotion | null) => void;
  selectedMedia: Assets[];
  setSelectedMedia: (selectedMedia: Assets[]) => void;
  fetchAssets: () => void;
  originalPromotion: Promotion | null;
  fetchPromotions: () => void;
}

export interface PromotionAssetsTabProps {
  isEditing: boolean;
  assets: Assets[] | undefined;
  promotionTypes: PromotionType[];
  promotionId: string | undefined;
  selectedMedia: Assets[];
  setSelectedMedia: (selectedMedia: Assets[]) => void;
  carouselApi: CarouselApi | null;
  fetchAssets: () => void;
  activePromotionType: PromotionType | undefined;
  setActivePromotionType: Dispatch<SetStateAction<PromotionType>>;
  carouselAssetIndexMap: { [id: string]: number };
  value: string;
  setLoading: (loading: boolean) => void;
}

export const categories = [
  { id: "Mortage", label: "Mortage", icon: RiContractLine },
  { id: "FirstTimeHomeBuyer", label: "First Time Home Buyer", icon: Users },
  { id: "ClosingProcess", label: "Closing Process", icon: BiPurchaseTag },
  {
    id: "RealtorAdvocacy",
    label: "Realtor Advocacy",
    icon: MdOutlineRealEstateAgent,
  },
  { id: "BuyerTips", label: "Buyer Tips", icon: Zap },
  { id: "BuyerEducation", label: "Buyer Education", icon: BookOpen },
  { id: "HomeMaintenance", label: "Home Maintenance", icon: Wrench },
  { id: "HomeImprovement", label: "Home Improvement", icon: RiHomeGearLine },
  { id: "HomeSecurity", label: "Home Security", icon: Shield },
  { id: "MythBusting", label: "Myth Busting", icon: BsExclamationTriangle },
  { id: "Sustainability", label: "Sustainability", icon: Leaf },
];

export interface MediaThumbnailsProps {
  assets: Assets[] | undefined;
  currentPage: number;
  toggleSelectMedia: (asset: Assets) => void;
  selectedMedia: Assets[];
  isEditing: boolean;
  carouselApi: CarouselApi | null;
  carouselAssetIndexMap: { [id: string]: number };
}

export const itemsPerPage = 10;

export interface UserNotesCardProps {
  promotion: Promotion;
  setPromotion: (promotion: Promotion) => void;
}

export const samplePosts = [
  {
    label: "Mortage",
    img: capeCod,
    text: "Mortage are a great way to buy a home.",
  },
  {
    label: "First Time Home Buyer",
    img: capeCod,
    text: "First time home buyers are the best.",
  },
  {
    label: "Closing Process",
    img: capeCod,
    text: "The closing process is a long one.",
  },
  {
    label: "Realtor Advocacy",
    img: capeCod,
    text: "Realtors are the best.",
  },
  {
    label: "Buyer Tips",
    img: capeCod,
    text: "Buyer tips are important.",
  },
  {
    label: "Buyer Education",
    img: capeCod,
    text: "Buyer education is important.",
  },
  {
    label: "Home Maintenance",
    img: capeCod,
    text: "Home maintenance is important.",
  },
  {
    label: "Home Improvement",
    img: capeCod,
    text: "Home improvement is important.",
  },
  {
    label: "Home Security",
    img: capeCod,
    text: "Home security is important.",
  },
  {
    label: "Myth Busting",
    img: capeCod,
    text: "Myth busting is important.",
  },
  {
    label: "Sustainability",
    img: capeCod,
    text: "Sustainability is important.",
  },
];

export interface DeleteMediaProps {
  selectedMedia: Assets[];
  setLoading: (value: boolean) => void;
  promotionId?: string | undefined;
  fetchAssets: () => void;
  setSelectedMedia: (value: Assets[]) => void;
}
