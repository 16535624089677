import React from "react";
import { FaCheck } from "react-icons/fa";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import { cn } from "../../utils/classMerger";

interface CheckboxProps {
  variant?: "custom" | "radix";
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  id?: string;
  className?: string;
  width?: string;
  bg?: string;
  // Additional props as needed
}

const Checkbox: React.FC<CheckboxProps> = ({
  variant = "custom",
  label,
  checked,
  onChange,
  id,
  className,
  width,
  bg,
  ...props
}) => {
  if (variant === "custom") {
    // Custom checkbox implementation
    const handleCheckboxChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      event.stopPropagation(); // Prevent event from bubbling up
      onChange(!checked);
    };

    return (
      <div className="flex overflow-hidden flex-col justify-center self-stretch my-auto w-[185px]">
        <label
          htmlFor={id || "customCheckbox"}
          className={`flex overflow-hidden gap-2 justify-center items-center p-2 w-full bg-${
            bg ? bg : "white"
          } rounded-lg max-sm:justify-center max-sm:mx-auto cursor-pointer`}
        >
          <input
            type="checkbox"
            id={id || "customCheckbox"}
            checked={checked}
            onChange={handleCheckboxChange}
            className={`sr-only ${className}`}
          />
          <div className="flex justify-center items-center self-stretch my-auto w-6 min-h-[24px]">
            <div
              className={`flex justify-center items-center rounded-md border border-secondary border-solid h-[22px] w-[22px] bg-white`}
            >
              {checked && <FaCheck className="text-primary" />}
            </div>
          </div>
          {label && (
            <span className="flex-1 shrink self-stretch my-auto text-base text-primary basis-0">
              {label}
            </span>
          )}
        </label>
      </div>
    );
  } else if (variant === "radix") {
    // Radix UI checkbox implementation
    return (
      <div className="flex items-center">
        <CheckboxPrimitive.Root
          checked={checked}
          onCheckedChange={onChange}
          id={id}
          className={cn(
            "peer h-4 w-4 shrink-0 rounded-sm border border-primary shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
            className
          )}
          {...props}
        >
          <CheckboxPrimitive.Indicator
            className={cn("flex items-center justify-center text-current")}
          >
            <Check className="h-4 w-4 text-secondary" />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && (
          <label
            htmlFor={id}
            className="ml-2 text-base text-primary cursor-pointer"
          >
            {label}
          </label>
        )}
      </div>
    );
  }

  return null; // Handle any other cases or return null
};

export default Checkbox;
