import React, { useState, useEffect } from "react";
import { loadStripe, Appearance } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { auth } from "../../firebase/firebase-config";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import LoadingScreen from "../../screens/LoadingScreen";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

const appearance: Appearance = {
  theme: "stripe",
};

interface PaymentProps {
  setCurrentStep: (step: number) => void;
  role: string | null;
}

const Payment: React.FC<PaymentProps> = ({ setCurrentStep, role }) => {
  const [clientSecret, setClientSecret] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      setLoading(true);
      if (role === "TEAM_USER") {
        setCurrentStep(3);
        return;
      }
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error("User not authenticated");
        const token = await getIdToken(currentUser, true);

        const response = await axios.post(
          `${backendUrl}/stripe/newAccount`,
          { email: auth.currentUser?.email },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        console.log("Payment intent created:", response.data);
        setClientSecret(response.data.clientSecret);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error creating payment intent:", error);
        toast.error(
          <CustomToast
            message="Error creating payment intent. Please try again."
            type="error"
          />,
          {
            autoClose: 3000,
          }
        );
      } finally {
        setLoading(false);
      }
    };
    fetchPaymentIntent();
  }, [role, setCurrentStep]);

  if (loading) return <LoadingScreen />;

  if (!clientSecret) {
    toast.error(
      <CustomToast
        message="Error creating payment intent. Please try again."
        type="error"
      />,
      {
        autoClose: 3000,
      }
    );
    setCurrentStep(3);
    return null;
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
      <PaymentForm setCurrentStep={setCurrentStep} />
    </Elements>
  );
};

export default Payment;
