import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import LoadingScreen from "../../screens/LoadingScreen";
import { getAuthHeader } from "../../utils/authHeader";
import { UserListing } from "../../types/userListing";
import { Images } from "../../types/listingImages";
import { ListingVideos } from "../../types/listingVideos";
import { CarouselApi } from "./Carousel";
import { deCompileAddress } from "../../utils/addressCompiler";
import { Assets } from "../../types/asset-type";
import axios from "axios";
import Slideshow from "./slideshow/Slideshow";
import ListingHeader from "./listing/ListingHeader";
import DataLoadingError from "../misc/DataLoadingError";
import MediaCarousel from "./listing/MediaCarousel";
import {
  CombinedAsset,
  CombinedImage,
  CombinedMedia,
  CombinedVideo,
} from "./listing/types";
import TabsComponent from "./listing/Tabs";
import CampaignCard from "./listing/CampaignCard";
import ImageTagCard from "./listing/ImageTagCard";
import SlideshowGeneratorCard from "./listing/SlideshowGeneratorCard";
import { AllListings } from "../../types/allListings";
import { useSocket } from "../../context/SocketContext";
import { CampaignProgress } from "../campaign/types";
import UserNotesCard from "./listing/UserNotesCard";

interface LocationState {
  listingId: string;
}

interface ListingViewProps {
  mlsApproved: boolean | null;
}

const ListingView: React.FC<ListingViewProps> = ({ mlsApproved }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState | undefined;
  const [isGenerating, setIsGenerating] = useState(false);
  const [propertyData, setPropertyData] = useState<UserListing | null>(null);
  const [originalPropertyData, setOriginalPropertyData] =
    useState<UserListing | null>(null);
  const [allListing, setAllListing] = useState<AllListings | null>(null);
  const [images, setImages] = useState<Images[]>([]);
  const [videos, setVideos] = useState<ListingVideos[]>([]);
  const [assets, setAssets] = useState<Assets[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
  const [isSlideshowOpen, setIsSlideshowOpen] = useState(false);

  const { socket } = useSocket();
  const subscribedCampaignsRef = useRef<Set<string>>(new Set());

  const openSlideshow = () => setIsSlideshowOpen(true);
  const closeSlideshow = () => setIsSlideshowOpen(false);

  const fetchListing = useCallback(async () => {
    console.log("Fetching listing");
    console.log("mlsApproved", mlsApproved);
    try {
      if (state) {
        // Clear images and videos before fetching
        setImages([]);
        setVideos([]);

        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const response = await fetch(
          `${backendUrl}/${mlsApproved ? "realtor-listing" : "listing"}/${
            state.listingId
          }`,
          {
            headers,
            method: "GET",
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data) {
          const updatedData: UserListing = {
            ...data,
            footer: {
              ...data.footer,
              agencyAddress: deCompileAddress(data.footer.agencyAddress),
            },
          };
          setPropertyData(updatedData);
          setOriginalPropertyData(updatedData);

          const allListingData: AllListings = {
            id: data._id,
            formattedAddress: data.formattedAddress,
            status: data.status,
            latitude: data.latitude,
            longitude: data.longitude,
            primaryImage: data.primaryImage,
            campaignStatus: data.campaignStatus,
            campaignType: data.campaignType,
            generatedPosts: data.generatedPosts,
            publishedPosts: data.publishedPosts,
            scheduledPosts: data.scheduledPosts,
            unapprovedPosts: data.unapprovedPosts,
            contentRefresh: data.contentRefresh,
            progress: data.progress,
            color: data.color,
            campaignId: data.campaignId,
            campaignProgress: data.campaignProgress,
            imageCount: data.imageCount,
          };

          setAllListing(allListingData);

          setImages(data.images || []);
          setVideos(data.videos || []);
        }
      } else {
        toast.error(
          <CustomToast message="Listing Retrieval Failed" type="error" />,
          {
            autoClose: 3000,
          }
        );

        setTimeout(() => {
          navigate("/listings");
        }, 3000);
      }
    } catch (error) {
      console.error("Error fetching listing:", error);
      toast.error(
        <CustomToast message="Failed to load listing." type="error" />,
        {
          autoClose: 3000,
        }
      );
    }
  }, [state, navigate, mlsApproved]);

  const fetchAssets = useCallback(async () => {
    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await axios.get(
        `${backendUrl}/assets/listing/${state?.listingId}`,
        {
          headers,
        }
      );

      if (response.data) {
        if (Array.isArray(response.data)) {
          setAssets(response.data);
        } else if (
          response.data.assets &&
          Array.isArray(response.data.assets)
        ) {
          setAssets(response.data.assets);
        } else {
          console.error("Assets data is not an array", response.data);
          setAssets([]);
        }
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          // Corrected to access status via error.response
          console.log("No assets found for listing");
        } else {
          console.error("Error fetching assets:", error);
          toast.error(
            <CustomToast message="Failed to load assets." type="error" />,
            {
              autoClose: 3000,
            }
          );
        }
      } else {
        console.log("Error fetching assets:", error);
        toast.error(
          <CustomToast message="Failed to load assets." type="error" />,
          {
            autoClose: 3000,
          }
        );
      }
    }
  }, [state]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchListing(), fetchAssets()]);
      } catch (error) {
        toast.error(
          <CustomToast message="Failed to load data." type="error" />,
          {
            autoClose: 3000,
          }
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [fetchListing, fetchAssets]);

  useEffect(() => {
    if (!socket || !allListing || !allListing.campaignId) return;

    const campaignId = allListing.campaignId;
    const currentSubscribedCampaigns = subscribedCampaignsRef.current;
    let isSubscribed = false; // Flag to track subscription in this effect

    if (
      allListing.campaignStatus === "PROCESSING" &&
      !currentSubscribedCampaigns.has(campaignId)
    ) {
      socket.emit("subscribeToCampaign", campaignId);
      currentSubscribedCampaigns.add(campaignId);
      isSubscribed = true; // Mark subscription
    }

    const handleProgressUpdate = (data: {
      entityId: string;
      entityType: "campaign";
      progress: CampaignProgress;
    }) => {
      const { entityId, entityType, progress } = data;

      if (entityType === "campaign" && entityId === campaignId) {
        setAllListing((prevAllListing) => {
          if (!prevAllListing) return prevAllListing;

          const updatedCampaignStatus =
            progress.currentStep === "Completed"
              ? "ACTIVE"
              : prevAllListing.campaignStatus;

          return {
            ...prevAllListing,
            campaignProgress: progress,
            campaignStatus: updatedCampaignStatus,
          };
        });

        if (progress.currentStep === "Completed") {
          fetchListing();
        }
      }
    };

    socket.on("progressUpdate", handleProgressUpdate);

    return () => {
      socket.off("progressUpdate", handleProgressUpdate);

      // Only unsubscribe if this effect added the subscription
      if (isSubscribed && currentSubscribedCampaigns.has(campaignId)) {
        socket.emit("unsubscribeFromCampaign", campaignId);
        currentSubscribedCampaigns.delete(campaignId);
      }
    };
  }, [socket, allListing, fetchListing]);

  const combinedMedia: CombinedMedia[] = [
    ...images.map((image) => ({ ...image, type: "image" } as CombinedImage)),
    ...videos.map((video) => ({ ...video, type: "video" } as CombinedVideo)),
    ...assets.map((asset) => {
      let mediaType: "image" | "video" = "image";
      if (asset.assetType === "Video") {
        mediaType = "video";
      } else if (asset.assetType === "Image") {
        mediaType = "image";
      } else {
        mediaType = "image";
      }
      return { ...asset, type: mediaType } as CombinedAsset;
    }),
  ];

  if (isLoading) return <LoadingScreen />;

  if (!propertyData) {
    setTimeout(() => {
      navigate("/listings");
    }, 3000);
    return (
      <DataLoadingError
        heading="Data Loading Error"
        text="The requested property data could not be loaded. You will be
              redirected back in a few seconds."
      />
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-row gap-8">
        {/* Left Column */}
        <div className="w-full lg:flex-1 max-w-[70%]">
          <ListingHeader
            formattedAddress={propertyData.formattedAddress}
            propertyType={propertyData.propertyType}
            status={propertyData.status}
          />

          <MediaCarousel
            setCarouselApi={setCarouselApi}
            combinedMedia={combinedMedia}
            latitude={propertyData.latitude}
            longitude={propertyData.longitude}
            address={propertyData.formattedAddress}
          />

          <TabsComponent
            images={images}
            videos={videos}
            assets={assets}
            setIsLoading={setIsLoading}
            carouselApi={carouselApi}
            propertyData={propertyData}
            fetchListing={fetchListing}
            fetchAssets={fetchAssets}
            setPropertyData={setPropertyData}
            originalPropertyData={originalPropertyData}
            setOriginalPropertyData={setOriginalPropertyData}
            combinedMedia={combinedMedia}
            mlsApproved={mlsApproved}
          />
        </div>
        {/* Right Column */}
        <div className="w-full lg:w-1/3 max-w-[30%]">
          {/* Campaign Card */}
          {allListing !== null && (
            <CampaignCard
              allListing={allListing}
              mlsApproved={mlsApproved || false}
            />
          )}

          {/* Image Tag Card */}
          <ImageTagCard
            combinedMedia={combinedMedia}
            carouselApi={carouselApi}
            propertyData={propertyData}
            fetchListing={fetchListing}
            setImages={setImages}
            images={images}
            mlsApproved={mlsApproved || false}
          />

          {/* Slideshow Generator */}
          <SlideshowGeneratorCard
            openSlideshow={openSlideshow}
            isGenerating={isGenerating}
          />

          {/* Slideshow Component */}
          <Slideshow
            listingId={propertyData._id}
            images={images}
            isOpen={isSlideshowOpen}
            onClose={closeSlideshow}
            videos={videos}
            setVideos={setVideos}
            formattedAddress={propertyData.formattedAddress}
            isGenerating={isGenerating}
            setIsGenerating={setIsGenerating}
          />

          {/* User Notes Card */}
          <UserNotesCard
            propertyData={propertyData}
            setPropertyData={setPropertyData}
            mlsApproved={mlsApproved || false}
          />
        </div>
      </div>
    </div>
  );
};

export default ListingView;
