import React from "react";
import { Card, CardContent, CardTitle } from "../../misc/Card";
import { CampaignCardListingProps, CampaignCardProps } from "./types";
import CampaignStatusCard from "../../campaign/CampaignStatusCard";
import { Button } from "../../misc/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import { useDispatch } from "react-redux";
import { setSelectedListingAddress } from "../../../store/calendarSlice";
import { OptionType } from "../../../types/option-type";
import { useLocation } from "react-router-dom";

const CampaignCard: React.FC<CampaignCardProps> = ({
  allListing,
  mlsApproved,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewCampaign = () => {
    if (allListing?.formattedAddress) {
      const address = allListing.formattedAddress.split(",")[0].trim();

      const option: OptionType[] = [
        {
          label: address.charAt(0).toUpperCase() + address.slice(1),
          value: address,
        },
      ];
      dispatch(setSelectedListingAddress(option));
      navigate("/");
    }
  };

  const handleAddPost = () => {
    if (allListing?.formattedAddress) {
      const address = allListing.formattedAddress.split(",")[0].trim();

      const option: OptionType[] = [
        {
          label: address.charAt(0).toUpperCase() + address.slice(1),
          value: address,
        },
      ];
      dispatch(setSelectedListingAddress(option));
      navigate("/", {
        state: {
          addPost: true,
        },
      });
    }
  };

  const CampaignCardListing: CampaignCardListingProps = {
    campaignProgress: allListing?.campaignProgress,
    campaignStatus: allListing?.campaignStatus,
    campaignType: allListing?.campaignType,
    generatedPosts: allListing?.generatedPosts,
    publishedPosts: allListing?.publishedPosts,
    scheduledPosts: allListing?.scheduledPosts,
    unapprovedPosts: allListing?.unapprovedPosts,
    contentRefresh: allListing?.contentRefresh,
  };

  return (
    <Card>
      <CardContent className="pt-6">
        <CardTitle className="mb-4">Campaign</CardTitle>

        <CampaignStatusCard listing={CampaignCardListing} />
        {allListing && !allListing.campaignStatus ? (
          <button
            className="w-full bg-secondary text-white hover:bg-primary transition-colors duration-200 py-2 rounded mt-4"
            onClick={() => {
              if (allListing.imageCount <= 0) {
                toast.error(
                  <CustomToast
                    message="Please upload images before creating a campaign."
                    type="error"
                  />,
                  {
                    autoClose: 3000,
                  }
                );
              } else {
                navigate("/create-campaign", {
                  state: {
                    listingId: allListing.id,
                    listingType: mlsApproved ? "MLSListing" : "Listing",
                    from: location.pathname,
                  },
                });
              }
            }}
          >
            Create Campaign
          </button>
        ) : null}
        {allListing &&
        allListing.campaignStatus &&
        allListing.campaignStatus === "ACTIVE" ? (
          <>
            <Button
              className="mt-4 w-full font-semibold"
              onClick={handleViewCampaign}
              variant="secondary"
            >
              View Campaign Schedule
            </Button>
            <Button
              className="mt-4 w-full font-semibold"
              onClick={handleAddPost}
            >
              Add Post
            </Button>
          </>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default CampaignCard;
