/**
 * This code was generated by Builder.io.
 */
import React from "react";

const VerificationMessage: React.FC = () => {
  return (
    <>
      <div className="flex flex-col mt-11 max-w-full text-2xl font-bold text-center text-black w-[425px] max-md:mt-10">
        <div className="flex gap-3.5 justify-between items-center min-h-[98px]">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ace964e82052533cf689321095e61cecfd0ef4499bc50d0e7602fad53218cdb?placeholderIfAbsent=true&apiKey=dbe43292bfea4986bc9beb1eef1ca547"
            alt=""
            className="object-contain shrink-0 self-stretch my-auto aspect-[1.54] w-[43px]"
          />
          <h1 className="self-stretch my-auto w-[369px]">
            Email Verification Required
          </h1>
        </div>
      </div>
      <p className="self-stretch mt-11 text-2xl font-medium text-center text-black max-md:mt-10 max-md:max-w-full">
        Almost there! We need you to verify your email before signing in.
      </p>
    </>
  );
};

export default VerificationMessage;
