import * as React from "react";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  leftBorderColor?: string;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className = "", leftBorderColor, ...props }, ref) => (
    <div
      ref={ref}
      className={`rounded-lg border border-[#afb6c4] bg-card text-card-foreground shadow-sm ${className}`}
      style={
        leftBorderColor
          ? { borderLeftColor: leftBorderColor, borderLeftWidth: "4px" }
          : {}
      }
      {...props}
    />
  )
);
Card.displayName = "Card";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className = "", ...props }, ref) => (
  <div
    ref={ref}
    className={`flex flex-col justify-between ${className}`}
    {...props}
  />
));
CardContent.displayName = "CardContent";

export { Card, CardContent };
