import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  signInWithRedirect,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  AuthError,
} from "firebase/auth";
import { auth, googleProvider, facebookProvider } from "./firebase-config";

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    return result.user;
  } catch (error) {
    handleError(error);
  }
};

export const signInWithFacebook = async (useRedirect: boolean = false) => {
  try {
    if (useRedirect) {
      await signInWithRedirect(auth, facebookProvider);
    } else {
      const result = await signInWithPopup(auth, facebookProvider);
      return result.user;
    }
  } catch (error) {
    handleError(error);
  }
};

export const signUpWithEmail = async (email: string, password: string) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    return result.user;
  } catch (error) {
    handleError(error);
  }
};

export const signInWithEmail = async (
  email: string,
  password: string,
  rememberMe: boolean
) => {
  try {
    await setPersistence(
      auth,
      rememberMe ? browserLocalPersistence : browserSessionPersistence
    );
    const result = await signInWithEmailAndPassword(auth, email, password);
    return result.user;
  } catch (error) {
    handleError(error);
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    handleError(error);
  }
};

const getFirebaseErrorMessage = (error: AuthError): string => {
  switch (error.code) {
    case "auth/email-already-in-use":
      return "This email is already registered.";
    case "auth/invalid-email":
      return "The email address is not valid.";
    case "auth/user-not-found":
      return "No user found with this email.";
    case "auth/wrong-password":
      return "Incorrect password. Please try again.";
    case "auth/weak-password":
      return "The password is too weak. Please use a stronger password.";
    case "auth/popup-closed-by-user":
      return "Authentication was canceled.";
    default:
      return "An unexpected error occurred. Please try again later.";
  }
};

const handleError = (error: unknown): never => {
  if (isAuthError(error)) {
    console.error(`${error.code}: ${error.message}`);
    throw new Error(getFirebaseErrorMessage(error));
  } else {
    console.error("Unexpected Error:", error);
    throw new Error("An unexpected error occurred.");
  }
};

const isAuthError = (error: unknown): error is AuthError => {
  return (
    typeof error === "object" &&
    error !== null &&
    "code" in error &&
    "message" in error
  );
};
