import React from "react";
import { Button } from "../misc/Button";
import { FaTrash } from "react-icons/fa";
import { DeleteMediaProps } from "./types";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";

export const DeleteMediaButton: React.FC<DeleteMediaProps> = ({
  selectedMedia,
  setSelectedMedia,
  setLoading,
  fetchAssets,
}) => {
  const handleDeleteImages = async () => {
    setLoading(true);
    const assetIds = selectedMedia.map((media) => media.id);

    if (assetIds.length <= 0) {
      toast.error(
        <CustomToast
          message="You need to select atleast one image to delete."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );

      setLoading(false);
      return;
    }

    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.delete(`${backendUrl}/assets/`, {
        headers,
        data: { ids: assetIds },
      });

      if (!response.data) {
        throw new Error("Response data is null.");
      }

      if (response.status === 200) {
        toast.success(
          <CustomToast message="Images deleted successfully." type="success" />,
          {
            autoClose: 3000,
          }
        );
        setSelectedMedia([]);

        fetchAssets();
        setLoading(false);
      } else {
        throw new Error("Failed to delete images.");
      }
    } catch (error: unknown) {
      console.error("Failed to delete images:", error);
      setLoading(false);
      toast.error(
        <CustomToast
          message="Failed to delete images. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <span className="mr-2 text-primary">{selectedMedia.length} Selected</span>
      {selectedMedia.length > 0 ? (
        <Button
          className="bg-secondary hover:bg-primary"
          onClick={handleDeleteImages}
        >
          <FaTrash className="h-4 w-4" />
          Delete Images
        </Button>
      ) : null}
    </div>
  );
};
