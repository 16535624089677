import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../misc/Card";
import { Badge } from "../calendar/Badge";
import { FaClock, FaPencil } from "react-icons/fa6";
import { Button } from "../misc/Button";
import { ImageIcon, RefreshCw } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../misc/Tabs";
import { ScrollArea } from "../calendar/ScrollArea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../misc/Dialog";

const Post: React.FC = () => {
  const navigate = useNavigate();

  const [expandedContent, setExpandedContent] = useState<boolean>(false);
  const [isMediaPickerOpen, setIsMediaPickerOpen] = useState<boolean>(false);

  const handleBack = () => {
    navigate(-1);
  };

  const handleEditContent = () => {};

  const handleRegenerateContent = () => {};

  const handleEditMedia = () => {};

  return (
    <div className="p-4">
      {/* Back Button */}
      <button
        onClick={handleBack}
        className="flex items-center mb-4 px-4 py-2 bg-primary text-white rounded hover:bg-secondary transition-colors duration-300"
        aria-label="Go Back"
      >
        <FaArrowLeft className="mr-2" />
        Back
      </button>

      {/* Post Content */}
      <Card className="max-w-3xl mx-auto shadow-lg">
        <CardHeader className="space-y-2">
          <div className="flex items-center justify-between">
            <Badge variant="outline" className="flex gap-2 text-sm">
              <FaClock className="w-4 h-4" />
              {/* {format(post_.scheduledFor, 'PPP p')} */}
            </Badge>
            <Badge className={`text-white`}>
              {/* {post_.postType.replace('-', ' ').toUpperCase()} */}
            </Badge>
          </div>
          <CardTitle className="text-2xl font-bold">
            {/* {post_.contentType} */}
          </CardTitle>
          <CardDescription>Preview your social media post</CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Post Content</h3>
              <div className="space-x-2">
                <Button variant="outline" size="sm" onClick={handleEditContent}>
                  <FaPencil className="w-4 h-4 mr-2" />
                  Edit
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleRegenerateContent}
                >
                  <RefreshCw className="w-4 h-4 mr-2" />
                  Regenerate
                </Button>
                <Button variant="outline" size="sm" onClick={handleEditMedia}>
                  <ImageIcon className="w-4 h-4 mr-2" />
                  Edit Media
                </Button>
              </div>
            </div>
            <p
              className={`text-muted-foreground ${
                expandedContent ? "" : "line-clamp-3"
              }`}
            >
              {/* {post_.content} */}
            </p>
            {200 > 150 && (
              <Button
                variant="link"
                onClick={() => setExpandedContent(!expandedContent)}
              >
                {expandedContent ? "Show less" : "Read more"}
              </Button>
            )}
          </div>
          <Tabs defaultValue="previous" className="w-full">
            <TabsList className="grid w-full grid-cols-1">
              <TabsTrigger value="previous">
                Previous Content Versions
              </TabsTrigger>
            </TabsList>
            <TabsContent value="previous" className="mt-4">
              <ScrollArea className="h-[200px] rounded-md border p-4">
                {/* {post_.previousContent.map((content, index) => (
                <div key={index} className="mb-4 last:mb-0">
                  <h4 className="text-sm font-semibold mb-1">Version {post_.previousContent.length - index}</h4>
                  <p className="text-sm text-muted-foreground">{content}</p>
                </div>
              ))} */}
              </ScrollArea>
            </TabsContent>
          </Tabs>
        </CardContent>

        <Dialog open={isMediaPickerOpen} onOpenChange={setIsMediaPickerOpen}>
          <DialogContent className="max-w-4xl">
            <DialogHeader>
              <DialogTitle>Select Media</DialogTitle>
            </DialogHeader>
            <ScrollArea className="h-[400px] mt-4">
              <div className="grid grid-cols-4 gap-4 p-4">
                {/* {placeholderImages.map((media, index) => (
                <div
                  key={index}
                  className="relative aspect-square overflow-hidden rounded-lg cursor-pointer hover:opacity-80 transition-opacity"
                  onClick={() => handleSelectMedia(media)}
                >
                  <Image
                    src={media.url}
                    alt={`Selectable media ${index + 1}`}
                    fill
                    className="object-cover"
                  />
                </div>
              ))} */}
              </div>
            </ScrollArea>
          </DialogContent>
        </Dialog>
      </Card>
    </div>
  );
};

export default Post;
