import React from "react";
import { FaTrash } from "react-icons/fa";

interface User {
  firstName: string;
  lastName: string;
  email: string;
}

interface AddedValue {
  email: string;
  error: string | null;
}

interface TeamMemberProps {
  users: User[];
  setUsers: (users: User[]) => void;
  addedValue?: AddedValue[];
  added?: boolean;
}

export const TeamMembersList: React.FC<TeamMemberProps> = ({
  users,
  setUsers,
  addedValue = [],
  added,
}) => {
  const handleDelete = (index: number) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    setUsers(newUsers);
  };

  const getAddedValue = (email: string) =>
    addedValue.find((value) => value.email === email);

  return (
    <ul className="flex flex-col py-1 mt-8 max-w-full w-[695px] overflow-y-auto max-h-[250px]">
      {users.map((user, index) => {
        const value = getAddedValue(user.email);

        const errorMessage = value?.error
          ? `Error: ${value.error}. Please contact support to add this email (support@ispeak.ai).`
          : null;

        const successMessage =
          value && !value.error ? "Email added successfully." : null;

        return (
          <li key={index} className="flex flex-col w-full">
            <div className="grid grid-cols-3 gap-4 items-center w-full text-sm font-semibold whitespace-nowrap text-primary">
              <div className="flex flex-col">
                <label className="block text-xs text-gray-500">Name:</label>
                <span>
                  {user.firstName} {user.lastName}
                </span>
              </div>
              <div className="flex flex-col">
                <label className="block text-xs text-gray-500">Email:</label>
                <span>{user.email}</span>
              </div>
              {!added && (
                <FaTrash
                  onClick={() => handleDelete(index)}
                  className="cursor-pointer text-secondary justify-self-end"
                />
              )}
            </div>

            {errorMessage && (
              <div className="text-red-500 text-xs mt-1">{errorMessage}</div>
            )}

            {successMessage && (
              <div className="text-green-500 text-xs mt-1">
                {successMessage}
              </div>
            )}

            <div className="mt-1.5">
              <hr className="border-t border-slate-100" />
            </div>
          </li>
        );
      })}
    </ul>
  );
};
