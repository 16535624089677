import React, { useEffect, useState } from "react";
import { CombinedImage, CombinedMedia, ImageTagCardProps } from "./types";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import { Card, CardContent, CardTitle } from "../../misc/Card";
import { IoMdPricetag } from "react-icons/io";
import { Button } from "../../misc/Button";
import { FaEdit } from "react-icons/fa";
import InputField from "../../misc/InputField";
import { Badge } from "../../calendar/Badge";

const ImageTagCard: React.FC<ImageTagCardProps> = ({
  combinedMedia,
  carouselApi,
  propertyData,
  fetchListing,
  images,
  setImages,
  mlsApproved,
}) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [tagEditing, setTagEditing] = useState(false);

  useEffect(() => {
    if (carouselApi) {
      const onSelect = () => {
        setCurrentMediaIndex(carouselApi.selectedScrollSnap());
      };

      carouselApi.on("select", onSelect);

      // Set initial index
      setCurrentMediaIndex(carouselApi.selectedScrollSnap());

      return () => {
        carouselApi.off("select", onSelect);
      };
    }
  }, [carouselApi]);

  const currentMedia = combinedMedia[currentMediaIndex];

  function isCombinedImage(media: CombinedMedia): media is CombinedImage {
    return media.type === "image" && "tag" in media;
  }

  const handleEditTag = async (imageId: string, newTag: string) => {
    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const url = mlsApproved
        ? `${backendUrl}/realtor-listing`
        : `${backendUrl}/listing/image-tag`;

      const response = await axios.patch(
        `${url}`,
        {
          listingId: propertyData?._id,
          image: { id: imageId, tag: newTag },
        },
        { headers }
      );

      if (response.status === 200) {
        toast.success(
          <CustomToast message="Tag updated successfully." type="success" />,
          { autoClose: 3000 }
        );
        setTagEditing(false);
        // Optionally refresh data
        fetchListing();
      } else {
        toast.error(
          <CustomToast message="Failed to update tag." type="error" />,
          { autoClose: 3000 }
        );
      }
    } catch (error) {
      console.error("Error updating tag:", error);
      toast.error(
        <CustomToast
          message="An error occurred while updating the tag."
          type="error"
        />,
        { autoClose: 3000 }
      );
    }
  };

  return (
    <>
      {currentMedia && isCombinedImage(currentMedia) && (
        <Card className="mt-4">
          <CardContent className="pt-6">
            <div className="flex flex-row justify-between items-center mb-2">
              <div className="flex flex-row justify-center items-center mb-4">
                <IoMdPricetag className="text-secondary w-6 h-6 mr-1" />
                <CardTitle>Image Tag</CardTitle>
              </div>
              {!tagEditing && (
                <Button
                  className="bg-secondary hover:bg-primary"
                  onClick={() => setTagEditing(true)}
                >
                  <FaEdit className="mr-2 h-6 w-6 text-white" />
                  Edit Tag
                </Button>
              )}
            </div>
            <div className="mt-4 text-center flex flex-row justify-center items-center">
              {tagEditing ? (
                <InputField
                  label="Tag"
                  placeholder="Tag"
                  value={currentMedia.tag}
                  onChange={(e) => {
                    const { value } = e.target;
                    setImages(
                      images.map((img, index) =>
                        index === currentMediaIndex
                          ? { ...img, tag: value }
                          : img
                      )
                    );
                  }}
                  type="text"
                  className="text-left"
                />
              ) : (
                <div className="mt-6 flex flex-row">
                  <p className="mr-2 font-semibold text-primary">Tag:</p>
                  <Badge variant="secondary">{currentMedia.tag}</Badge>
                </div>
              )}
            </div>
            {tagEditing && (
              <div className="flex justify-center mt-4">
                <Button
                  className="bg-secondary hover:bg-primary"
                  onClick={() => {
                    if (isCombinedImage(currentMedia)) {
                      handleEditTag(currentMedia.id, currentMedia.tag);
                    } else {
                      toast.error(
                        <CustomToast
                          message="Invalid media type."
                          type="error"
                        />,
                        { autoClose: 3000 }
                      );
                    }
                  }}
                >
                  Save Tag
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default ImageTagCard;
