import React, { useRef, useEffect } from "react";
import { OverlaySelectorProps } from "./types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./Tooltip";
import { FaCheck } from "react-icons/fa6";
import { cn } from "../../../utils/classMerger";

const OverlaySelector: React.FC<OverlaySelectorProps> = ({
  overlays,
  selectedOverlay,
  onSelectOverlay,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ left: 0, behavior: "smooth" });
    }
  }, [selectedOverlay]);
  console.log("OverlaySelectorProps", overlays);
  return (
    <div className="space-y-4">
      <h4 className="text-sm font-medium">Choose Overlay</h4>
      <div
        ref={containerRef}
        className="flex flex-row gap-4 overflow-x-auto overflow-y-hidden scroll-smooth"
      >
        {overlays.length > 0 ? (
          overlays.map((overlay) => (
            <TooltipProvider key={overlay.id}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div
                    className={cn(
                      "group relative cursor-pointer overflow-hidden rounded-lg w-64 h-36 flex-shrink-0 bg-black"
                    )}
                    onClick={() => onSelectOverlay(overlay.id)}
                  >
                    <img
                      src={overlay.url}
                      alt={overlay.name}
                      className="object-contain w-full h-full"
                    />
                    {selectedOverlay === overlay.id && (
                      <div className="absolute inset-0 bg-secondary/20 flex items-center justify-center">
                        <FaCheck className="h-6 w-6 text-white" />
                      </div>
                    )}
                  </div>
                </TooltipTrigger>
                <TooltipContent>{overlay.name}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))
        ) : (
          <p className="text-neutral-500 col-span-full text-center">
            No overlays available. Create a new one below.
          </p>
        )}
      </div>
    </div>
  );
};

export default OverlaySelector;
