import React from "react";
import { Button } from "./Button";
import { LoaderCircle } from "lucide-react";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "./CustomToast";

const ConnectSocialAccountsButton = () => {
  const [connectLoading, setConnectLoading] = React.useState(false);

  const handleConnectButtonClick = async () => {
    console.log("handleConnectButtonClick called");

    setConnectLoading(true);

    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await axios.post(
        `${backendUrl}/scheduler/link-social-url`,
        {
          redirect: `${process.env.REACT_APP_FRONTEND_URL}/create-campaign`,
        },
        {
          headers,
        }
      );

      console.log("Social Connect URL Response:", response);

      if (response.status === 200) {
        // Open the third-party URL in the same tab
        window.location.href = response.data;
      } else {
        throw new Error(
          "Failed to fetch social connect URL. Please try again or go to settings to connect your social accounts."
        );
      }
    } catch (error: unknown) {
      console.error("Fetch Social Connect URL Error:", error);

      let errorMessage =
        "Error fetching social connect URL. Please try again or go to settings to connect your social accounts.";

      if (axios.isAxiosError(error) && error.response) {
        errorMessage += ` Server responded with status ${error.response.status}.`;
      }

      toast.error(<CustomToast message={errorMessage} type="error" />, {
        autoClose: 3000,
      });
    } finally {
      setConnectLoading(false);
    }
  };

  return (
    <div className="mt-6 flex justify-center">
      <Button
        onClick={handleConnectButtonClick}
        disabled={connectLoading}
        className="flex items-center justify-center"
      >
        {connectLoading ? (
          <>
            <LoaderCircle className="animate-spin h-5 w-5 mr-2" />
            Connecting...
          </>
        ) : (
          "Connect Social Accounts"
        )}
      </Button>
    </div>
  );
};

export default ConnectSocialAccountsButton;
