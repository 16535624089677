import React, { useState } from "react";
import { Tabs, TabsList, TabsTrigger } from "../misc/Tabs";
import PromotionTab from "./PromotionTab";
import { PromotionTabsComponentProps, PromotionType } from "./types";
import TabButtons from "./TabButtons";
import PromotionAssetsTab from "./PromotionAssetsTab";
import { Assets } from "../../types/asset-type";
import { isPromotionType } from "./util";

const TabsComponent: React.FC<PromotionTabsComponentProps> = ({
  isEditing,
  setIsEditing,
  promotion,
  assets,
  activeTab,
  setActiveTab,
  activePromotionType,
  setActivePromotionType,
  carouselApi,
  carouselAssetIndexMap,
  setLoading,
  setPromotion,
  fetchAssets,
  originalPromotion,
  fetchPromotions,
}) => {
  // State to manage selected categories (assuming it's used within PromotionTab)
  const [selectedCategories, setSelectedCategories] = useState<PromotionType[]>(
    promotion?.types || []
  );

  // State to manage selected media (assuming it's used within PromotionAssetsTab)
  const [selectedMedia, setSelectedMedia] = useState<Assets[]>([]);

  const selectedAssetCount = assets.filter(
    (asset) =>
      isPromotionType(asset.assetType) &&
      selectedCategories.includes(asset.assetType)
  ).length;

  console.log("Selected Asset Count:", selectedAssetCount);
  return (
    <Tabs
      value={activeTab}
      onValueChange={(value: string) =>
        setActiveTab(value as "categories" | "assets")
      }
    >
      <div className="flex flex-row justify-between">
        <TabsList>
          <TabsTrigger value="categories">Categories</TabsTrigger>
          {promotion ? (
            <TabsTrigger value="assets">
              Assets ({selectedAssetCount})
            </TabsTrigger>
          ) : null}
        </TabsList>

        {/* Tab Buttons (e.g., Edit, Save) */}
        <TabButtons
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          promotion={promotion}
          setLoading={setLoading}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          setPromotion={setPromotion}
          selectedMedia={selectedMedia}
          setSelectedMedia={setSelectedMedia}
          fetchAssets={fetchAssets}
          originalPromotion={originalPromotion}
          fetchPromotions={fetchPromotions}
        />
      </div>

      {/* Categories Tab Content */}
      <PromotionTab
        isEditing={isEditing}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        promotion={promotion}
        setPromotion={setPromotion}
      />

      {/* Assets Tab Content */}
      {promotion ? (
        <PromotionAssetsTab
          isEditing={isEditing}
          assets={assets}
          promotionTypes={promotion.types}
          promotionId={promotion.id}
          selectedMedia={selectedMedia}
          setSelectedMedia={setSelectedMedia}
          carouselApi={carouselApi}
          carouselAssetIndexMap={carouselAssetIndexMap}
          fetchAssets={fetchAssets}
          activePromotionType={activePromotionType}
          setActivePromotionType={setActivePromotionType}
          value="assets"
          setLoading={setLoading}
        />
      ) : null}
    </Tabs>
  );
};

export default TabsComponent;
