import React, { useEffect, useState, useCallback } from "react";
import { Promotion as PromotionType } from "../components/promotions/types";
import NoItems from "../components/misc/NoItems";
import LoadingScreen from "./LoadingScreen";
import { getAuthHeader } from "../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "../components/misc/CustomToast";
import axios from "axios";
import Promotion from "../components/promotions/Promotion";
import { Assets } from "../types/asset-type";

const PromotionsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [promotion, setPromotion] = useState<PromotionType | null>(null);
  const [originalPromotion, setOriginalPromotion] =
    useState<PromotionType | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [assets, setAssets] = useState<Assets[]>([]);

  // Memoize fetchPromotions to prevent unnecessary re-creations
  const fetchPromotions = useCallback(async () => {
    setLoading(true);

    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await axios.get(`${backendUrl}/promotions`, { headers });

      if (!response.data) {
        throw new Error("Response data is null.");
      }

      if (response.status !== 200) {
        throw new Error("Failed to fetch promotions. Please try again later.");
      } else {
        const data =
          response.data.id === null ||
          response.data.id === undefined ||
          response.data.id.length <= 0
            ? null
            : response.data;
        setPromotion(data);
        setOriginalPromotion(data);
      }
    } catch (error: unknown) {
      console.error("Failed to fetch promotions:", error);
      toast.error(
        <CustomToast
          message="Failed to fetch promotions. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  }, []);

  // Log promotion changes
  useEffect(() => {
    console.log("Promotions fetched successfully:", promotion);
  }, [promotion]);

  // Memoize fetchAssets to include dependencies
  const fetchAssets = useCallback(async () => {
    setLoading(true);
    if (!promotion || !promotion.id) {
      return;
    }

    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await axios.get(
        `${backendUrl}/assets/listing/${promotion.id}`,
        {
          headers,
        }
      );

      if (response.data) {
        if (Array.isArray(response.data)) {
          setAssets(response.data);
        } else if (
          response.data.assets &&
          Array.isArray(response.data.assets)
        ) {
          setAssets(response.data.assets);
        } else {
          console.error("Assets data is not an array", response.data);
          setAssets([]);
        }
      }
    } catch (error: unknown) {
      setLoading(false);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          console.log("No assets found for listing");
        } else {
          console.error("Error fetching assets:", error);
          toast.error(
            <CustomToast message="Failed to load assets." type="error" />,
            {
              autoClose: 3000,
            }
          );
        }
      } else {
        console.log("Error fetching assets:", error);
        toast.error(
          <CustomToast message="Failed to load assets." type="error" />,
          {
            autoClose: 3000,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  }, [promotion]);

  // Effect to fetch promotions on mount
  useEffect(() => {
    fetchPromotions();
  }, [fetchPromotions]);

  // Effect to fetch assets when promotion changes
  useEffect(() => {
    if (promotion) {
      fetchAssets();
    }
  }, [promotion, fetchAssets]);

  const handleCreatePromotion = () => {
    setIsEditing(true);
  };

  return (
    <div className="relative w-full h-full">
      {loading && <LoadingScreen />}
      {!loading && (!promotion || promotion === null) && !isEditing ? (
        <NoItems
          title="No Promotion"
          description="You have not created a promotion yet."
          descriptionTwo="Click the button below to create a new promotion."
          buttonText="Create Promotion"
          clickHandler={handleCreatePromotion}
        />
      ) : null}
      {!loading && (
        <Promotion
          promotion={promotion}
          setPromotion={setPromotion}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          assets={assets}
          setLoading={setLoading}
          fetchAssets={fetchAssets}
          fetchPromotions={fetchPromotions}
          originalPromotion={originalPromotion}
        />
      )}
    </div>
  );
};

export default PromotionsScreen;
