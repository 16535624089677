import React, { useState, useRef, useCallback, useEffect } from "react";
import { Button } from "./Button";
import { FaCloudUploadAlt, FaFileImage, FaFileVideo } from "react-icons/fa";
import axios, { AxiosProgressEvent } from "axios";
import { getAuthHeader } from "../../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "./CustomToast";
import { v4 as uuidv4 } from "uuid";
import { DragAndDropMediaProps } from "../listings/types";

interface FileWithPreview {
  file: File;
  preview: string;
}

const DragAndDropMedia: React.FC<DragAndDropMediaProps> = ({
  type,
  listingId,
  onUploadStart,
  onUploadComplete,
  propertyAddress1,
  mlsApproved,
  assetType,
}) => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Clean up object URLs to prevent memory leaks
  useEffect(() => {
    return () => {
      files.forEach((fileWithPreview) =>
        URL.revokeObjectURL(fileWithPreview.preview)
      );
    };
  }, [files]);

  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleFiles = useCallback(
    (selectedFiles: File[]) => {
      // Filter files based on the 'type' prop
      const validFiles = selectedFiles.filter((file) => {
        if (type === "image") return file.type.startsWith("image/");
        if (type === "video") return file.type.startsWith("video/");
        return file.type.startsWith("image/") || file.type.startsWith("video/");
      });

      // Map valid files to include preview URLs
      const newFiles: FileWithPreview[] = validFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    },
    [type]
  );

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const droppedFiles = Array.from(e.dataTransfer.files);
      handleFiles(droppedFiles);
    },
    [handleFiles]
  );

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFiles(Array.from(e.target.files));
      // Reset the input value to allow uploading the same file again if needed
      e.target.value = "";
    }
  };

  const removeFile = (fileToRemove: FileWithPreview) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    URL.revokeObjectURL(fileToRemove.preview);
  };

  const handleImageUpload = async () => {
    if (files.length === 0) {
      alert("No files to upload.");
      return;
    }

    setIsUploading(true);
    setUploadError(null);
    setUploadProgress(0);

    onUploadStart?.();

    const formData = new FormData();
    listingId && formData.append("listingId", listingId);
    files.forEach((fileWithPreview) => {
      formData.append("images", fileWithPreview.file);
    });

    try {
      const authHeader = await getAuthHeader();
      const headers = {
        ...authHeader,
        "Content-Type": "multipart/form-data",
      };

      const backendUrl = process.env.REACT_APP_BACKEND_URL || "";
      const response = await axios.patch(
        `${backendUrl}/${mlsApproved ? "realtor-listing" : "listing"}/images`,
        formData,
        {
          headers,
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            }
          },
        }
      );

      console.log("Upload successful:", response.data);

      // Call onUploadComplete to notify parent
      onUploadComplete?.();

      setFiles([]);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        setUploadError(error.response?.data?.message || error.message);
        toast.error(
          <CustomToast
            message={error.response?.data?.message || "Upload error"}
            type="error"
          />,
          {
            autoClose: 3000,
          }
        );
      } else {
        setUploadError("An unexpected error occurred.");
        toast.error(
          <CustomToast message="An unexpected error occurred." type="error" />,
          {
            autoClose: 3000,
          }
        );
      }
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleVideoUpload = async () => {
    if (files.length === 0) {
      alert("No files to upload.");
      return;
    }

    setIsUploading(true);
    setUploadError(null);
    setUploadProgress(0);

    onUploadStart?.();

    try {
      const authHeader = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

      for (let i = 0; i < files.length; i++) {
        const fileWithPreview = files[i];

        // Generate filename
        const baseAddress = propertyAddress1
          ? propertyAddress1.replace(/\s+/g, "_")
          : "video";
        const randomSalt = uuidv4().split("-")[0]; // Get first segment
        const counter = i + 1;
        const extension = fileWithPreview.file.name.split(".").pop();
        const filename = `${baseAddress}-${randomSalt}-${counter}.${extension}`;

        // Request signed URL from backend
        const signedUrlResponse = await axios.post(
          `${backendUrl}/listing/videos/signed-url`,
          {
            listingId,
            filename,
          },
          { headers: authHeader }
        );

        const { signedUrl } = signedUrlResponse.data;

        // Upload file to GCS
        await axios.put(signedUrl, fileWithPreview.file, {
          headers: {
            "Content-Type": fileWithPreview.file.type,
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            }
          },
        });

        // Inform backend of the uploaded video
        await axios.patch(
          `${backendUrl}/${
            mlsApproved ? "realtor-listing" : "listing"
          }/videos/${listingId}`,
          {
            filename,
          },
          { headers: authHeader }
        );
      }

      toast.success(
        <CustomToast message="Videos uploaded successfully." type="success" />,
        {
          autoClose: 3000,
        }
      );

      onUploadComplete?.();

      setFiles([]);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        setUploadError(error.response?.data?.message || error.message);
        toast.error(
          <CustomToast
            message={error.response?.data?.message || "Upload error"}
            type="error"
          />,
          {
            autoClose: 3000,
          }
        );
      } else {
        setUploadError("An unexpected error occurred.");
        toast.error(
          <CustomToast message="An unexpected error occurred." type="error" />,
          {
            autoClose: 3000,
          }
        );
      }
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleUpload = () => {
    if (type === "image") {
      handleImageUpload();
    } else if (type === "video") {
      handleVideoUpload();
    } else if (
      type === "asset" ||
      type === "assetImage" ||
      type === "assetVideo"
    ) {
      handleAssetsUpload(); // Call the new function
    }
  };

  const handleAssetsUpload = async () => {
    if (files.length === 0) {
      alert("No files to upload.");
      return;
    }

    setIsUploading(true);
    setUploadError(null);
    setUploadProgress(0);

    onUploadStart?.();

    // Separate files into images and videos
    const imageFiles = files.filter((fileWithPreview) =>
      fileWithPreview.file.type.startsWith("image/")
    );

    const videoFiles = files.filter((fileWithPreview) =>
      fileWithPreview.file.type.startsWith("video/")
    );

    try {
      const authHeader = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

      // Handle image uploads
      if (imageFiles.length > 0) {
        const formData = new FormData();
        listingId && formData.append("listingId", listingId);
        formData.append(
          "listingType",
          assetType ? "Promotion" : mlsApproved ? "MLSListing" : "Listing"
        );
        formData.append("assetType", assetType || "Image");
        imageFiles.forEach((fileWithPreview) => {
          formData.append("images", fileWithPreview.file);
        });

        const response = await axios.post(`${backendUrl}/assets`, formData, {
          headers: {
            ...authHeader,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            }
          },
        });

        console.log("Image assets uploaded successfully:", response.data);
      }

      // Handle video uploads
      for (let i = 0; i < videoFiles.length; i++) {
        const fileWithPreview = videoFiles[i];

        // Generate filename
        const baseAddress = propertyAddress1
          ? propertyAddress1.replace(/\s+/g, "_")
          : "asset_video";
        const randomSalt = uuidv4().split("-")[0];
        const counter = i + 1;
        const extension = fileWithPreview.file.name.split(".").pop();
        const filename = `${baseAddress}-${randomSalt}-${counter}.${extension}`;

        // Request signed URL from backend
        const signedUrlResponse = await axios.post(
          `${backendUrl}/assets/videos-signed-url`,
          {
            filename,
            contentType: fileWithPreview.file.type,
          },
          { headers: authHeader }
        );

        const { signedUrl } = signedUrlResponse.data;

        // Upload file to GCS
        await axios.put(signedUrl, fileWithPreview.file, {
          headers: {
            "Content-Type": fileWithPreview.file.type,
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            }
          },
        });

        // Inform backend of the uploaded video
        await axios.post(
          `${backendUrl}/assets/videos/${listingId}`,
          {
            filename,
          },
          { headers: authHeader }
        );
      }

      toast.success(
        <CustomToast message="Assets uploaded successfully." type="success" />,
        {
          autoClose: 3000,
        }
      );

      onUploadComplete?.();

      setFiles([]);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        setUploadError(error.response?.data?.message || error.message);
        toast.error(
          <CustomToast
            message={error.response?.data?.message || "Upload error"}
            type="error"
          />,
          {
            autoClose: 3000,
          }
        );
      } else {
        setUploadError("An unexpected error occurred.");
        toast.error(
          <CustomToast message="An unexpected error occurred." type="error" />,
          {
            autoClose: 3000,
          }
        );
      }
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  return (
    <div className="w-full mx-auto p-6">
      <div
        onDragOver={onDragOver}
        onDrop={onDrop}
        className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:border-gray-400 transition-colors"
        onClick={handleButtonClick}
        tabIndex={0}
        role="button"
        aria-label={`Drag and drop area for ${
          type === "image" || type === "assetImage"
            ? "images"
            : type === "video" || type === "assetVideo"
            ? "videos"
            : type === "asset"
            ? "assets"
            : "media"
        }`}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            handleButtonClick();
          }
        }}
      >
        <FaCloudUploadAlt className="mx-auto text-secondary mb-4" size={48} />
        <p className="text-primary mb-2">
          Drag and drop your{" "}
          {type === "image" || type === "assetImage"
            ? "images"
            : type === "video" || type === "assetVideo"
            ? "videos"
            : type === "asset"
            ? "assets"
            : "media"}{" "}
          here
        </p>
        <p className="text-primary text-sm">or</p>
        <Button
          type="button"
          size="sm"
          className="mt-2"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            handleButtonClick();
          }}
        >
          Select Files
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          multiple
          accept={
            type === "image" || type === "assetImage"
              ? "image/*"
              : type === "video" || type === "assetVideo"
              ? "video/*"
              : "image/*,video/*"
          }
          className="hidden"
          onChange={handleFileInputChange}
          aria-label="File input"
        />
      </div>

      {files.length > 0 && (
        <div className="mt-6">
          <div className="grid grid-cols-2 gap-4">
            {files.map((fileWithPreview, index) => (
              <div
                key={`${fileWithPreview.file.name}-${index}`}
                className="relative group"
              >
                {fileWithPreview.file.type.startsWith("image/") ? (
                  <img
                    src={fileWithPreview.preview}
                    alt={fileWithPreview.file.name}
                    className="w-full h-32 object-cover rounded-md"
                  />
                ) : (
                  <video
                    src={fileWithPreview.preview}
                    className="w-full h-32 object-cover rounded-md"
                    controls
                  />
                )}
                <button
                  onClick={() => removeFile(fileWithPreview)}
                  className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity h-6 w-6 flex justify-center items-center"
                  aria-label={`Remove ${fileWithPreview.file.name}`}
                >
                  <span className="text-sm font-bold">&times;</span>
                </button>
                {fileWithPreview.file.type.startsWith("image/") ? (
                  <FaFileImage
                    className="absolute bottom-1 left-1 text-secondary"
                    size={20}
                  />
                ) : (
                  <FaFileVideo
                    className="absolute bottom-1 left-1 text-secondary"
                    size={20}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="mt-4 flex flex-col items-start">
            <Button type="button" onClick={handleUpload} disabled={isUploading}>
              {isUploading
                ? type === "image"
                  ? `Uploading... ${uploadProgress}%`
                  : `Uploading... ${uploadProgress}%`
                : "Upload"}
            </Button>
            {isUploading && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                <div
                  className="bg-secondary h-2.5 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            )}
            {uploadError && (
              <span className="text-red-500 text-sm mt-2">{uploadError}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DragAndDropMedia;
