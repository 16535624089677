import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import Login from "../screens/LoginScreen";
import AdminPanelScreen from "../screens/AdminPanelScreen";
import SignupScreen from "../screens/SignupScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";
import OnboardingScreen from "../screens/OnboardingScreen";
import PreLogin from "../screens/PreLogin";
import VerifyEmailScreen from "../screens/VerifyEmailScreen";
import UserLayout from "../layouts/UserLayout";
import userRoutes from "./UserRoutes";

const verifyEmailRoutes = () => (
  <>
    <Route path="verify-email" element={<VerifyEmailScreen />} />
    <Route path="*" element={<Navigate to="/verify-email" />} />
  </>
);

const loginRoutes = () => (
  <>
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<SignupScreen />} />
    <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </>
);

const AppRoutes: React.FC = () => {
  const {
    user,
    role,
    isAdminView,
    isAuthenticated,
    isOnboarded,
    emailVerified,
    mlsApproved,
  } = useSelector((state: RootState) => state.auth);

  // Check if the app is in development mode
  const isDevMode = process.env.REACT_APP_ENV === "development";
  const preLoginPassed = sessionStorage.getItem("preLoginPassed") === "true";

  // Handle development mode with pre-login check
  if (isDevMode && !preLoginPassed) {
    return (
      <Routes>
        <Route path="*" element={<PreLogin />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* Public Routes */}
      {!isAuthenticated && loginRoutes()}

      {/* Protected Routes */}
      {isAuthenticated && (
        <>
          {/* Admin Panel */}
          {role === "ADMIN" && isAdminView && (
            <Route path="/admin" element={<AdminPanelScreen />} />
          )}

          {/* Onboarding */}
          {!isOnboarded && (
            <>
              <Route
                path="/onboarding"
                element={<OnboardingScreen role={role} />}
              />
              <Route path="*" element={<Navigate to="/onboarding" />} />
            </>
          )}

          {/* Main Application */}
          {isOnboarded && (
            <>
              {!emailVerified && verifyEmailRoutes()}

              {emailVerified && (
                <>
                  {/* User Layout with Nested Routes */}
                  <Route element={<UserLayout role={role} user={user} />}>
                    {userRoutes(mlsApproved)}
                  </Route>

                  {/* Redirect any unknown routes within the user layout */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </>
              )}
            </>
          )}
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
