import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./Tooltip";
import { FaCheck } from "react-icons/fa6";
import { cn } from "../../../utils/classMerger";
import CustomToast from "../../misc/CustomToast";
import { ImageSelectorProps } from "./types";
import { toast } from "react-toastify";

const ImageSelector: React.FC<ImageSelectorProps> = ({
  images,
  selectedImages,
  onSelect,
  maxImages,
  minImages,
}) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-primary">Select Images</h3>
      <span className="text-sm text-gray-500">
        Please select a minimum of {minImages} images and a maximum of{" "}
        {maxImages} images
      </span>

      {/* Display selected images count */}
      <div className="mb-2 text-sm text-gray-600">
        Selected {selectedImages.length} / {maxImages} images
      </div>

      <div className="grid grid-cols-3 gap-4">
        {images.map((image) => (
          <TooltipProvider key={image.id}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  className={cn(
                    "group relative w-full h-48 cursor-pointer overflow-hidden rounded-lg border",
                    selectedImages.includes(image.id) && "ring-2 ring-primary",
                    // Disable selection if max images reached and image not selected
                    !selectedImages.includes(image.id) &&
                      selectedImages.length >= maxImages &&
                      "opacity-50 cursor-not-allowed"
                  )}
                  onClick={() => {
                    if (
                      !selectedImages.includes(image.id) &&
                      selectedImages.length >= maxImages
                    ) {
                      // Show a toast notification
                      toast.error(
                        <CustomToast
                          message={`You can select up to ${maxImages} images.`}
                          type="error"
                        />
                      );
                      return;
                    }
                    onSelect(image.id);
                  }}
                >
                  <img
                    src={image.url}
                    alt=""
                    className="object-cover w-full h-full transition-transform duration-200 group-hover:scale-105"
                  />
                  {selectedImages.includes(image.id) && (
                    <div className="absolute inset-0 bg-primary/20 flex items-center justify-center">
                      <span className="absolute bottom-2 right-2 bg-primary text-white text-xs px-2 py-1 rounded">
                        {selectedImages.indexOf(image.id) + 1}
                      </span>
                      <FaCheck className="h-6 w-6 text-white" />
                    </div>
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <span className="text-white">Click to select</span>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
      </div>

      {/* Optional: Display an error message if minimum not met */}
      {selectedImages.length < minImages && (
        <div className="text-red-500 text-sm">
          Please select at least {minImages} images to continue.
        </div>
      )}

      {/* Removed the redundant "Continue" button */}
    </div>
  );
};

export default ImageSelector;
