import React, { useState } from "react";
import InputField from "../components/misc/InputField";
import Button from "../components/Button";
import Divider from "../components/misc/Divider";
import { createButton } from "react-social-login-buttons";
import { FaFacebookF, FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import {
  signInWithGoogle,
  signInWithFacebook,
  signUpWithEmail,
} from "../firebase/auth";
import logo from "../assets/images/logo.png";
import { isValidEmail, isValidPassword } from "../utils/validationUtil";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../components/misc/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import LoadingScreen from "./LoadingScreen";

const SignupScreen: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [showSocialButtons, setShowSocialButtons] = useState<boolean>(true);
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);

  const [passwordRequirements, setPasswordRequirements] = useState({
    char: false,
    charCount: false,
    special: false,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const handleGoogleSignUp = async () => {
    try {
      const user = await signInWithGoogle();
      console.log("Google Sign-In Success:", user);
    } catch (error) {
      toast.error(
        <CustomToast message={(error as Error).message} type="error" />,
        {
          autoClose: 3000,
        }
      );
    }
  };

  const handleFacebookSignUp = async () => {
    setLoading(true);
    try {
      const user = await signInWithFacebook();
      console.log("Facebook Sign-In Success:", user);
    } catch (error) {
      toast.error(
        <CustomToast message={(error as Error).message} type="error" />,
        {
          autoClose: 3000,
        }
      );
    }
    setLoading(false);
  };

  const handleEmailSignUp = async () => {
    setLoading(true);
    if (!email) {
      toast.error(
        <CustomToast message="Please enter your email address." type="error" />,
        {
          autoClose: 3000,
        }
      );
    }

    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    const emailExists = await checkEmailExists(email);

    if (emailExists) {
      setLoading(false);
      toast.error(
        <CustomToast
          message="This email is already registered."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
      setEmailError("This email is already registered.");
      return;
    }

    if (!password) {
      setLoading(false);
      toast.error(
        <CustomToast message="Please enter your password." type="error" />,
        {
          autoClose: 3000,
        }
      );
    }

    if (
      emailError ||
      !passwordRequirements.char ||
      !passwordRequirements.charCount ||
      !passwordRequirements.special
    ) {
      setLoading(false);
      toast.error(
        <CustomToast
          message="Please ensure your email and password meet the requirements."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
      return;
    }

    try {
      const user = await signUpWithEmail(email, password);
      console.log("Email Sign-Up Success:", user);
    } catch (error) {
      toast.error(
        <CustomToast message={(error as Error).message} type="error" />,
        {
          autoClose: 3000,
        }
      );
    }
    setLoading(false);
  };

  const handleEmailBlur = async () => {
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    try {
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        setEmailError("This email is already registered.");
      } else {
        setEmailError(null);
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    setShowSocialButtons(value.length === 0 && email.length === 0);

    setPasswordRequirements(isValidPassword(value));

    setShowPasswordRequirements(true);
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setShowSocialButtons(value.length === 0 && password.length === 0);
  };

  const facebookButtonConfig = {
    text: "Sign up with Facebook",
    icon: FaFacebookF,
    style: {
      background: "#1877F2",
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      borderRadius: "15px!important",
    },
    activeStyle: { background: "#166fe5" },
  };

  const GoogleSvgIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 326667 333333"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      className="w-10 h-6 mr-3"
    >
      <path
        d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z"
        fill="#4285f4"
      />
      <path
        d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z"
        fill="#34a853"
      />
      <path
        d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z"
        fill="#fbbc04"
      />
      <path
        d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z"
        fill="#ea4335"
      />
    </svg>
  );

  const googleButtonConfig = {
    text: "Sign up with Google",
    icon: GoogleSvgIcon,
    style: {
      background: "#fff",
      color: "#000",
      display: "flex",
      justifyContent: "center",
      boxShadow: "0px 0px 3px rgba(0,0,0,0.084)",
    },
    activeStyle: { background: "#f1f1f1" },
  };

  const MyFacebookLoginButton = createButton(facebookButtonConfig);
  const MyGoogleLoginButton = createButton(googleButtonConfig);

  return (
    <main className="flex overflow-hidden flex-col justify-center items-center h-screen bg-gray-300 shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
      <section className="flex flex-col items-center px-14 py-20 max-w-full bg-white rounded-2xl min-h-[833px] w-[605px] max-md:px-5 max-md:pb-24">
        {loading && <LoadingScreen />}
        <header className="flex flex-col max-w-full text-2xl font-bold leading-none text-center text-primary w-[395px]">
          <img
            loading="lazy"
            src={logo}
            alt="Marabot Logo"
            className="object-contain w-full aspect-[8.77]"
          />
          <h1 className="mt-2">Sign up to start posting</h1>
        </header>
        <form
          className="flex flex-col mt-11 max-w-full w-[393px] max-md:mt-10"
          onSubmit={(e) => {
            e.preventDefault();
            handleEmailSignUp();
          }}
        >
          <InputField
            label="Email address"
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => handleEmailChange(e.target.value)}
            onBlur={handleEmailBlur}
            errorMessage={emailError}
          />
          <InputField
            label="Password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            showPasswordToggle
          />
          {showPasswordRequirements && (
            <div className="flex flex-col mt-4 space-y-2">
              <div className="flex items-center gap-2">
                {passwordRequirements.char ? (
                  <FaCheckCircle className="text-green-600" />
                ) : (
                  <FaTimesCircle className="text-red-600" />
                )}
                <span className="text-sm text-neutral-700">
                  Password must contain at least 1 letter.
                </span>
              </div>
              <div className="flex items-center gap-2">
                {passwordRequirements.special ? (
                  <FaCheckCircle className="text-green-600" />
                ) : (
                  <FaTimesCircle className="text-red-600" />
                )}
                <span className="text-sm text-neutral-700">
                  Password must contain at least 1 number or special character.
                </span>
              </div>
              <div className="flex items-center gap-2">
                {passwordRequirements.charCount ? (
                  <FaCheckCircle className="text-green-600" />
                ) : (
                  <FaTimesCircle className="text-red-600" />
                )}
                <span className="text-sm text-neutral-700">
                  Password must be at least 10 characters long.
                </span>
              </div>
            </div>
          )}
          <Button text="Sign Up" type="submit" className="mt-11 max-md:mt-10" />
        </form>
        {showSocialButtons && (
          <>
            <Divider text="" className="mt-11 max-md:mt-10" />
            <div className="flex flex-col mt-11 max-w-full text-xl rounded-xl w-[397px] max-md:mt-10">
              <MyGoogleLoginButton onClick={handleGoogleSignUp} />
              <MyFacebookLoginButton onClick={handleFacebookSignUp} />
            </div>
          </>
        )}
        <footer className="flex items-center mt-11 text-sm font-medium tracking-normal max-md:mt-10">
          <p className="self-stretch my-auto text-primary w-full">
            Already have an account?
            <a href="/login" className="self-stretch my-auto text-secondary">
              &nbsp;Log in here
            </a>
          </p>
        </footer>
      </section>
    </main>
  );
};

export default SignupScreen;

async function checkEmailExists(email: string): Promise<boolean> {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/check-email`;
    const response = await axios.post(
      url,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error checking email:", error);
    return false;
  }
}
