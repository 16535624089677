import React, { useState } from "react";
import { PromotionTabButtonProps } from "./types";
import { Button } from "../misc/Button";
import { FaBoxArchive, FaSquarePlus, FaTrash } from "react-icons/fa6";
import { FaEdit, FaPlay, FaSave } from "react-icons/fa";
import ReconfirmOverlay from "../misc/ReconfirmOverlay";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";

const TabButtons: React.FC<PromotionTabButtonProps> = ({
  isEditing,
  setIsEditing,
  promotion,
  setLoading,
  selectedCategories,
  setSelectedCategories,
  setPromotion,
  selectedMedia,
  setSelectedMedia,
  fetchAssets,
  originalPromotion,
  fetchPromotions,
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const handlePromotionDelete = async () => {
    if (!promotion || !promotion.id) {
      toast.error(
        <CustomToast
          message="Failed to delete promotion. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
      return;
    }

    setLoading(true);

    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.delete(
        `${backendUrl}/promotions/${promotion.id}`,
        {
          headers,
        }
      );

      if (response.status === 200) {
        setLoading(false);
        toast.success(
          <CustomToast
            message="Promotion deleted successfully."
            type="success"
          />,
          {
            autoClose: 3000,
          }
        );
        setIsEditing(false);
        fetchPromotions();
        setPromotion(null);
        setSelectedCategories([]);
      } else {
        throw new Error("Failed to delete promotion.");
      }
    } catch (error: unknown) {
      setLoading(false);
      console.error("Failed to delete promotion:", error);
      setLoading(false);
      toast.error(
        <CustomToast
          message="Failed to delete promotion. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!promotion || !originalPromotion) {
      toast.error(
        <CustomToast
          message="Failed to save promotion. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
      return;
    }

    // Check if there are changes
    const hasChanges =
      JSON.stringify(selectedCategories) !==
        JSON.stringify(originalPromotion.types) ||
      promotion.color !== originalPromotion.color;

    if (!hasChanges) {
      toast.success(<CustomToast message="Promotion Saved" type="success" />, {
        autoClose: 3000,
      });
      setIsEditing(false);
      return;
    }

    setLoading(true);

    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.patch(
        `${backendUrl}/promotions/${promotion.id}`,
        { types: selectedCategories, color: promotion.color },
        { headers }
      );

      if (!response.data) {
        throw new Error("Response data is null.");
      }

      if (response.status === 200) {
        setLoading(false);
        toast.success(
          <CustomToast
            message="Promotion saved successfully."
            type="success"
          />,
          {
            autoClose: 3000,
          }
        );
        setPromotion({ ...promotion, types: selectedCategories });
        setIsEditing(false);
      } else {
        throw new Error("Failed to save promotion.");
      }
    } catch (error: unknown) {
      setLoading(false);
      console.error("Failed to save promotion:", error);
      setLoading(false);
      toast.error(
        <CustomToast
          message="Failed to save promotion. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const confirmAction = () => {
    setIsOverlayOpen(false);
    handlePromotionDelete();
  };

  const handleCreatePromotion = async () => {
    setLoading(true);
    if (selectedCategories.length <= 0) {
      toast.error(
        <CustomToast
          message="You need to choose atleast one category to create a promotion."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );

      setLoading(false);
      return;
    }

    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await axios.post(
        `${backendUrl}/promotions`,
        { types: selectedCategories },
        { headers }
      );

      if (!response.data) {
        throw new Error("Response data is null.");
      }

      if (response.status === 201) {
        toast.success(
          <CustomToast
            message="Promotion created successfully."
            type="success"
          />,
          {
            autoClose: 3000,
          }
        );
        setPromotion(response.data);
        setIsEditing(false);
      } else {
        throw new Error("Failed to create promotion.");
      }
    } catch (error: unknown) {
      console.error("Failed to create promotion:", error);
      setLoading(false);
      toast.error(
        <CustomToast
          message="Failed to create promotion. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePauseCampaign = async () => {
    if (!promotion?.campaignId) return;

    setLoading(true);

    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.patch(
        `${backendUrl}/campaign/${promotion.campaignId}`,
        { status: "PAUSED" },
        { headers }
      );

      if (response.status === 200) {
        setLoading(false);
        toast.success(
          <CustomToast
            message="Campaign paused successfully."
            type="success"
          />,
          { autoClose: 3000 }
        );
        fetchPromotions();
      } else {
        throw new Error("Failed to pause campaign.");
      }
    } catch (error: unknown) {
      setLoading(false);
      console.error("Error pausing campaign", error);
      toast.error(
        <CustomToast
          message="Error pausing campaign. Please try again later."
          type="error"
        />,
        { autoClose: 3000 }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleResumeCampaign = async () => {
    if (!promotion?.campaignId) return;

    setLoading(true);

    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.patch(
        `${backendUrl}/campaign/${promotion.campaignId}`,
        { status: "ACTIVE" },
        { headers }
      );

      if (response.status === 200) {
        setLoading(false);
        toast.success(
          <CustomToast
            message="Campaign resumed successfully."
            type="success"
          />,
          { autoClose: 3000 }
        );
        fetchPromotions();
      } else {
        throw new Error("Failed to resume campaign.");
      }
    } catch (error: unknown) {
      setLoading(false);
      console.error("Error resuming campaign", error);
      toast.error(
        <CustomToast
          message="Error resuming campaign. Please try again later."
          type="error"
        />,
        { autoClose: 3000 }
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {isEditing ? (
        <>
          <div className="flex flex-row justify-end space-x-2">
            {promotion ? (
              <>
                {promotion?.campaignStatus === "PAUSED" ? (
                  <Button
                    className="bg-secondary hover:bg-primary"
                    onClick={handleResumeCampaign}
                  >
                    <FaPlay className="h-4 w-4" />
                    Resume Campaign
                  </Button>
                ) : (
                  promotion?.campaignStatus === "ACTIVE" && (
                    <Button
                      className="bg-secondary hover:bg-primary"
                      onClick={handlePauseCampaign}
                    >
                      <FaBoxArchive className="h-4 w-4" />
                      Pause Campaign
                    </Button>
                  )
                )}
                <Button
                  className="bg-secondary hover:bg-primary"
                  onClick={() => {
                    setIsOverlayOpen(true);
                  }}
                >
                  <FaTrash className="h-4 w-4" />
                  Delete Promotion
                </Button>
                <Button
                  className="bg-secondary hover:bg-primary"
                  onClick={handleSave}
                >
                  <FaSave className="h-4 w-4" />
                  Save
                </Button>
              </>
            ) : (
              <Button
                className="bg-secondary hover:bg-primary"
                onClick={handleCreatePromotion}
              >
                <FaSquarePlus className="h-4 w-4" />
                Create Promotion
              </Button>
            )}
          </div>
          <ReconfirmOverlay
            isOpen={isOverlayOpen}
            onClose={() => setIsOverlayOpen(false)}
            onConfirm={confirmAction}
            title="Delete Promotion"
            message="Are you sure you want to delete this promotion?"
            messageTwo="This action cannot be undone."
            confirmText="Delete Promotion"
          />
        </>
      ) : (
        <div className="flex flex-row space-x-4">
          <Button
            className="bg-secondary hover:bg-primary"
            onClick={() => setIsEditing(true)}
          >
            <FaEdit className="mr-2 h-6 w-6 text-white" />
            Edit
          </Button>
        </div>
      )}
    </>
  );
};

export default TabButtons;
