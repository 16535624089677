import { Assets } from "../../types/asset-type";
import { PromotionType } from "./types";

export const isPromotionType = (type: string): type is PromotionType => {
  return Object.values(PromotionType).includes(type as PromotionType);
};

export function calculateTotalPages(
  assets: Assets[],
  itemsPerPage: number = 10
): number {
  if (!assets || assets.length === 0) return 1;
  return Math.ceil(assets.length / itemsPerPage);
}
