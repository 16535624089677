import React, { useCallback, useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import StepProgressBar from "../progress-bar";
import InputField from "../misc/InputField";
import { BrokerDetails as BrokerDetailsType } from "../../types/onboarding";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import Button from "../Button";
import LoadingScreen from "../../screens/LoadingScreen";
import "react-toastify/dist/ReactToastify.css";
import { isValidEmail } from "../../utils/validationUtil";
import { getAuthHeader } from "../../utils/authHeader";

interface BrokerDetailsProps {
  setCurrentStep: (step: number) => void;
  role: string | null;
}

const BrokerDetails: React.FC<BrokerDetailsProps> = ({
  setCurrentStep,
  role,
}) => {
  const [brokerDetails, setBrokerDetails] = useState<BrokerDetailsType>({
    brokerRecordName: "",
    brokerRecordLicenseNumber: "",
    brokerRecordEmail: "",
  });

  const [loading, setLoading] = useState<boolean>(true);

  const [initialDetails, setInitialDetails] =
    useState<BrokerDetailsType | null>(null);

  useEffect(() => {
    const fetchBrokerDetails = async () => {
      try {
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await axios.get(`${backendUrl}/users/broker-details`, {
          headers,
          withCredentials: true,
        });
        setBrokerDetails(response.data);
        setInitialDetails(response.data);
      } catch (error) {
        console.error("Error fetching broker details:", error);
        toast.error(
          <CustomToast
            message="Failed to fetch broker details. Please try again."
            type="error"
          />,
          {
            autoClose: 3000,
          }
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrokerDetails();
  }, []);

  const hasChanges = useCallback(() => {
    if (!initialDetails) return false;
    return JSON.stringify(brokerDetails) !== JSON.stringify(initialDetails);
  }, [brokerDetails, initialDetails]);

  const handleSaveAndNext = async () => {
    setLoading(true);
    if (
      !brokerDetails.brokerRecordName ||
      !brokerDetails.brokerRecordLicenseNumber ||
      !brokerDetails.brokerRecordEmail
    ) {
      setLoading(false);
      toast.error(
        <CustomToast message="Please fill in all the fields." type="error" />,
        {
          autoClose: 3000,
        }
      );
      return;
    } else if (!isValidEmail(brokerDetails.brokerRecordEmail)) {
      setLoading(false);
      toast.error(
        <CustomToast
          message="Please enter a valid email address."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
      return;
    }

    try {
      if (hasChanges()) {
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        await axios.patch(`${backendUrl}/users/broker-details`, brokerDetails, {
          headers,
          withCredentials: true,
        });
        toast.success(
          <CustomToast
            message="Broker details saved successfully!"
            type="success"
          />,
          {
            autoClose: 3000,
          }
        );
      }

      setCurrentStep(3); // Move to next step
    } catch (error) {
      console.error("Error saving broker details:", error);
      toast.error(
        <CustomToast
          message="Failed to save broker details. Please try again later!"
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    }
  };

  const handleBack = () => {
    setCurrentStep(1); // Set step back to 1
  };

  const progressSteps = [
    { label: "Agent Details", isActive: true, isCompleted: true },
    { label: "Broker Details", isActive: true, isCompleted: false },
    { label: "Choose MLS", isActive: false, isCompleted: false },
  ];

  if (role !== "TEAM_USER") {
    progressSteps.push({
      label: "Select Plan",
      isActive: false,
      isCompleted: false,
    });
    progressSteps.push({
      label: "Payment",
      isActive: false,
      isCompleted: false,
    });
  }

  return (
    <main className="flex overflow-hidden flex-col justify-center items-center min-h-screen bg-background shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
      <section className="flex flex-col items-center px-14 py-20 max-w-full bg-white rounded-2xl min-h-[833px] w-[905px] max-md:px-5 max-md:pb-24">
        {/* Logo and Progress at Top */}
        <div className="w-full flex flex-col items-center mb-8">
          <img
            loading="lazy"
            src={logo}
            alt="Agent Registration Logo"
            className="object-contain self-center max-w-full aspect-[8.77] w-[395px]"
          />
          <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
            User Onboarding
          </h1>
          <StepProgressBar steps={progressSteps.filter((step) => step)} />
        </div>

        {loading && <LoadingScreen />}

        {/* Form in the Middle */}
        <div className="flex-grow flex items-center w-full">
          <form className="w-full">
            <InputField
              label="Broker Name"
              placeholder="Broker Name"
              type="text"
              value={brokerDetails.brokerRecordName}
              onChange={(e) =>
                setBrokerDetails({
                  ...brokerDetails,
                  brokerRecordName: e.target.value,
                })
              }
            />
            <InputField
              label="Broker License Number"
              placeholder="License Number"
              type="text"
              value={brokerDetails.brokerRecordLicenseNumber}
              onChange={(e) =>
                setBrokerDetails({
                  ...brokerDetails,
                  brokerRecordLicenseNumber: e.target.value,
                })
              }
            />
            <InputField
              label="Broker Email Address"
              placeholder="Email Address"
              type="email"
              value={brokerDetails.brokerRecordEmail}
              onChange={(e) =>
                setBrokerDetails({
                  ...brokerDetails,
                  brokerRecordEmail: e.target.value,
                })
              }
            />
          </form>
        </div>

        {/* Buttons at the Bottom */}
        <div className="w-full flex justify-between mt-8 gap-20">
          <Button text="Back" className="w-1/3" onClick={handleBack} />
          <Button text="Next" className="w-1/3" onClick={handleSaveAndNext} />
        </div>
      </section>
    </main>
  );
};

export default BrokerDetails;
