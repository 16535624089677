import React, { useEffect, useState } from "react";
import { Tabs, TabsList, TabsTrigger } from "../../misc/Tabs";
import { CombinedMedia, TabsProps } from "./types";
import { getAuthHeader } from "../../../utils/authHeader";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../../misc/CustomToast";
import { compileAddress } from "../../../utils/addressCompiler";
import AssetsTab from "./tabs/AssetsTab";
import ImagesTab from "./tabs/ImagesTab";
import VideosTab from "./tabs/VideosTab";
import DetailsTab from "./tabs/DetailsTab";
import TabButtons from "./tabs/TabButtons";

const TabsComponent: React.FC<TabsProps> = ({
  images,
  videos,
  assets,
  setIsLoading,
  carouselApi,
  propertyData,
  fetchListing,
  fetchAssets,
  setPropertyData,
  originalPropertyData,
  setOriginalPropertyData,
  combinedMedia,
  mlsApproved,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState<
    "images" | "videos" | "details" | "assets"
  >("details");
  const [selectedMedia, setSelectedMedia] = useState<CombinedMedia[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  useEffect(() => {
    if (!isEditing) {
      setSelectedMedia([]);
    }
  }, [isEditing]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  const toggleSelectMedia = (media: CombinedMedia) => {
    setSelectedMedia(
      selectedMedia.some((m) => m.id === media.id)
        ? selectedMedia.filter((m) => m.id !== media.id)
        : [...selectedMedia, media]
    );
  };

  const handleUploadComplete = () => {
    fetchListing();
    fetchAssets();
  };

  const handleSave = async () => {
    if (!propertyData || !originalPropertyData) return;

    const changes = getChanges(originalPropertyData, propertyData);

    if (Object.keys(changes).length === 0) {
      setIsEditing(false);
      toast.success(<CustomToast message="Saved." type="success" />, {
        autoClose: 3000,
      });
      return;
    }

    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await axios.patch(
        `${backendUrl}/${mlsApproved ? "realtor-listing" : "listing"}`,
        { ...changes, listingId: propertyData._id },
        { headers }
      );

      if (response.status === 200) {
        toast.success(
          <CustomToast
            message="Listing updated successfully."
            type="success"
          />,
          { autoClose: 3000 }
        );
        setOriginalPropertyData(propertyData);
        setIsEditing(false);
      } else {
        throw new Error("Failed to update listing.");
      }
    } catch (error) {
      console.error("Error saving listing:", error);
      toast.error(
        <CustomToast message="Failed to save changes." type="error" />,
        { autoClose: 3000 }
      );
    }
  };

  const getChanges = <T extends object>(
    original: T,
    updated: T
  ): Partial<T> => {
    const changes: Partial<T> = {};

    (Object.keys(updated) as Array<keyof T>).forEach((key) => {
      const updatedValue = updated[key];
      const originalValue = original[key];

      if (key === "features" || key === "footer") {
        if (key === "footer" && updatedValue && originalValue) {
          // Serialize agencyAddress using compileAddress if it has changed
          const originalFooter = originalValue as any;
          const updatedFooter = updatedValue as any;

          if (
            JSON.stringify(updatedFooter.agencyAddress) !==
            JSON.stringify(originalFooter.agencyAddress)
          ) {
            changes[key] = {
              ...updatedFooter,
              agencyAddress: compileAddress(updatedFooter.agencyAddress),
            };
          } else if (
            JSON.stringify(updatedValue) !== JSON.stringify(originalValue)
          ) {
            changes[key] = updatedValue;
          }
        } else {
          if (JSON.stringify(updatedValue) !== JSON.stringify(originalValue)) {
            changes[key] = updatedValue;
          }
        }
        return;
      }

      if (
        typeof updatedValue === "object" &&
        updatedValue !== null &&
        !Array.isArray(updatedValue) &&
        typeof originalValue === "object" &&
        originalValue !== null &&
        !Array.isArray(originalValue)
      ) {
        const nestedChanges = getChanges(originalValue, updatedValue);
        if (Object.keys(nestedChanges).length > 0) {
          changes[key] = nestedChanges as T[keyof T];
        }
      } else if (originalValue !== updatedValue) {
        changes[key] = updatedValue;
      }
    });

    return changes;
  };

  return (
    <Tabs
      value={activeTab}
      onValueChange={(value: string) =>
        setActiveTab(value as "images" | "videos" | "details" | "assets")
      }
    >
      <div className="flex flex-row justify-between">
        <TabsList>
          <TabsTrigger value="details">Details</TabsTrigger>
          <TabsTrigger value="images">Images ({images.length})</TabsTrigger>
          <TabsTrigger value="videos">Videos ({videos.length})</TabsTrigger>
          <TabsTrigger value="assets">Assets ({assets.length})</TabsTrigger>
        </TabsList>

        <TabButtons
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          selectedMedia={selectedMedia}
          handleSave={handleSave}
          setIsLoading={setIsLoading}
          propertyData={propertyData}
          fetchListing={fetchListing}
          fetchAssets={fetchAssets}
          setSelectedMedia={setSelectedMedia}
          mlsApproved={mlsApproved}
        />
      </div>

      {/* Details Tab */}
      <DetailsTab
        propertyData={propertyData}
        setPropertyData={setPropertyData}
        isEditing={isEditing}
        handleSave={handleSave}
        mlsApproved={mlsApproved}
      />

      {/* Images Tab */}
      <ImagesTab
        images={images}
        listingId={propertyData._id}
        handleUploadComplete={handleUploadComplete}
        toggleSelectMedia={toggleSelectMedia}
        selectedMedia={selectedMedia}
        isEditing={isEditing}
        carouselApi={carouselApi}
        combinedMedia={combinedMedia}
        mlsApproved={mlsApproved}
        setSelectedMedia={setSelectedMedia}
        setIsLoading={setIsLoading}
        itemsPerPage={10}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      {/* Videos Tab */}
      <VideosTab
        videos={videos}
        listingId={propertyData._id}
        address1={propertyData.formattedAddress.split(",")[0]}
        handleUploadComplete={handleUploadComplete}
        toggleSelectMedia={toggleSelectMedia}
        selectedMedia={selectedMedia}
        isEditing={isEditing}
        carouselApi={carouselApi}
        combinedMedia={combinedMedia}
        mlsApproved={mlsApproved}
        setSelectedMedia={setSelectedMedia}
        setIsLoading={setIsLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemsPerPage={10}
      />

      {/* Asset Tab */}
      <AssetsTab
        assets={assets}
        listingId={propertyData._id}
        address1={propertyData.formattedAddress.split(",")[0]}
        handleUploadComplete={handleUploadComplete}
        toggleSelectMedia={toggleSelectMedia}
        selectedMedia={selectedMedia}
        isEditing={isEditing}
        carouselApi={carouselApi}
        combinedMedia={combinedMedia}
        mlsApproved={mlsApproved}
        setSelectedMedia={setSelectedMedia}
        setIsLoading={setIsLoading}
        itemsPerPage={10}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Tabs>
  );
};

export default TabsComponent;
