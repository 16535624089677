import React from "react";

interface UserLimitInfoProps {
  users: object[];
  totalLimit: number;
}

export const UserLimitInfo: React.FC<UserLimitInfoProps> = ({
  users,
  totalLimit,
}) => {
  return (
    <div className="flex flex-col mt-2.5 w-full text-sm tracking-tighter text-gray-700 max-w-[695px]">
      <div className="flex gap-6 items-center">
        <div className="self-stretch my-auto w-full text-left">
          Added Users: {users.length}
        </div>
        <div className="self-stretch my-auto text-right w-full">
          Total Limit: {totalLimit}
        </div>
      </div>
      <div className="shrink-0 mt-1 h-px border border-gray-700 border-solid" />
    </div>
  );
};
