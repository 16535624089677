import React, { useEffect, useState } from "react";
import { ContentTabsProps, promotionTypes } from "./types";
import { Tabs, TabsList, TabsTrigger } from "../misc/Tabs";
import ListingsTab from "./ListingsTab";
import PromotionAssetsTab from "../promotions/PromotionAssetsTab";
import { Assets } from "../../types/asset-type";
import { PromotionType } from "../promotions/types";
import { Button } from "../misc/Button";
import { FaCircleCheck, FaPencil } from "react-icons/fa6";
import ResourcesTab from "./ResourcesTab";

const ContentTabs: React.FC<ContentTabsProps> = ({
  promotions,
  activeTab,
  setActiveTab,
  listingMedia,
  listingTabs,
  activeListingTab,
  setActiveListingTab,
  loading,
  mlsApproved,
  carouselApi,
  fetchAssets,
  fetchListingMedia,
  setLoading,
  carouselIndexMap,
  resources,
  fetchResources,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<Assets[]>([]);
  const [activePromotionType, setActivePromotionType] = useState<PromotionType>(
    PromotionType.BuyerEducation
  );

  useEffect(() => {
    setSelectedMedia([]);
  }, [setIsEditing]);

  console.log("Promotions:", promotions?.assets);
  return (
    <Tabs
      value={activeTab}
      onValueChange={(value: string) =>
        setActiveTab(value as "listing" | "promotion")
      }
    >
      <div className="flex flex-row justify-between">
        <TabsList>
          <TabsTrigger value="listing">Listings</TabsTrigger>
          <TabsTrigger value="promotion">Promotion</TabsTrigger>
          <TabsTrigger value="resources">Resources</TabsTrigger>
        </TabsList>
        <Button onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? (
            <FaCircleCheck className="mr-1 w-4 h-4" />
          ) : (
            <FaPencil className="mr-1 w-4 h-4" />
          )}
          {isEditing ? "Done" : "Edit"}
        </Button>
      </div>
      <ListingsTab
        listingMedia={listingMedia}
        listingTabs={listingTabs}
        activeListingTab={activeListingTab}
        setActiveListingTab={setActiveListingTab}
        activeTab={activeTab}
        loading={loading}
        mlsApproved={mlsApproved}
        carouselApi={carouselApi}
        setLoading={setLoading}
        fetchListingMedia={fetchListingMedia}
        carouselIndexMap={carouselIndexMap}
        isEditing={isEditing}
      />
      {promotions?.assets ? (
        <PromotionAssetsTab
          isEditing={isEditing}
          assets={Array.isArray(promotions?.assets) ? promotions.assets : []}
          promotionTypes={promotionTypes}
          promotionId={promotions?.id}
          selectedMedia={selectedMedia}
          setSelectedMedia={setSelectedMedia}
          carouselApi={carouselApi}
          fetchAssets={fetchAssets}
          activePromotionType={activePromotionType}
          setActivePromotionType={setActivePromotionType}
          carouselAssetIndexMap={carouselIndexMap}
          value="promotion"
          setLoading={setLoading}
        />
      ) : null}

      <ResourcesTab
        resources={resources}
        isEditing={isEditing}
        carouselApi={carouselApi}
        carouselIndexMap={carouselIndexMap}
        fetchResources={fetchResources}
        setLoading={setLoading}
      />
    </Tabs>
  );
};

export default ContentTabs;
