import React from "react";
import { AiOutlineCheck } from "react-icons/ai";

interface FeatureItemProps {
  text: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ text }) => {
  return (
    <li className="flex gap-4 items-center mt-3 w-full">
      <AiOutlineCheck className="text-secondary w-5 h-5" />
      <span className="flex-1 shrink self-stretch my-auto basis-0">{text}</span>
    </li>
  );
};

export default FeatureItem;
