export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const isValidPassword = (
  password: string
): { char: boolean; charCount: boolean; special: boolean } => {
  const charRegex = /[a-zA-Z]/; // Checks for at least one letter
  const specialRegex = /[\d!@#$%^&*(),.?":{}|<>]/; // Checks for at least one number or special character

  const isCharPresent = charRegex.test(password);
  const isSpecialPresent = specialRegex.test(password);
  const isMinLength = password.length >= 10;

  return {
    char: isCharPresent,
    charCount: isMinLength,
    special: isSpecialPresent,
  };
};

export const isValidPhoneNumber = (phone: string): boolean => {
  const phoneRegex = /^(\+?\d{1,2})?[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/;
  return phoneRegex.test(phone);
};
