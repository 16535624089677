import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OptionType {
  label: string;
  value: string;
}

interface CalendarState {
  selectedView: OptionType;
  selectedListingAddress: OptionType[];
  selectedPlatform: OptionType[];
  selectedApprovalStatus: OptionType[];
  currentDate: Date;
}

const initialState: CalendarState = {
  selectedView: { label: "Weekly View", value: "weekly" },
  selectedListingAddress: [],
  selectedPlatform: [],
  selectedApprovalStatus: [],
  currentDate: new Date(),
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setSelectedView(state, action: PayloadAction<OptionType>) {
      state.selectedView = action.payload;
    },
    setSelectedListingAddress(state, action: PayloadAction<OptionType[]>) {
      state.selectedListingAddress = action.payload;
    },
    setSelectedPlatform(state, action: PayloadAction<OptionType[]>) {
      state.selectedPlatform = action.payload;
    },
    setSelectedApprovalStatus(state, action: PayloadAction<OptionType[]>) {
      state.selectedApprovalStatus = action.payload;
    },
    setCurrentDate(state, action: PayloadAction<Date>) {
      state.currentDate = action.payload;
    },
    resetFilters(state) {
      state.selectedListingAddress = [];
      state.selectedPlatform = [];
      state.selectedApprovalStatus = [];
    },
    resetCalendarState() {
      return initialState;
    },
  },
});

export const {
  setSelectedView,
  setSelectedListingAddress,
  setSelectedPlatform,
  setSelectedApprovalStatus,
  setCurrentDate,
  resetFilters,
  resetCalendarState,
} = calendarSlice.actions;

export default calendarSlice.reducer;
