import React, { useEffect, useRef, useState } from "react";
import LeafletMap from "./LeafletMap";
import { IoIosAlert } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { AllListings } from "../../types/allListings";
import { Images, Loader, Video } from "lucide-react";
import { useSocket } from "../../context/SocketContext";
import { Progress } from "./types";
import { CampaignProgress } from "../campaign/types";
import CampaignStatusCard from "../campaign/CampaignStatusCard";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import { getAuthHeader } from "../../utils/authHeader";
import axios from "axios";
import { CampaignCardListingProps } from "./listing/types";
import { useLocation } from "react-router-dom";

interface ListingDisplayProps {
  listings: AllListings[];
  mlsApproved: boolean;
  fetchListings: () => void;
}

const ListingDisplay: React.FC<ListingDisplayProps> = ({
  listings,
  mlsApproved,
  fetchListings,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { socket } = useSocket();

  const [currentListings, setCurrentListings] =
    useState<AllListings[]>(listings);

  const subscribedListingsRef = useRef<Set<string>>(new Set());
  const subscribedCampaignsRef = useRef<Set<string>>(new Set());

  useEffect(() => {
    setCurrentListings(listings);
  }, [listings]);

  console.log(currentListings);

  useEffect(() => {
    if (!socket) return;

    currentListings.forEach((listing) => {
      if (
        listing.status === "Processing" &&
        !subscribedListingsRef.current.has(listing.id)
      ) {
        socket.emit("subscribeToListing", listing.id);
        subscribedListingsRef.current.add(listing.id);
      }

      if (
        listing.campaignId &&
        listing.campaignStatus === "PROCESSING" &&
        !subscribedCampaignsRef.current.has(listing.campaignId)
      ) {
        socket.emit("subscribeToCampaign", listing.campaignId);
        subscribedCampaignsRef.current.add(listing.campaignId);
      }
    });

    const handleProgressUpdate = (data: {
      entityId: string;
      entityType: "listing" | "campaign";
      progress: Progress | CampaignProgress;
    }) => {
      const { entityId, entityType, progress } = data;
      setCurrentListings((prevListings) =>
        prevListings.map((listing) => {
          if (entityType === "listing" && listing.id === entityId) {
            return {
              ...listing,
              progress: progress as Progress,
              status:
                (progress as Progress).currentStep === "Completed"
                  ? "Active"
                  : listing.status,
            };
          } else if (
            entityType === "campaign" &&
            listing.campaignId === entityId
          ) {
            if ((progress as CampaignProgress).currentStep === "Completed") {
              fetchListings();
            }
            return {
              ...listing,
              campaignProgress: progress as CampaignProgress,
              campaignStatus:
                (progress as CampaignProgress).currentStep === "Completed"
                  ? "ACTIVE"
                  : listing.campaignStatus,
            };
          }
          return listing;
        })
      );
    };

    socket.on("progressUpdate", handleProgressUpdate);

    // Copy current ref values
    const currentSubscribedListings = subscribedListingsRef.current;
    const currentSubscribedCampaigns = subscribedCampaignsRef.current;

    return () => {
      socket.off("progressUpdate", handleProgressUpdate);

      currentListings.forEach((listing) => {
        if (currentSubscribedListings.has(listing.id)) {
          socket.emit("unsubscribeFromListing", listing.id);
          currentSubscribedListings.delete(listing.id);
        }
        if (
          listing.campaignId &&
          currentSubscribedCampaigns.has(listing.campaignId)
        ) {
          socket.emit("unsubscribeFromCampaign", listing.campaignId);
          currentSubscribedCampaigns.delete(listing.campaignId);
        }
      });
    };
  }, [socket, currentListings, fetchListings]);

  const viewListingHandler = (id: string) => {
    navigate("/listing", { state: { listingId: id } });
  };

  const createListingHandler = () => {
    navigate("/create-listing");
  };

  const handleCampaignRetry = async (campaignId: string | undefined) => {
    if (!campaignId) return;

    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.get(
        `${backendUrl}/campaign/restart/${campaignId}`,
        { headers: headers }
      );

      if (response.status === 200) {
        toast.success(
          <CustomToast
            message="Campaign generation process restarted."
            type="success"
          />,
          {
            autoClose: 3000,
          }
        );
      }
    } catch (error: unknown) {
      console.error(error);
      toast.error(
        <CustomToast message="Failed to retry campaign." type="error" />,
        {
          autoClose: 3000,
        }
      );
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header Section */}
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-2xl font-bold text-primary">Your Listings</h1>
        <button
          onClick={createListingHandler}
          className="px-4 py-2 bg-primary text-white rounded hover:bg-secondary transition-colors duration-200 flex items-center"
        >
          Add Listing
        </button>
      </div>

      {/* Listings Grid */}
      <div className="flex flex-wrap justify-center gap-6">
        {currentListings.map((listing) => {
          // Define CampaignCardListing inside the map function
          const CampaignCardListing: CampaignCardListingProps = {
            campaignProgress: listing?.campaignProgress,
            campaignStatus: listing?.campaignStatus,
            campaignType: listing?.campaignType,
            generatedPosts: listing?.generatedPosts,
            publishedPosts: listing?.publishedPosts,
            scheduledPosts: listing?.scheduledPosts,
            unapprovedPosts: listing?.unapprovedPosts,
            contentRefresh: listing?.contentRefresh,
          };

          return (
            <div
              key={listing.id}
              className="w-full sm:w-1/2 lg:w-1/3 max-w-sm bg-white rounded-lg shadow-2xl flex flex-col relative border-b-4"
              style={{ borderColor: listing.color }}
            >
              {listing.status !== "Active" && (
                <div className="absolute top-0 bg-gray-500 bg-opacity-50 w-full h-full z-10 rounded-lg" />
              )}

              {/* Image/Map Section */}
              <div className="relative w-full h-48">
                {listing.primaryImage && listing.primaryImage.length > 0 ? (
                  <img
                    src={listing.primaryImage}
                    alt={listing.formattedAddress}
                    className="w-full h-full object-cover rounded-t-lg"
                  />
                ) : (
                  <LeafletMap
                    latitude={listing.latitude}
                    longitude={listing.longitude}
                    address={listing.formattedAddress}
                  />
                )}
              </div>

              {/* Content Section */}
              <div className="p-4 flex flex-col flex-grow">
                {/* Address Section */}
                <h2
                  className="text-lg font-bold text-primary text-center mb-4 line-clamp-2 h-14 overflow-hidden"
                  title={listing.formattedAddress}
                >
                  {listing.formattedAddress}
                </h2>

                {/* Details Section */}
                <dl
                  className={`space-y-2 text-sm flex flex-col flex-grow ${
                    listing.status === "Processing" ||
                    listing.campaignStatus === "PROCESSING"
                      ? "justify-between"
                      : "justify-start"
                  }`}
                >
                  {/* Listing Status */}
                  <div className="flex items-center justify-between">
                    <dt className="flex items-center font-medium text-accent">
                      <IoIosAlert className="mr-2 h-5 w-5 text-secondary" />
                      Listing Status
                    </dt>
                    <dd className="font-medium text-primary">
                      {listing.status}
                    </dd>
                  </div>
                  {/* Listing Creation Updates */}
                  {listing.status === "Processing" ? (
                    <>
                      <div className="flex items-center justify-center mt-4">
                        <dt className="flex items-center justify-center font-medium text-accent">
                          <Loader className="mr-2 h-5 w-5 animate-spin text-secondary" />
                          {listing.progress?.currentStep}
                        </dt>
                      </div>
                      {listing.progress?.totalImages &&
                        listing.progress?.totalImages > 0 && (
                          <div className="flex items-center justify-between">
                            <dt className="flex items-center font-medium text-accent">
                              <Images className="mr-2 h-5 w-5 text-secondary" />
                              Images Processed:
                            </dt>
                            <dd className="font-medium text-primary">
                              {listing.progress?.imagesProcessed}/
                              {listing.progress?.totalImages}
                            </dd>
                          </div>
                        )}
                      {listing.progress?.totalVideos &&
                      listing.progress?.totalVideos > 0 ? (
                        <div className="flex items-center justify-between">
                          <dt className="flex items-center font-medium text-accent">
                            <Video className="mr-2 h-5 w-5 text-secondary" />
                            Videos Processed:
                          </dt>
                          <dd className="font-medium text-primary">
                            {listing.progress?.videosProcessed}/
                            {listing.progress?.totalVideos}
                          </dd>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <CampaignStatusCard listing={CampaignCardListing} />
                  )}
                </dl>
              </div>

              {/* Button Section */}
              {listing.status !== "Processing" && (
                <div className="p-4 flex flex-col space-y-2">
                  {!listing.campaignStatus && (
                    <button
                      className="w-full bg-secondary text-white hover:bg-primary transition-colors duration-200 py-2 rounded"
                      onClick={() => {
                        if (listing.imageCount <= 0) {
                          toast.error(
                            <CustomToast
                              message="Please upload images before creating a campaign."
                              type="error"
                            />,
                            {
                              autoClose: 3000,
                            }
                          );
                        } else {
                          navigate("/create-campaign", {
                            state: {
                              listingId: listing.id,
                              listingType: mlsApproved
                                ? "MLSListing"
                                : "Listing",
                              from: location.pathname,
                            },
                          });
                        }
                      }}
                    >
                      Create Campaign
                    </button>
                  )}
                  {listing.campaignStatus &&
                    listing.campaignStatus === "ERROR" && (
                      <button
                        className="w-full bg-secondary text-white hover:bg-primary transition-colors duration-200 py-2 rounded"
                        onClick={() => {
                          handleCampaignRetry(listing.campaignId);
                        }}
                      >
                        Retry Campaign
                      </button>
                    )}
                  <button
                    className="w-full bg-primary text-white hover:bg-secondary transition-colors duration-200 py-2 rounded z-20"
                    onClick={() => {
                      viewListingHandler(listing.id);
                    }}
                  >
                    View Listing
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListingDisplay;
