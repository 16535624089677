import React from "react";
import FeatureItem from "./FeatureItem";

interface PlanData {
  planId: string;
  title: string;
  subtitle: string;
  price: string;
  listingsLimit: string;
  postsLimit: string;
  imageLimit: string;
  videoLimit: string;
}

interface PricingCardProps {
  plan: PlanData;
  isSelected: boolean;
  setSelectedPlanId: (planId: string) => void;
}

const PricingCard: React.FC<PricingCardProps> = ({
  plan,
  isSelected,
  setSelectedPlanId,
}) => {
  const {
    planId,
    title,
    subtitle,
    price,
    listingsLimit,
    postsLimit,
    imageLimit,
    videoLimit,
  } = plan;

  console.log(`PricingCard for ${title}:`);
  console.log("planId:", planId);
  console.log("isSelected:", isSelected);

  return (
    <article className="flex overflow-hidden gap-2.5 items-start self-stretch p-10 my-auto bg-white rounded-3xl border border-violet-100 border-solid min-w-[240px] shadow-[0px_26px_40px_rgba(188,202,255,0.13)] w-[290px] max-md:px-5">
      <div className="flex flex-col flex-1 shrink w-full basis-0">
        <header className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <h2 className="text-2xl font-medium tracking-tighter text-primary">
              {title}
            </h2>
            <p className="text-base font-light leading-8 text-neutral-500">
              {subtitle}
            </p>
          </div>
          <div className="flex mt-2.5 max-w-full rounded-none w-fit">
            <span className="grow text-2xl font-medium tracking-tighter text-primary">
              ${parseInt(price) / 100}
            </span>
            <span className="self-start mt-3 text-sm font-light text-neutral-500 mx-2">
              per month
            </span>
          </div>
        </header>
        <hr className="mt-4 w-full min-h-0 border border-violet-100 border-solid" />
        <ul className="flex flex-col mt-4 w-full text-base leading-10 text-primary">
          <FeatureItem text={`${listingsLimit} Active Listings`} />
          <FeatureItem text={`${postsLimit} Total Posts`} />
          <FeatureItem text={`${imageLimit} Image Tags`} />
          <FeatureItem text={`${videoLimit} Generated Videos`} />
        </ul>
        <button
          className={`gap-2.5 self-stretch px-11 py-6 mt-5 w-full leading-3 text-center whitespace-nowrap rounded-xl border border-solid shadow-[0px_8px_36px_rgba(27,34,60,0.16)] max-md:px-5 text-sm ${
            isSelected
              ? "bg-secondary text-primary border-secondary"
              : "text-secondary border-secondary hover:bg-secondary hover:text-white"
          }`}
          onClick={() => setSelectedPlanId(planId)}
        >
          {isSelected ? `${title}` : `Choose ${title}`}
        </button>
      </div>
    </article>
  );
};

export default PricingCard;
