import React, { useState } from "react";
import StepProgressBar from "../progress-bar";
import AddressAutocomplete from "../misc/AddressAutocomplete";
import CustomSelect from "../misc/CustomSelect";
import Button from "../Button";
import { STATE_CODE_TO_NAME, US_STATES } from "../../utils/states";
import InputField from "../misc/InputField";
import { SelectedAddress } from "../../types/selectedAddress";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "../misc/CustomToast";
import { useNavigate } from "react-router-dom";

interface CreateUserListingProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  selectedAddress: SelectedAddress | null;
  setSelectedAddress: React.Dispatch<
    React.SetStateAction<SelectedAddress | null>
  >;
  mlsApproved: boolean | null;
  mlsId: string;
  setMlsId: React.Dispatch<React.SetStateAction<string>>;
}

const CreateUserListing: React.FC<CreateUserListingProps> = ({
  setCurrentStep,
  selectedAddress,
  setSelectedAddress,
  mlsApproved,
  mlsId,
  setMlsId,
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const [progressSteps] = useState([
    { label: "Property Address", isActive: true, isCompleted: false },
    { label: "Confirm Property Details", isActive: false, isCompleted: false },
  ]);

  const handleAddressSelect = (address: SelectedAddress) => {
    console.log("Selected Address:", address);
    setSelectedAddress(address);
  };

  const handleSaveAndNext = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (mlsApproved) {
      if (!mlsId && !selectedAddress) {
        toast.error(
          <CustomToast
            message="Please enter MLS Number or select an address before proceeding."
            type="error"
          />,
          { autoClose: 3000 }
        );
        return;
      }

      if (mlsId && selectedAddress) {
        toast.error(
          <CustomToast
            message="Please enter either MLS Number or select an address, not both."
            type="error"
          />,
          { autoClose: 3000 }
        );
        return;
      }
    } else {
      if (!selectedAddress) {
        toast.error(
          <CustomToast
            message="Please select an address before proceeding."
            type="error"
          />,
          { autoClose: 3000 }
        );
        return;
      }
    }
    setCurrentStep(2);
    console.log("Selected Address:", selectedAddress);
    console.log("MLS ID:", mlsId);
  };

  const handleReset = () => {
    setSelectedAddress(null);
    setMlsId("");
  };

  return (
    <main className="flex min-h-full overflow-auto flex-col justify-center items-center bg-background shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
      <section className="flex flex-col items-center px-8 py-12 bg-white w-full  max-w-3xl max-md:px-4 max-md:py-8 relative">
        <header className="flex flex-col mx-auto w-full">
          <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
            Create Listing
          </h1>
          <StepProgressBar steps={progressSteps.filter((step) => step)} />
        </header>
        <form
          className="flex flex-col justify-between flex-1 mt-2 w-full max-w-full"
          onSubmit={handleSaveAndNext}
        >
          {/* Address Autocomplete Component */}
          {mlsApproved && (
            <div className="flex flex-col justify-between">
              <InputField
                label="MLS Number"
                placeholder="Enter MLS Number"
                type="number"
                className="mt-2"
                value={mlsId}
                onChange={(e) => setMlsId(e.target.value)}
              />

              <span className="mt-8 text-center text-secondary"> OR </span>
            </div>
          )}

          {!selectedAddress ? (
            <AddressAutocomplete
              onAddressSelect={handleAddressSelect}
              label={"Enter Property Address"}
              className="top-24 mt-2"
            />
          ) : (
            <div className="flex-1 flex flex-col justify-center">
              <h2 className="text-lg font-semibold text-primary mb-4">
                Enter Property Address
              </h2>
              <div className="space-y-4">
                <AddressAutocomplete
                  label="Address 1"
                  placeholder="Ex: 5000 Stonewood Dr"
                  value={selectedAddress.address1}
                  onInputChange={(value) =>
                    setSelectedAddress({
                      ...selectedAddress,
                      address1: value,
                    })
                  }
                  onAddressSelect={(address) => {
                    setSelectedAddress(address);
                  }}
                  className="relative"
                />
                <InputField
                  label="Address 2"
                  placeholder="Apartment, suite, unit, etc."
                  type="text"
                  value={selectedAddress.address2 || ""}
                  onChange={(e) =>
                    setSelectedAddress({
                      ...selectedAddress,
                      address2: e.target.value,
                    })
                  }
                />
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col md:flex-row gap-6 w-full md:items-end">
                    <InputField
                      label="City"
                      placeholder="Ex: Wexford"
                      type="text"
                      value={selectedAddress.city}
                      onChange={(e) =>
                        setSelectedAddress({
                          ...selectedAddress,
                          city: e.target.value,
                        })
                      }
                      className="w-full md:flex-1"
                    />
                    <CustomSelect
                      label="State"
                      options={US_STATES.map((state) => ({
                        label: `${state.label} - ${state.value}`,
                        value: state.value,
                      }))}
                      value={
                        selectedAddress.state
                          ? {
                              label: `${
                                STATE_CODE_TO_NAME[selectedAddress.state]
                              } - ${selectedAddress.state}`,
                              value: selectedAddress.state,
                            }
                          : null
                      }
                      onChange={(selectedOption) =>
                        setSelectedAddress((prevDetails) => ({
                          ...prevDetails!,
                          state: selectedOption ? selectedOption.value : "",
                        }))
                      }
                      placeholder="Select State"
                      className="w-full md:flex-1 min-h-[60px]"
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-6 w-full">
                    <InputField
                      label="Zip Code"
                      placeholder="Ex: 15090"
                      type="text"
                      value={selectedAddress.zipCode}
                      onChange={(e) =>
                        setSelectedAddress({
                          ...selectedAddress,
                          zipCode: e.target.value,
                        })
                      }
                      className="w-full md:flex-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="w-full mt-7 flex flex-row gap-x-10">
            {mlsId.length > 0 || selectedAddress !== null ? (
              <Button
                text="Reset"
                className="w-full md:w-auto"
                onClick={handleReset}
              />
            ) : (
              <Button
                text="Back"
                className="w-full md:w-auto"
                onClick={handleBack}
              />
            )}
            <Button text="Next" className="w-full md:w-auto" type="submit" />
          </div>
        </form>
      </section>
    </main>
  );
};

export default CreateUserListing;
