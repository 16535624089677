import React from "react";

interface ButtonProps {
  text?: string;
  type?: "button" | "submit" | "reset";
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  ariaLabel?: string;
  id?: string;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  text,
  type = "button",
  onClick,
  className = "",
  disabled = false,
  loading = false,
  ariaLabel,
  id,
  children,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={`gap-2.5 self-stretch px-8 py-5 mt-6 w-full text-lg font-medium bg-secondary rounded-[30px] text-primary max-md:px-5 ${className} ${
        loading ? "opacity-50 cursor-not-allowed" : ""
      }`}
      aria-label={ariaLabel}
      id={id}
    >
      {loading ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      ) : (
        text || children
      )}
    </button>
  );
};

export default Button;
