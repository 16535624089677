import React, { useEffect } from "react";
import { Post } from "../types/posts";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "../components/misc/CustomToast";
import Calendar from "../components/calendar/Calendar";
import { getAuthHeader } from "../utils/authHeader";
import LoadingScreen from "./LoadingScreen";
import { useNavigate } from "react-router-dom";

const HomeScreen: React.FC = () => {
  const navigate = useNavigate();

  const [posts, setPosts] = React.useState<Post[]>([]);

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const postsResponse = await axios.get(`${backendUrl}/post`, {
        headers,
        withCredentials: true,
      });

      if (postsResponse.status === 200) {
        setPosts(postsResponse.data);
        setLoading(false);
      } else {
        toast.error(
          <CustomToast
            message="Failed to fetch posts! Please try again later."
            type="error"
          />,
          {
            autoClose: 3000,
          }
        );
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);
  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      {loading && <LoadingScreen />}
      {posts.length <= 0 && !loading && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-75 backdrop-blur-sm flex flex-col items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-semibold mb-4">No Posts Found</h2>
            <p className="mb-6">
              Click the button below to go to the listings page to create a
              listing or a campaign.
            </p>
            <button
              onClick={() => navigate("/listings")}
              className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-secondary transition-colors"
            >
              Go to Listings
            </button>
          </div>
        </div>
      )}
      <Calendar posts={posts} />
    </div>
  );
};

export default HomeScreen;
