export interface TimeZoneOption {
  label: string;
  value: string;
}

export const TIME_ZONES: TimeZoneOption[] = [
  { label: "Alaska Time (AKT) - Anchorage", value: "America/Anchorage" },
  { label: "Central Time (CT) - Chicago", value: "America/Chicago" },
  { label: "Eastern Time (ET) - New York", value: "America/New_York" },
  { label: "Hawaii Time (HT) - Honolulu", value: "Pacific/Honolulu" },
  { label: "Mountain Time (MT) - Denver", value: "America/Denver" },
  { label: "Mountain Time (MT) - Phoenix (No DST)", value: "America/Phoenix" },
  { label: "Pacific Time (PT) - Los Angeles", value: "America/Los_Angeles" },
];
