import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAuthHeader } from "../utils/authHeader";
import { toast } from "react-toastify";
import CustomToast from "../components/misc/CustomToast";
import LoadingScreen from "./LoadingScreen";
import ContentTabs from "../components/content/ContentTabs";
import axios from "axios";
import {
  AllListingMedia,
  ContentProps,
  PromotionsMedia,
} from "../components/content/types";
import MediaCarousel from "../components/listings/listing/MediaCarousel";
import { CarouselApi } from "../components/listings/Carousel";
import { CombinedMedia, MediaItem } from "../components/listings/listing/types";
import { Assets } from "../types/asset-type";

const ContentScreen: React.FC<ContentProps> = ({ mlsApproved }) => {
  const [listingMedia, setListingMedia] = useState<AllListingMedia[]>([]);
  const [promotions, setPromotions] = useState<PromotionsMedia | null>(null);
  const [resources, setResources] = useState<Assets[]>([]);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<"listing" | "promotion">(
    "listing"
  );
  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
  const [carouselIndexMap, setCarouselIndexMap] = useState<{
    [id: string]: number;
  }>({});

  const [listingTabs, setListingTabs] = useState<string[]>([]);
  const [activeListingTab, setActiveListingTab] = useState<string>("");

  const fetchAssets = useCallback(async () => {
    setLoading(true);
    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.get(`${backendUrl}/promotions/media`, {
        headers,
      });
      console.log("promotions response", response);

      if (response.status === 200) {
        setPromotions(response.data);
      } else {
        throw new Error("Failed to fetch assets. Please try again later.");
      }
    } catch (error: unknown) {
      console.error("Failed to fetch assets:", error);
      toast.error(
        <CustomToast
          message="Failed to fetch assets. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchListingMedia = useCallback(async () => {
    setLoading(true);
    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.get(
        `${backendUrl}/${mlsApproved ? "realtor-listing" : "listing"}/media`,
        {
          headers,
        }
      );
      console.log(response);

      if (response.status === 200) {
        setListingMedia(response.data);
        const tabs = response.data.map(
          (listing: AllListingMedia) => listing.address
        );
        setListingTabs(tabs);
        console.log("tabs", tabs);
        setActiveListingTab(tabs[0] || "");
      } else {
        throw new Error(
          "Failed to fetch listing media. Please try again later."
        );
      }
    } catch (error: unknown) {
      console.error("Failed to fetch listing media:", error);
      toast.error(
        <CustomToast
          message="Failed to fetch listing media. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  }, [mlsApproved]);

  const fetchResources = useCallback(async () => {
    setLoading(true);
    const headers = await getAuthHeader();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.get(`${backendUrl}/assets/general-assets`, {
        headers,
      });

      if (response.status === 200) {
        setResources(response.data);
      } else {
        throw new Error("Failed to fetch resources. Please try again later.");
      }
    } catch (error: unknown) {
      setLoading(false);
      console.error("Failed to fetch resources:", error);
      toast.error(
        <CustomToast
          message="Failed to fetch resources. Please try again later."
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAssets();
    fetchListingMedia();
    fetchResources();
  }, [fetchListingMedia, fetchAssets, fetchResources]);

  // Memoize allItems to prevent it from being recreated on every render
  const allItems: MediaItem[] = useMemo(() => {
    const items: MediaItem[] = [];

    listingMedia.forEach((listing) => {
      listing.images.forEach((image) => {
        items.push({ ...image, type: "image" });
      });
      listing.videos.forEach((video) => {
        items.push({ ...video, type: "video" });
      });
      listing.assets.forEach((asset) => {
        const mediaType: "image" | "video" =
          asset.assetType === "Video" ? "video" : "image";
        items.push({ ...asset, type: mediaType });
      });
    });

    promotions?.assets.forEach((promotion) => {
      items.push({ ...promotion, type: "image" });
    });

    resources.forEach((resource) => {
      const mediaType: "image" | "video" =
        resource.assetType === "Video" ? "video" : "image";
      items.push({ ...resource, type: mediaType });
    });

    return items;
  }, [listingMedia, promotions, resources]);

  useEffect(() => {
    const map: { [id: string]: number } = {};
    allItems.forEach((item, index) => {
      if (item.id) {
        map[item.id] = index;
      }
    });
    setCarouselIndexMap(map);
  }, [allItems]);

  return (
    <div className="relative w-full min-h-full py-6 px-10 mb-4">
      {/* Loading Screen */}
      {loading && <LoadingScreen />}
      {/* Heading */}
      <div className="flex flex-col mb-4">
        <h1 className="text-3xl font-bold text-primary">My Content</h1>
      </div>
      <div className="w-[70%] mx-[15%]">
        <div className="px-10">
          <MediaCarousel
            setCarouselApi={setCarouselApi}
            combinedMedia={allItems as CombinedMedia[]}
          />
        </div>
        {/* Asset Tabs */}
        <ContentTabs
          promotions={promotions}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          listingMedia={listingMedia}
          listingTabs={listingTabs}
          activeListingTab={activeListingTab}
          setActiveListingTab={setActiveListingTab}
          loading={loading}
          mlsApproved={mlsApproved || false}
          carouselApi={carouselApi}
          fetchAssets={fetchAssets}
          fetchListingMedia={fetchListingMedia}
          setLoading={setLoading}
          carouselIndexMap={carouselIndexMap}
          resources={resources}
          fetchResources={fetchResources}
        />
      </div>
    </div>
  );
};

export default ContentScreen;
