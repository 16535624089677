import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { AgentDetails as AgentDetailsType } from "../../types/onboarding";
import InputField from "../misc/InputField";
import Button from "../Button";
import StepProgressBar from "../progress-bar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/logo.png";
import CustomToast from "../misc/CustomToast";
import { isValidPhoneNumber } from "../../utils/validationUtil";
import LoadingScreen from "../../screens/LoadingScreen";
import CustomSelect from "../misc/CustomSelect";
import { STATE_CODE_TO_NAME, US_STATES } from "../../utils/states";
import { getAuthHeader } from "../../utils/authHeader";
import AddressAutocomplete from "../misc/AddressAutocomplete";
import { TIME_ZONES, TimeZoneOption } from "./utils/timeZones";
import { ActionMeta, SingleValue } from "react-select";
import { OptionType } from "../../types/option-type";

interface AgentDetailsProps {
  setCurrentStep: (step: number) => void;
  role: string | null;
}

const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const AgentDetails: React.FC<AgentDetailsProps> = ({
  setCurrentStep,
  role,
}) => {
  const [agentDetails, setAgentDetails] = useState<AgentDetailsType>({
    firstName: "",
    lastName: "",
    realtorLicenseNumber: "",
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    timeZone: BROWSER_TIMEZONE, // Default to browser's time zone
    phoneNumber: "",
  });
  const [initialDetails, setInitialDetails] = useState<AgentDetailsType | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [addressSelected, setAddressSelected] = useState<boolean>(false);

  useEffect(() => {
    const fetchAgentDetails = async () => {
      try {
        setLoading(true);
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const response = await axios.get(`${backendUrl}/users/agent-details`, {
          headers,
          withCredentials: true,
        });
        if (
          response.data.address1 &&
          response.data.city &&
          response.data.state &&
          response.data.zipCode
        ) {
          setAddressSelected(true);
        }
        setAgentDetails(response.data);
        setInitialDetails(response.data);
      } catch (error) {
        console.error("Error fetching agent details:", error);
        toast.error(
          <CustomToast
            message="Failed to fetch agent details. Please try again."
            type="error"
          />,
          {
            autoClose: 3000,
          }
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAgentDetails();
  }, []);

  const hasChanges = useCallback(() => {
    if (!initialDetails) return false;
    return JSON.stringify(agentDetails) !== JSON.stringify(initialDetails);
  }, [agentDetails, initialDetails]);

  const validateFields = () => {
    const {
      firstName,
      lastName,
      realtorLicenseNumber,
      companyName,
      address1,
      city,
      state,
      zipCode,
      phoneNumber,
      timeZone,
    } = agentDetails;

    if (
      !firstName ||
      !lastName ||
      !realtorLicenseNumber ||
      !companyName ||
      !address1 ||
      !city ||
      !state ||
      !zipCode ||
      !phoneNumber ||
      !timeZone
    ) {
      toast.error(
        <CustomToast message="All fields are required!" type="error" />,
        {
          autoClose: 3000,
        }
      );
      return false;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      toast.error(
        <CustomToast message="Invalid phone number format!" type="error" />,
        {
          autoClose: 3000,
        }
      );
      return false;
    }

    return true;
  };

  const handleSaveAndNext = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      setLoading(true);
      if (hasChanges()) {
        const headers = await getAuthHeader();
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        await axios.patch(
          `${backendUrl}/users/agent-details`,
          { ...agentDetails },
          {
            headers,
            withCredentials: true,
          }
        );
        toast.success(
          <CustomToast
            message="Agent details saved successfully!"
            type="success"
          />,
          {
            autoClose: 3000,
          }
        );
      }

      setCurrentStep(2);
    } catch (error) {
      console.error("Error saving agent details:", error);
      toast.error(
        <CustomToast
          message="Failed to save agent details. Please try again later!"
          type="error"
        />,
        {
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  // Handle address selection from AddressAutocomplete
  const handleAddressSelect = (selectedAddress: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
  }) => {
    setAgentDetails((prevDetails) => ({
      ...prevDetails,
      ...selectedAddress,
    }));
    setAddressSelected(true);
  };

  const progressSteps = [
    { label: "Agent Details", isActive: true, isCompleted: false },
    { label: "Broker Details", isActive: false, isCompleted: false },
    { label: "Choose MLS", isActive: false, isCompleted: false },
  ];

  if (role !== "TEAM_USER") {
    progressSteps.push({
      label: "Select Plan",
      isActive: false,
      isCompleted: false,
    });
    progressSteps.push({
      label: "Payment",
      isActive: false,
      isCompleted: false,
    });
  }

  const handleStateChange = (
    selectedOption: { label: string; value: string } | null
  ) => {
    setAgentDetails((prevDetails) => ({
      ...prevDetails,
      state: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleTimeZoneChange = (
    option: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    setAgentDetails((prevDetails) => ({
      ...prevDetails,
      timeZone: option ? option.value : "",
    }));
  };

  console.log("AgentDetails");
  console.log(agentDetails);
  console.log(agentDetails.address1);

  // Determine the default time zone
  const getDefaultTimeZone = (): TimeZoneOption | null => {
    // Check if the current timeZone is in the TIME_ZONES list
    const current: TimeZoneOption | undefined = TIME_ZONES.find(
      (tz: TimeZoneOption) => tz.value === agentDetails.timeZone
    );
    if (current) return current;

    // If not, find the browser's time zone
    const browserTZ = TIME_ZONES.find(
      (tz: TimeZoneOption) => tz.value === BROWSER_TIMEZONE
    );

    return browserTZ || null;
  };

  return (
    <main className="flex overflow-hidden flex-col justify-center items-center min-h-screen bg-background shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
      <section className="flex flex-col items-center px-14 py-20 max-w-full bg-white rounded-2xl min-h-[833px] w-[905px] max-md:px-5 max-md:pb-24 relative">
        {loading && <LoadingScreen />}
        <header className="flex flex-col mx-auto w-full max-md:max-w-full">
          <img
            loading="lazy"
            src={logo}
            alt="Agent Registration Logo"
            className="object-contain self-center max-w-full aspect-[8.77] w-[395px]"
          />
          <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
            User Onboarding
          </h1>
          <StepProgressBar steps={progressSteps.filter((step) => step)} />
        </header>
        <form
          className="flex flex-col mx-auto mt-2 w-full max-md:max-w-full"
          onSubmit={handleSaveAndNext}
        >
          <div className="flex flex-row gap-6 items-center w-auto max-md:max-w-full">
            <InputField
              label="First Name"
              placeholder="Ex: John"
              type="text"
              value={agentDetails.firstName}
              onChange={(e) =>
                setAgentDetails({
                  ...agentDetails,
                  firstName: e.target.value,
                })
              }
            />
            <InputField
              label="Last Name"
              placeholder="Ex: Doe"
              type="text"
              value={agentDetails.lastName}
              onChange={(e) =>
                setAgentDetails({ ...agentDetails, lastName: e.target.value })
              }
            />
          </div>
          <InputField
            label="Real Estate License Number"
            placeholder="License Number"
            type="text"
            value={agentDetails.realtorLicenseNumber}
            onChange={(e) =>
              setAgentDetails({
                ...agentDetails,
                realtorLicenseNumber: e.target.value,
              })
            }
          />
          <InputField
            label="Company Name"
            placeholder="Ex: iSpeak AI"
            type="text"
            value={agentDetails.companyName}
            onChange={(e) =>
              setAgentDetails({
                ...agentDetails,
                companyName: e.target.value,
              })
            }
          />
          {/* Integrate AddressAutocomplete Component */}
          {!addressSelected ? (
            <AddressAutocomplete
              onAddressSelect={handleAddressSelect}
              label={"Company Address"}
              className="top-32"
            />
          ) : (
            <>
              <h2 className="text-lg mt-4 font-semibold text-primary">
                Enter Property Address
              </h2>
              <InputField
                label="Address 1"
                placeholder="Ex: 5000 Stonewood Dr"
                type="text"
                value={agentDetails.address1}
                onChange={(e) =>
                  setAgentDetails({
                    ...agentDetails,
                    address1: e.target.value,
                  })
                }
                className="mt-1"
              />
              <InputField
                label="Address 2"
                placeholder="Apartment, suite, unit, etc."
                type="text"
                value={agentDetails.address2}
                onChange={(e) =>
                  setAgentDetails({
                    ...agentDetails,
                    address2: e.target.value,
                  })
                }
              />
              <div className="flex flex-col gap-2 w-full max-md:max-w-full">
                <div className="flex flex-col md:flex-row gap-6 w-full md:items-end">
                  <InputField
                    label="City"
                    placeholder="Ex: Wexford"
                    type="text"
                    value={agentDetails.city}
                    onChange={(e) =>
                      setAgentDetails({ ...agentDetails, city: e.target.value })
                    }
                    className="w-full md:w-1/2"
                  />
                  <CustomSelect
                    label="State"
                    options={US_STATES.map((state) => ({
                      label: state.label + " - " + state.value,
                      value: state.value,
                    }))}
                    value={
                      (agentDetails.state &&
                        STATE_CODE_TO_NAME[agentDetails.state] && {
                          label:
                            STATE_CODE_TO_NAME[agentDetails.state] +
                            " - " +
                            agentDetails.state,
                          value: agentDetails.state,
                        }) ||
                      null
                    }
                    onChange={handleStateChange}
                    placeholder="Select State"
                    className="w-full md:w-1/2 min-h-[60px]"
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-6 w-full">
                  <InputField
                    label="Zip Code"
                    placeholder="Ex: 15090"
                    type="text"
                    value={agentDetails.zipCode}
                    onChange={(e) =>
                      setAgentDetails({
                        ...agentDetails,
                        zipCode: e.target.value,
                      })
                    }
                    className="w-full md:w-1/2"
                  />
                </div>
              </div>
            </>
          )}
          {/* Time Zone Selection */}
          <CustomSelect
            label="Time Zone"
            options={TIME_ZONES}
            value={getDefaultTimeZone()}
            onChange={handleTimeZoneChange}
            placeholder="Select Time Zone"
            className="w-full md:w-1/2 min-h-[60px] mt-4"
          />
          <InputField
            label="Phone Number"
            placeholder="Ex: (878) 231-6060"
            type="text"
            value={agentDetails.phoneNumber}
            onChange={(e) =>
              setAgentDetails({
                ...agentDetails,
                phoneNumber: e.target.value,
              })
            }
            className="w-full md:w-1/2 mt-4"
          />
          <Button text="Next" className="mt-7" type="submit" />
        </form>
      </section>
    </main>
  );
};

export default AgentDetails;
