import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import CustomToast from "../misc/CustomToast";
import Button from "../Button";
import StepProgressBar from "../progress-bar";
import logo from "../../assets/images/logo.png";
import { auth } from "../../firebase/firebase-config";
import { getIdToken } from "firebase/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { setIsOnboarded } from "../../store/authSlice";
import LoadingScreen from "../../screens/LoadingScreen";

interface PaymentFormProps {
  setCurrentStep: (step: number) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ setCurrentStep }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleBack = () => {
    setCurrentStep(4);
  };

  const handleNext = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      toast.error(
        <CustomToast
          message="Stripe.js has not yet loaded. Please try again."
          type="error"
        />,
        { autoClose: 3000 }
      );
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });

    if (error) {
      toast.error(
        <CustomToast
          message={`Payment failed: ${error.message}`}
          type="error"
        />,
        { autoClose: 3000 }
      );
      setIsProcessing(false);
    } else {
      toast.success(
        <CustomToast message="Payment succeeded!" type="success" />,
        { autoClose: 3000 }
      );

      try {
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error("User not authenticated");
        const token = await getIdToken(currentUser, true);
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const response = await axios.get(`${backendUrl}/users/activate`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        await axios
          .get(`${backendUrl}/send-verification-email`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .catch((error: Error) => {
            console.error(error);
          });

        if (response.status === 200) {
          toast.success(
            <CustomToast
              message="Account activated successfully!"
              type="success"
            />,
            { autoClose: 3000 }
          );

          if (response.data.onboarded) {
            dispatch(setIsOnboarded(true));
            navigate("/");
          } else {
            setCurrentStep(6);
          }
        }
      } catch (error) {
        console.error("Error activating account:", error);
        toast.error(
          <CustomToast
            message="Account activation failed. Please contact support."
            type="error"
          />,
          { autoClose: 3000 }
        );
      } finally {
        setIsProcessing(false);
      }
    }
  };

  return (
    <main className="flex overflow-hidden flex-col justify-center items-center h-screen bg-background shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5">
      <section className="flex flex-col items-center px-14 py-20 max-w-full bg-white rounded-2xl min-h-[833px] w-[905px] max-md:px-5 max-md:pb-24">
        <div className="w-full flex flex-col items-center mb-8">
          <img
            loading="lazy"
            src={logo}
            alt="Agent Registration Logo"
            className="object-contain self-center max-w-full aspect-[8.77] w-[395px]"
          />
          <h1 className="my-2 text-primary text-2xl font-bold leading-none text-center">
            User Onboarding
          </h1>
          <StepProgressBar
            steps={[
              { label: "Agent Details", isActive: true, isCompleted: true },
              { label: "Broker Details", isActive: true, isCompleted: true },
              { label: "Choose MLS", isActive: true, isCompleted: true },
              { label: "Select Plan", isActive: true, isCompleted: true },
              { label: "Payment", isActive: true, isCompleted: false },
            ]}
          />
        </div>
        <div className="flex-grow flex items-center w-full">
          <form onSubmit={handleNext} className="w-full">
            <div className="w-full">
              <PaymentElement />
            </div>
            {isProcessing && <LoadingScreen />}
            <div className="w-full flex justify-between mt-8 gap-20">
              <Button text="Back" className="w-1/3" onClick={handleBack} />
              <Button
                type="submit"
                disabled={!stripe || !elements || isProcessing}
              >
                {isProcessing ? "Processing..." : "Pay"}
              </Button>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default PaymentForm;
