import React from "react";
import PricingCard from "./PricingCard";

interface PlanData {
  planId: string;
  title: string;
  subtitle: string;
  price: string;
  listingsLimit: string;
  postsLimit: string;
  imageLimit: string;
  videoLimit: string;
}

interface PricingPlansProps {
  plans: PlanData[];
  selectedPlanId: string | null;
  setSelectedPlanId: (planId: string) => void;
}

const PricingPlans: React.FC<PricingPlansProps> = ({
  plans,
  selectedPlanId,
  setSelectedPlanId,
}) => {
  return (
    <section className="flex flex-row gap-7 items-center mx-auto">
      {plans.map((plan) => {
        console.log("Rendering plan:", plan.planId);
        return (
          <PricingCard
            key={plan.planId}
            plan={plan}
            isSelected={String(selectedPlanId) === String(plan.planId)}
            setSelectedPlanId={setSelectedPlanId}
          />
        );
      })}
    </section>
  );
};

export default PricingPlans;
