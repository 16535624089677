import React from "react";
import { DeleteMediaProps } from "./types";
import { Button } from "../../../misc/Button";
import { getAuthHeader } from "../../../../utils/authHeader";
import { isCombinedAsset } from "./utils";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../../../misc/CustomToast";

const DeleteMediaButton: React.FC<DeleteMediaProps> = ({
  selectedMedia,
  setIsLoading,
  mlsApproved,
  listingId,
  handleDeleteComplete,
  setSelectedMedia,
}) => {
  const handleDelete = async () => {
    setIsLoading(true);
    if (selectedMedia.length === 0) return;

    try {
      const headers = await getAuthHeader();
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      // Group selectedMedia by type
      const assetsToDelete = selectedMedia
        .filter(isCombinedAsset)
        .map((media) => media.id);
      const imageIdsToDelete = selectedMedia
        .filter((media) => media.type === "image" && !isCombinedAsset(media))
        .map((media) => media.id);
      const videoIdsToDelete = selectedMedia
        .filter((media) => media.type === "video" && !isCombinedAsset(media))
        .map((media) => media.id);

      const deletePromises = [];

      if (assetsToDelete.length > 0) {
        deletePromises.push(
          axios.delete(`${backendUrl}/assets`, {
            headers,
            data: {
              ids: assetsToDelete,
            },
          })
        );
      }

      if (imageIdsToDelete.length > 0) {
        deletePromises.push(
          axios.delete(
            `${backendUrl}/${
              mlsApproved ? "realtor-listing" : "listing"
            }/images/`,
            {
              headers: headers,
              data: {
                listingId: listingId,
                imageIds: imageIdsToDelete,
              },
            }
          )
        );
      }

      if (videoIdsToDelete.length > 0) {
        deletePromises.push(
          axios.delete(
            `${backendUrl}/${
              mlsApproved ? "realtor-listing" : "listing"
            }/videos/`,
            {
              headers: headers,
              data: {
                listingId: listingId,
                videos: videoIdsToDelete,
              },
            }
          )
        );
      }

      await Promise.all(deletePromises);
      setIsLoading(false);
      toast.success(
        <CustomToast
          message="Selected items deleted successfully."
          type="success"
        />,
        { autoClose: 3000 }
      );
      // Refresh listing data
      handleDeleteComplete();
      setSelectedMedia([]);
    } catch (error) {
      setIsLoading(false);
      console.error("Error deleting media:", error);
      toast.error(
        <CustomToast message="Failed to delete selected items." type="error" />,
        { autoClose: 3000 }
      );
    }
  };
  return (
    <div>
      <span className="mr-2 text-primary">{selectedMedia.length} Selected</span>

      {selectedMedia.length > 0 && (
        <Button
          className="bg-secondary hover:bg-primary"
          onClick={handleDelete}
        >
          Delete Selected
        </Button>
      )}
    </div>
  );
};

export default DeleteMediaButton;
